const acceptedPosters = [
    "Karagün Ebru, Eröz Recep, Gamsızkan Mehmet, Baysak Sevim, Eyüp Yavuz, Özcan Yunus. Kseroderma Pigmentozum E; DDB2 Geninde Tanımlanan Yeni Mutasyon. Xxıv. Prof. Dr. A. Lütfü Tat Sempozyumu",
    "Karagün Ebru. İmmunsüpresif Hastaların Ağız Bakım Alışkanlıklarının Değerlendirilmesi Ve Oral Enfeksiyonlar Arasındaki İlişkinin İncelenmesi. 1. Uluslar Arası Dental Ve Oral Enfeksiyonlar Kongresi",
    "Karagün Ebru. Mavi Işık Deriye Zarar Verir Mi?. Hastalıkta Ve Sağlıkta Dermatoloji",
    "Karagün Ebru, Gamsızkan Mehmet, Eyüp Yavuz, Bahcıvan Atike, Özcan Yunus, Büyücek Şeyma. Nadir Lokalizyon Yerleşimli Bazal Hücreli Karsinom Üç Olgu Sunumu. Vı. Dod Dermatoloji Gündemi",
    "Karagün Ebru, Öneç Birgül, Gamsızkan Mehmet, Özcan Yunus, Büyücek Şeyma, Eyüp Yavuz. Indetermina Dentritic Hücreli Neoplazi: Nadir Görülen Bir Olgu. Vı. Dod Dermatoloji Gündemi",
    "Özcan Yunus, Karagün Ebru, Gamsızkan Mehmet, Bahcıvan Atike. Eritema Nodozum’un Olağan Dışı Yerleşimi Olgu Sunumu. Xxıv. Prof. Dr. A. Lütfü Tat Sempozyumu",
    "Karagün Ebru, Gamsızkan Mehmet, Özcan Yunus, Bahcıvan Atike, Eyüp Yavuz. Dermatofibrosarkoma Protuberans Nadir Bir Tümör. Xxıv. Prof. Dr. A. Lütfü Tat Sempozyumu",
    "Bahcıvan Atike, Naldemir Aslı, Gamsızkan Mehmet, Karagün Ebru. Skrotumda Bazal Hücreli Karsinom: Nadir Bir Lokalizasyon. 28. Ulusal Patoloji Kongresi",
    "Karagün Ebru, Özcan Yunus. Veteriner İlacına Bağlı Sistemik Allerjik Kontakt Dermatit. 27. Ulusal Dermatoloji Kongresi",
    "Karagün Ebru, Öneç Birgül. Hidroürenin Neden Olduğu Saç Ve Sakal Renk Koyulaşması. 27. Ulusal Dermatoloji Kongresi",
    "Karagün Ebru, Gamsızkan Mehmet, Bahcıvan Atike, Eyüp Yavuz. Extragenital Yerleşimli Büllöz Liken Sklerozis Olgusu. 27. Ulusal Dermatoloji Kongresi",
    "Karagün Ebru, Özcan Yunus. Düzce İli Çevresinde Lokalize Lyme Tanısı Konulan-İki Olgu Sunumu. 27. Ulusal Dermatoloji Kongresi",
    "Karagün Ebru, Gamsızkan Mehmet, Eyüp Yavuz, Bahcıvan Atike. Atipik Yerleşimli Liken Striatus Olgusu. 27. Ulusal Dermatoloji Kongresi",
    "Karagün Ebru. Familyal Homozigot Hiperkolesterolomi Tip Iıa Olgu Sunumu. Xxııı. Prof. Dr. A. Lütfü Tat Sempozyumu",
    "Karagün Ebru. Akuajenik Keratoderma 4 Ve 8 Yaşında Tanı Konulan İki Olgu. Xxııı. Prof. Dr. A. Lütfü Tat Sempozyumu",
    "Karagün Ebru, Baysak Sevim. Zona Zoster Tanısı Konulan Bir Yaşından Küçük Üç Olgu Sunumu. 26. Ulusal Dermatoloji Kongresi",
    "Karagün Ebru, Tatar Zeynep. Prurigo Pigmentoza Olgu Sunumu. 26. Ulusal Dermatoloji Kongresi",
    "Karagün Ebru, Tatar Zeynep. Palmoplantar Yerleşimli Liken Planus Olgu Sunumu. 26. Ulusal Dermatoloji Kongresi",
    "Karagün Ebru, Yarıkkaya Enver, Baysak Sevim. Leopart Sendromu Tanısı Konulan Olgu Sunumu. 26. Ulusal Dermatoloji Kongresi",
    "Karagün Ebru, Yarıkkaya Enver, Baysak Sevim. Atipik Yerleşimli Erişkin Başlangıçlı Liken Striatus Olgusu. 26. Ulusal Dermatoloji Kongresi",
    "Karagün Ebru, Baysak Sevim. Şarbon Tanılı: Üç Olgu Sunumu. Xxıı. Prof. Dr. A.Lütfü Tat Sempozyumu",
    "Karagün Ebru, Baysak Sevim. Ramsay-Hunt Sendromu Tanısı Konulan Çocuk Olgu Sunumu. Xxıı. Prof. Dr. A.Lütfü Tat Sempozyumu",
    "Karagün Ebru, Şahin Arzu, Çokbankır Özge, Adalı Yasemen. Eritrokeratoderma Variabilis, Olgu Sunumu. Xxıı. Prof. Dr. A.Lütfü Tat Sempozyumu",
    "Karagün Ebru, Çokbankır Özge, Baysak Sevim. Ekzema Benzeri Deri Metastazı Olan Mevcut Meme Ca Olgusu. Xxıı. Prof. Dr. A.Lütfü Tat Sempozyumu",
    "Karagün Ebru, Şahin Arzu. Ağrı İli Çevresinde Çocukluk Çağında Görülen Deri Hastalıkların Prevalansı. Xxıı. Prof. Dr. A.Lütfü Tat Sempozyumu",
    "Karagün Ebru. Dermatoloji Polikliniğine Başvuran Hastalarda Geleneksel Ve Tamamlayıcı Tıp Kullanım Sıklığının Araştırılması: Anket Çalışması. Dermatoloji Ve Kozmetolojide Gelişmeler Kongresi",
    "Karagün Ebru. Pernio Tanılı Hastaların Vücut Kitle İndeksi Ve Tanita Düzeylerinin Kontrol Grubu İle Karşılaştırılması. 10. Dermatoloji Bahar Sempozyumu",
    "Karagün Ebru. Vitiligo Hastalarının Vücut Tutulum Alanlarıyla Otoimmun Hastalıklar Arasındaki İlişki. Erciyes Dermatoloji Zirvesi",
    "Yağcı Perihan, Karagün Ebru, Öneç Kürşad, Öneç Birgül. Diffuse Large B Cell Lymphoma Of The Scalp. Ixth İnternational Eurasian Hematology Oncology Congress",
    "Özlü Emin,Öneç Birgül,Özcan Yunus,Karagün Ebru,Gamsızkan Mehmet,Mehtap Özgür. Lenf Nodu Ve Kemik İliği Tutulumlu Primer Kutanöz Marjinal Zon Lenfoma. V. Dod Dermatoloji Gündemi",
    "Karagün Ebru, Kandi Başak. Estetik Dermatolojide Mezoterapi, Prp Ve Kök Hücre Uygulamaları. Yüz Ve Boyun Gençleştirmede Mezoterapi"
]

export default acceptedPosters;
import React from 'react';

const ContactMap = () => {
    return (
        <>
            <section className="map-area">

                    <div style={{ width: '100%' }}>
                        <iframe title="Contact" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3059.8041440172906!2d32.803876!3d39.923399!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d349d17d0c066b%3A0xa4a5b6c0a18b2e95!2sBe%C5%9Ftepe%2C%2032.%20Cadde%20No%3A1%20D%3A22%2C%2006560%20Yenimahalle%2FAnkara%2C%20T%C3%BCrkiye!5e0!3m2!1str!2sus!4v1692857769058!5m2!1str!2sus" style={{ width: '100%' }} height={600} allowFullScreen="" loading="lazy"></iframe>
                    </div>

            </section>
        </>
    );
};

export default ContactMap;
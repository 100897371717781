import React from 'react';
import Footer from '../../../components/Shared/Footer';
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader';
import DoctorDetailsArea from './DoctorDetailsArea/DoctorDetailsArea';

const DoctorDetails = () => {
    return (
        <>
            <HomeHeader/>
            <DoctorDetailsArea/>
            <Footer/>
        </>
    );
};

export default DoctorDetails;
import React from 'react';
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader';
import BlogNoSideBarArea from './BlogNoSideBarArea/BlogNoSideBarArea';

const BlogNoSideBar = () => {
   return (
      <>
         <HomeHeader />
         <BlogNoSideBarArea />
      </>
   );
};

export default BlogNoSideBar;
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SingleBlog = ({ image, title, date, description, mediaid }) => {
    const { i18n } = useTranslation();
    return (
        <>
            <div className="col-xl-10 col-lg-10 col-md-10">
                <div className="latest-news-box latest-news-box-2 mb-30">
                    <div className="latest-news-thumb">
                        <Link to={`/${i18n.language === 'en' ? 'en/media' : 'medyada-biz'}/${mediaid}`}><img src={image} alt="" /></Link>
                    </div>
                    <div className="latest-news-content-box">
                        <div className="latest-news-content" style={{ minHeight: "240px" }}>
                            <div className="news-meta mb-10">
                                <span><Link to={`/${i18n.language === 'en' ? 'en/media' : 'medyada-biz'}/${mediaid}`} className="news-tag">{date}</Link></span>
                            </div>
                            {image ? <h3> <Link to={`/${i18n.language === 'en' ? 'en/media' : 'medyada-biz'}/${mediaid}`}>{title}</Link> </h3> : <h3> {title} </h3>}
                            <p>{description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SingleBlog;
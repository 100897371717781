import React, { useState, useEffect } from 'react';
import HomeTwoAbout from './HomeTwoAbout/HomeTwoAbout';
import HomeTwoAppoinmentArea from './HomeTwoAppoinmentArea/HomeTwoAppoinmentArea';
import HomeTwoBlogs from './HomeTwoBlogs/HomeTwoBlogs';
import HomeTwoCounter from './HomeTwoCounter/HomeTwoCounter';
import HomeTwoFooter from './HomeTwoFooter/HomeTwoFooter';
import HomeTwoHeroSection from './HomeTwoHeader/HomeTwoHeroSection';
import HomeTwoMissionArea from './HomeTwoMissionArea/HomeTwoMissionArea';
import HomeTwoNavBar from './HomeTwoNavBar/HomeTwoNavBar';
import HomeTwoServicesArea from './HomeTwoServicesArea/HomeTwoServicesArea';

const HomeTwo = () => {
    const [isVisible, setIsVisible] = useState(false);


    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 30) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <HomeTwoNavBar />
            {isVisible && <a
                href="https://wa.me/905332691271"
                className={`whatsapp_float`}
                target="_blank"
                rel="noopener noreferrer"
            >
                <button
                    className="play-btn popup-video"><i className="fab fa-whatsapp whatsapp-icon"></i>
                </button>

            </a>}
            <HomeTwoHeroSection />
            <HomeTwoAbout />
            <HomeTwoMissionArea />
            <HomeTwoServicesArea />
            <HomeTwoCounter />
            <HomeTwoAppoinmentArea />
            <HomeTwoBlogs />
            <HomeTwoFooter />
        </>
    );
};

export default HomeTwo;
import React from 'react';
import Footer from '../../components/Shared/Footer';
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader';
import BlogDetailsArea from './BlogDetailsArea/BlogDetailsArea';
import application from '../../fakeData/applications/application';
import { useLocation } from 'react-router-dom';
import enApplication from '../../fakeData/applications/en-application';
import { useTranslation } from 'react-i18next';

const BlogDetails = () => {
   const { i18n } = useTranslation();
   const location = useLocation();

   const regex = /\/([^\/]+)\/?$/;
   const match = location.pathname.match(regex);
   let filteredData = '';

   if (match) {
      filteredData = i18n.language === 'en' ? enApplication.filter(data => data.id === match[1]) : application.filter(data => data.id === match[1])
   }

   return (
      <>
         <HomeHeader />
         <BlogDetailsArea blogData={filteredData} />
         <Footer />
      </>
   );
};

export default BlogDetails;
const media = [
    {
        mediaid: 'doc-dr-ebru-karagun-cilt-catlaklari',
        title: "Doç. Dr. Ebru Karagün – Cilt Çatlakları",
        image: "/img/media/doc-dr-ebru-karagun-cilt-catlaklari.jpg",
        date: '28 Mart 2023',
        medialink: 'https://www.magdergi.com/roportajlar/doc-dr-ebru-karagun-cilt-catlaklari',
        mediaDesc: 'Doç. Dr. Ebru Karagün Cilt Çatlakları Kendi kliniğinde hastalarını kabul eden Dermatoloji Doç. Dr. Ebru Karagün, cilt çatlaklarının oluşumunu ele...',
        content: {
            blogPageTitle: "Doç. Dr. Ebru Karagün – Cilt Çatlakları",
            blogPageDesc: "Doç. Dr. Ebru Karagün Cilt Çatlakları Kendi kliniğinde hastalarını kabul eden Dermatoloji Doç. Dr. Ebru Karagün, cilt çatlaklarının oluşumunu ele alarak tedavi yöntemlerine ve çatlak oluşumunu minimalize etmenin yollarına ışık tutuyor. Kendinizden biraz bahsederek başlar mısınız? Bugüne kadar neler yaptınız, eğitimleriniz ve uzmanlık alanlarınız neler? Samsunluyum. Öğrenim hayatımı, üniversite de dahil Samsun’da tamamladım. Asistanlık eğitimimi Ankara’da, Sağlık Bilimleri Üniversitesi Dışkapı Yıldırım Beyazıt Eğitim Araştırma Hastanesinde aldım. Asistanlık sonrasında Ağrı Devlet Hastanesinde mecburi hizmetimi bitirdim. İstanbul Medipol Üniversitesi ve Düzce Üniversitesi Tıp Fakültelerinde yardımcı doçent doktor olarak görev yaptım. Meslek hayatımın...",
            metaDescription: "Doç. Dr. Ebru Karagün, cilt çatlaklarını ele alarak tedavi yöntemlerine ve çatlak oluşumunu minimalize etmenin yollarına ışık tutuyor.",
            blogDetailPageInfo: [
                {
                    title: 'Cilt Çatlakları',
                    description: 'Kendi kliniğinde hastalarını kabul eden Dermatoloji Doç. Dr. Ebru Karagün, cilt çatlaklarının oluşumunu ele alarak tedavi yöntemlerine ve çatlak oluşumunu minimalize etmenin yollarına ışık tutuyor.',
                },
                {
                    title: 'Kendinizden biraz bahsederek başlar mısınız? Bugüne kadar neler yaptınız, eğitimleriniz ve uzmanlık alanlarınız neler?',
                    description: 'Samsunluyum. Öğrenim hayatımı, üniversite de dahil Samsun’da tamamladım. Asistanlık eğitimimi Ankara’da, Sağlık Bilimleri Üniversitesi Dışkapı Yıldırım Beyazıt Eğitim Araştırma Hastanesinde aldım. Asistanlık sonrasında Ağrı Devlet Hastanesinde mecburi hizmetimi bitirdim. İstanbul Medipol Üniversitesi ve Düzce Üniversitesi Tıp Fakültelerinde yardımcı doçent doktor olarak görev yaptım. Meslek hayatımım en keyifli dönemleri; hem tıp fakültesi öğrencilerine hem de asistan hekimlere eğitim vererek hem hekimlik hem de hocalık görevi yerine getirmekti. 2021 yılında doçentlik unvanı alarak akademik hayatıma İstinye Üniversitesi Tıp Fakültesinde misafir öğretim üyesi kadrosuyla devam etmekteyim. 2022 yılının nisan ayından itibaren ise Moment Beştepe Plazada bulunan kendi kliniğimde hekimlik görevimi yerine getirmekteyim.',
                },
                {
                    title: 'Kliniğinizde hangi tedavileri uyguluyorsunuz?',
                    description: 'Kliniğimizde kozmetik uygulamalardan dolgu-mezoterapi uygulamaları, ip askılama yöntemleri, cihaz işlemleri (Scarlet X lazer), ozon-glutatyon tedavisi ve ayrıca tüm dermatolojik hastalıkların tanı ve tedavisi yapılmaktadır.',
                },
                {
                    title: 'Ciltteki çatlaklar nasıl ve neden oluşur?',
                    description: '<p style="text-align:left">Stria ya da cilt çatlakları; derinin alt tabakasında destek dokularının zayıflaması ve derinin, genişleme kapasitesinin üzerinde gerilmesi sonucu ortaya çıkan, çizgi tarzında yer yer yırtılması olarak da tarif edebiliriz. “Striae distensae (SD)”, ”stretch marks” ve “stria rubra” ya da “alba” gibi isimler verilmektedir. Sıklıkla gebelik sürecinde ortaya çıkması nedeni ile “gebelik çatlakları” olarak da tanımlanmaktadır. Cilt çatlakları sağlık açısından ciddi bir problem olmamakla birlikte geçmişte olduğu gibi günümüzde de en önemli kozmetik problemlerinden bir tanesidir. Sıklıkla beş ila elli yaş döneminde görülmekte ve görülme sıklığı yüzde on bir ila seksen sekiz arasında değişmektedir. Kadınlarda, gebelik süreci nedeni ile iki buçuk kat daha fazla gözlenmektedir. Cilt çatlaklarının oluşma nedeni tam olarak bilinmemektedir. Bununla birlikte birçok hipotezden bahsedilmektedir. Bunlar; mekanik etki (Mekanik olarak dokuların gerilmesi ve deri altı destek dokusunun azalması nedeniyle olmaktadır. Örneğin; gebelik, kilo alımı, ağırlık kaldırma özellikle barfiks çekme hızlı boy uzaması.),<strong>kortizon tedavileri, genetik faktörler</strong>. Ergenlik döneminde sıklıkla koltuk altı ve genital kılların ortaya çıkması sonrasında görülmektedir. Genellikle, kadınlarda uyluk üstü, kalça ve göğüslerde olurken; erkeklerde uyluk dış kısmı, sırt orta ve bel kısımlarında görülmekte.</p>',
                },
                {
                    description: 'Gebelikte gözlenen deri çatlakları için risk faktörlerini şu şekilde sayabiliriz: Gebelerde sıklıkla gebeliğin son üçüncü ayında başlamakta ve gebelik sonlana kadar devam etmektedir. Ailesel yatkınlık, genç yaşta gebe kalmak, gebelikte kilo artışının fazla  olması, karın büyüklüğü ile (kilolu bebek, amniyon suyunun çok olması veya çoğul gebelikler) ile ilişkilidir. Kadınlarda gebelik sonrası cilt çatlakları en sık; karın, memelerde ve uyluk üst bölgelerinde gözlenmektedir.',
                },
                {
                    description: 'Cilt çatlaklarının iki klinik formu mevcuttur:<ul><li><strong>Striae rubra (erken evre)</strong></li><li><strong>Striae alba (geç evre)</strong></li></ul>',
                },
                {
                    description: 'Cilt çatlakları başlangıçta kırmızı ve morumsu göründükleri için kırmızı anlamına gelen “striae rubrae-SR” olarak tanımlanmaktadır. Daha sonra bu renk solmakta ve sanki deride bir kırışıklık hatta bir skar gibi görünmekte ve beyaz anlamındaki ”striae albae-SA” olarak tanımlanmaktadır. Cilt çatlaklarında tedavi seçenekleri, cilt çatlaklarının klinik olarak erken ve geç evrede olup olmasına göre değişmektedir.',
                },
                {
                    title: 'Bu çatlakları tamamen tedavi etmek mümkün mü?',
                    description: 'Cilt çatlakları medikal bir problem olmamakla birlikte özellikle kadınlarda estetik yönü ile ciddi bir problem olarak algılanmaktadır.',
                },
                {
                    description: 'Önlemek için; deri çatlaklarında ilk olarak riskleri iyi belirlemek ve hastayı bu risk faktörleri konusunda bilinçlendirmek son derece önemlidir. Gebelik döneminde kremler, on ikinci gebelik haftasından itibaren başlanmalıdır. Özellikle; centella asiatica  içeren kremlerin (trofolastin), C vitamini, E vitamini ve kuşburnu yağının etkili olduğu, çalışmalarla tespit edilmiştir.',
                },
                {
                    description: 'Yaygın bir şekilde kullanılan badem yağı, kakao yağı, zeytinyağının etkinliğine dair bilimsel çalışmalarda yeterli olumlu sonuçlar alınamamıştır. Özellikle gebelikte kremlerin masaj yaparak uygulanmasının, deri çatlağı oluşumunu azalttığı bilinmektedir. Tedavi için; Scarlet X, dermapen lazer tedavileri, kimyasal peeling, PRP, mezoterapiler uygulanmaktadır. Özellikle erken evrede; pembe-mor evresinde tedavi yapılırsa tedavi etkinliği artmaktadır.',
                },
                {
                    description: 'Her yaş grubunda ve emzirme dönemlerinde uygulanacak olan tedavi seçenekleri mevcuttur. Deri çatlaklarının hem pembe-mor evresinde, hem de beyaz evresinde tedavi seçenekleri vardır; fakat erken dönemde, pembe-mor evresinde tedavi uygulanırsa, tedavi etkinliğini artıracağı özellikle bilinmelidir.',
                },
                {
                    description: '<p style="text-align:right">Centella asiatica içeren kremlerin (trofolastin), C vitamini, E vitamini ve kuşburnu yağının çatlaklarda etkili olduğu, çalışmalarla tespit edilmiştir</p>',
                },
                {
                    title: 'Bizler kliniğimizde;',
                    description: 'öncellikle kişinin cilt çatlaklarının neden olduğunu, çatlakların erken-geç evrede olup olmadığını değerlendirmekteyiz. Sonrasında; aylık tedavi seansları şeklinde en az üç ya da dört seans tedavi planı oluşturulmaktadır. Planlamayı Scarlet X, dermapen, tedavilerini PRP-mezoterapi uygulamaları ile kombine etmekteyiz. Tedavi yanıtına göre seçenekler değişebilmektedir. Tedavide cilt altında yapılandırma süreci artırmak amaçlandığından tedavi etkinliğini ikinci aydan itibaren görmekteyiz. Hasta-doktor iş birliği ile yüzde kırk ila seksen oranında cilt çatlaklarında azalma sağlayabilmekteyiz.',
                },
                {
                    title: 'Peki, tekrar eder mi? Tedavi sonrasında nasıl bir yol izlemek gerekli?',
                    description: 'Eğer kişi hızlı kilo alıp verirse maalesef tekrarlama olasılığı olur. Tedavi sonrasında özellikle kilo dengesine dikkat edilmelidir. Kontrol edemediğiniz durumlar olduğunda, herhangi bir hastalık nedeniyle kortizon kullanıldığında, kilo alımı veya hızlı boy uzaması dönemlerinde cilt çatlaklarının oluşumunu minimalize etmek için uzman görüşü alınması gerekebilir.',
                },
            ]
        },
    },
    {
        mediaid: 'doc-dr-ebru-karagun-fotoyaslanma',
        title: "Doç. Dr. Ebru Karagün – Fotoyaşlanma",
        image: "/img/media/doc-dr-ebru-karagun-fotoyaslanma.jpg",
        date: '28 Mart 2023',
        mediaDesc: 'Doç. Dr. Ebru Karagün Fotoyaşlanma Cildin yaşlanmasına etki eden faktörleri ele alarak fotoyaşlanmanın sebeplerini ve sonuçlarını açıklayan Doç. Dr. Ebru...',
        content: {
            blogPageTitle: "Doç. Dr. Ebru Karagün – Fotoyaşlanma",
            blogPageDesc: "Doç. Dr. Ebru Karagün Fotoyaşlanma Cildin yaşlanmasına etki eden faktörleri ele alarak fotoyaşlanmanın sebeplerini ve sonuçlarını açıklayan Doç. Dr. Ebru Karagün, güneş hasarını en aza indirmek için uygulanabilecek yöntemleri MAG Okurları için detaylandırıyor… Fotoyaşlanma (Güneş Hasarlı Cilt) Nedir? Yaşlanma; zamanla artış gösteren, kaçınılmaz ve doğal olan bir süreçtir. Doğduğumuz günden itibaren yaşlanma sürecimiz başlar; ancak, neden bazı insanların, olduklarından çok daha yaşlı göründüğünü, diğerlerinin ise daha genç göründüğünü hiç merak ettiniz mi? Deri yaşlanması hem kronolojik (yaşa bağlı) yaşlanma hem de fotoyaşlanma ile olur. Ultraviyole (UV) ışınlarının, derimizde erken yaşlanma...",
            metaDescription: "Doç. Dr. Ebru Karagün, cildin yaşlanmasına etki eden faktörleri ele alarak fotoyaşlanmanın sebep ve sonuçlarını mag okurlarına açıklıyor.",
            blogDetailPageInfo: [
                {
                    title: 'Fotoyaşlanma',
                    description: 'Cildin yaşlanmasına etki eden faktörleri ele alarak fotoyaşlanmanın sebeplerini ve sonuçlarını açıklayan Doç. Dr. Ebru Karagün, güneş hasarını en aza indirmek için uygulanabilecek yöntemleri MAG Okurları için detaylandırıyor…',
                },
                {
                    title: 'Fotoyaşlanma (Güneş Hasarlı Cilt) Nedir?',
                    description: 'Yaşlanma; zamanla artış gösteren, kaçınılmaz ve doğal olan bir süreçtir. Doğduğumuz günden itibaren yaşlanma sürecimiz başlar; ancak, neden bazı insanların, olduklarından çok daha yaşlı göründüğünü, diğerlerinin ise daha genç göründüğünü hiç merak ettiniz mi? Deri yaşlanması hem kronolojik (yaşa bağlı) yaşlanma hem de fotoyaşlanma ile olur. Ultraviyole (UV) ışınlarının, derimizde erken yaşlanma değişikliklerini oluşturmasına “fotoyaşlanma” denir. Kırışıklıklar, ince çizgiler ve renk tonu eşitsizliği, yaşlandıkça sıklıkla ortaya çıkan kaçınılmaz cilt sıkıntılarıdır. Cildimizdeki bu değişikliklerin asıl suçlusu fotoyaşlanmadır; güneş ışığına ve UV ışığa maruz kalmanın neden olduğu, cilde verilen hasardır. Ciltteki gözle görülür değişikliklerin yüzde doksanından sorumlu olan fotoyaşlanma, yaşamınız boyunca maruz kaldığınız kümülatif (yıllar içerisinde oluşan, birikici) güneş hasarının doğrudan bir sonucudur. Cildinizin ne kadar yaşlı göründüğü, güneşte ne kadar zaman geçirdiğiniz ile doğru orantılıdır.',
                },
                {
                    title: 'Fotoyaşlanma Risk Faktörleri',
                    description: 'Samsunluyum. Öğrenim hayatımı, üniversite de dahil Samsun’da tamamladım. Asistanlık eğitimimi Ankara’da, Sağlık Bilimleri Üniversitesi Dışkapı Yıldırım Beyazıt Eğitim Araştırma Hastanesinde aldım. Asistanlık sonrasında Ağrı Devlet Hastanesinde mecburi hizmetimi bitirdim. İstanbul Medipol Üniversitesi ve Düzce Üniversitesi Tıp Fakültelerinde yardımcı doçent doktor olarak görev yaptım. Meslek hayatımım en keyifli dönemleri; hem tıp fakültesi öğrencilerine hem de asistan hekimlere eğitim vererek hem hekimlik hem de hocalık görevi yerine getirmekti. 2021 yılında doçentlik unvanı alarak akademik hayatıma İstinye Üniversitesi Tıp Fakültesinde misafir öğretim üyesi kadrosuyla devam etmekteyim. 2022 yılının nisan ayından itibaren ise Moment Beştepe Plazada bulunan kendi kliniğimde hekimlik görevimi yerine getirmekteyim.',
                },
                {
                    title: 'Fotoyaşlanma Nedenleri:',
                    description: 'Kolajen fibrilleri ve elastin lifleri cildimizin sıkılığını, elastikiyetini, parlak, canlı görünmesini sağlayan bileşenlerdir. UVB ışınları (öğle saatlerinde daha yoğundur), cildin üst tabakasına zarar vererek güneş yanığına neden olur; ancak, UVA ışınları (akşam saatlerinde daha yoğundur), güneş ışınlarının yüzde doksan beşini oluşturur. Bu dalga boyu cildin derinliklerine inerek kolajene zarar verir ve anormal elastin üretimine neden olur. Cildinizin daha yaşlı görünmesine, yani fotoyaşlanmaya neden olur. Bunun yanında, cildimiz kendini korumak için UV maruziyetine tepki olarak melanin (kahverengi renk) üretir ve bu süreç güneş lekelerine, çiller, yaşlılık lekeleri gibi renk tonu eşitsizliklerine neden olur.',
                },
                {
                    title: 'Fotoyaşlanma Belirtileri',
                    description: '<ul><li>Kırışıklıklar</li><li>Sarkık cilt (esneklik kaybı)</li><li>Koyu lekeler (güneş lekeleri)</li><li>Kan damarlarının belirginleşmesi</li><li>Ciltte renk eşitsizliği</li><li>Cildin çok kuruması ve sertleşmesi</li><li>Cildin hassaslaşması</li></ul><strong>Fotoyaşlanmadan korunmak için çok düzenli bir şekilde güneş koruyucu kullanılmalı ve güneşin hasarını azaltabilen uygulamalar (mezoterapi, Scarlet X) yapılmalıdır.</strong>',
                },
                {
                    title: 'Güneş Işınlarından Nasıl Korunurum?',
                    description: '<ul><li>En az SPF 30 olan güneş koruyucu kullanmalıyız. SPF 50 üstü güneş koruyucu kullanmak ek katkı sağlamayacaktır.</li><li>İçeriğinde çinko oksit ve/veya titanyum dioksit içeren güneş koruyucular tercih edilmelidir. Özellikle güneş koruyucunuz demir oksit içeriyorsa mavi ışık spektrumuna da etkin koruma sağlayacaktır, çünkü kullanacağız güneş koruyucularının hem UVB ve UVA hem de mavi ışığa karşı koruma faktörü içermesi önemlidir.</li><li>Dermatologlar olarak bizler, “Saat 10.00-16.00 arasında güneşe çıkmayın, denize girmeyin.” uyarısında bulunuruz, çünkü bu saatlerde UVB ışınları fazladır, bu ışınlar güneş yanıkları ve uzun dönemde deri kanserlerine neden olur. Sizin her kötü güneş yanığınız, derinizdeki DNA yapısına zarar verir ve ilerleyen yaşlarda deri kanserine maalesef zemin hazırlar. Saat 16.00’dan sonra UVB ışınları azalır, UVA ışınları yoğunlaşır. UVA ışınlarının, güneş yanığı ve deri kanseri yapma ihtimali daha zayıftır fakat fotoyaşlanma yapma riski çok daha fazladır. Bu sebeple güneş koruyucunuzu sabah kalktığınız gibi uygulamanızı ve gün batana kadar kullanmanızı önermekteyiz. Akşama doğru güneşin yakıcı etkinliği azalır fakat yaşlandırıcı etkinliği artar.</li><li>Gün içinde güneş koruyucularınızı dört saatte bir tekrarlamanız gerekmektedir. Güneş koruyucu kullanmak; deri kanserlerinden, fotoyaşlanmadan korunmak için olmazsa olmazımızdır. Fotoyaşlanma sürecini minimalize etmek, cildimizin kalitesini artırmak, daha canlı, daha sağlıklı bir cilde kavuşmak, ince kırışıklarımızı ve sarkmalarımızı azaltmak için yaz ve kış dönemlerinde Scarlet X ve/veya mezoterapi uygulamalarını yaptırabilirsiniz.</li></ul>',
                },
                {
                    title: 'Fotoyaşlanma Süreci Tersine Çevrilebilir Mi?',
                },
                {
                    title: 'Mezoterapi',
                    description: 'Yüz mezoterapisi ile, biorejuvenation (cildi uyarıcı) ve mezolift (cildi germe) temel hedeftir.',
                },
                {
                    description: 'Vitaminler, mineraller, amino asitler, koenzimler, antioksidanlar ve hyalüronik asit gibi, cildin ihtiyaç duyduğu maddelerin cildin alt katmanlarına enjekte edilmesiyle gerçekleştirilen bir tedavi yöntemidir. Mezoterapi uygulaması hangi amaçla yapılacaksa, kullanılan ürünlerin içerikleri buna bağlı olarak farklılık gösterir. Özellikle yaz döneminde güneş hasarı belirgin olduğundan dolayı antioksidan etkinliği yüksek mezoterapiler tercih edilmelidir. Lekelenme; çoğu kişinin yaz döneminde en çok canını sıkan durumdur, bu durumu azaltmak için leke oluşumunu önleyen veya oluşmuş bir leke varsa deri içerisinde yayılmasını engelleyen bileşimleri içeren mezoterapi ürünleri kullanılmalıdır.',
                },
                {
                    title: 'Mezoterapi Seansları',
                    description: 'Mezoterapiyi yaz döneminde, güneş hasarını azaltmak için iki ila üç hafta ara ile üç ila dört seans olarak planlamaktayız.'
                },
                {
                    title: 'Mezoterapi Sonrası Güneşe Çıkmak',
                    description: 'İki gün boyunca, yoğun güneş ışınlarından uzak durmanızı istemekteyiz; güneş koruyucu kullanarak rutin yaşantınıza devam edebilirsiniz. Güneşin cildinize verdiği hasarı minimalize etmek ve cilt altındaki geri dönüşümsüz süreci önleyebilmek  için yaz dönemlerinde güneş hasarını ve lekelenmeyi azaltarak cildinizi koruyacak mezoterapi seçeneklerini düşünebilirsiniz.'
                },
                {
                    title: 'Scarlet X',
                    description: 'Cildin yenilenmesi, gençleştirilmesi (antiaging) amacıyla son yıllarda Scarlet X teknolojisi kullanılmaya başlanmıştır. Scarlet X, ABD Silikon Vadisi’nde geliştirilen son teknoloji bir radyofrekans uygulamasıdır. Cildin bir bütün olarak gençleştirilmesinde, ince kırışıklıkların giderilmesinde, cilt lekeleri ve kızarıklığının tedavi edilmesinde, cilt tonunun aydınlatılmasında, gözeneklerin sıkılaştırılmasında, akne izlerinin iyileştirilmesinde, deri çatlaklarının (stria) tedavisinde güvenlik ve etkinlik açısından klinik olarak kanıtlanmış FDA onaylı bir cihazdır. Güneş ışınları kolajen sentezini azaltarak ve elastik liflerin yapısını bozarak fotoyaşlanmaya neden olmaktadır. Yaşlanma sürecini durduramayız ama yavaşlatabiliriz. Bu yavaşlatmayı ise cilt kalitesini artırarak sağlayabiliriz. Cilt kalitesini artırmak, fotoyaşlanmayı azaltmak için en doğru seçenek Scarlet X’tir.'
                },
                {
                    title: 'Scarlet X İşleminde Ağrı',
                    description: 'Uygulamada kişiye özel başlıklar kullanılır. Bu başlıklarda mikro iğneler bulunur ve bu mikro iğneler aracılığıyla cilt altına radyofrekans dalgaları gönderilir. Ağrı eşiği kişiden kişiye göre değişmektedir. Genellikle minimal ağrı hissedilmektedir. Ağrı ihtimalini mümkün olduğunca azaltmak için uygulamadan yaklaşık otuz dakika önce tedavi bölgesine topikal anestezik krem uygulanmaktadır. İşlem süresi ortalama kırk beş dakika sürmektedir.'
                },
                {
                    title: 'Scarlet X Tedavisinde İyileşme Süreci',
                    description: 'Uygulama sonrasında, bir ya da iki saat içinde geçen hafif bir kızarıklık oluşur. Hasta sosyal yaşamına hemen geri dönebilir. İşlem sonrasında ciltte mikro kanallar oluştuğundan yirmi dört saat boyunca banyo yapılması ya da havuza girilmesi önerilmez. Cildin üst tabakasında herhangi bir hasar oluşmadığından Scarlet X uygulamaları yaz ve kış dönemlerinde yapılabilir. Sonrasında güneş koruyucu kullanmak ve nemlendirmek yeterlidir.'
                },
                {
                    title: 'Scarlet X Tedavisinde İyileşme Süreci',
                    description: 'Scarlet X uygulaması son derece güvenli bir sistemdir. Uygulama sonrasında morarma, kanama oluşmaz, iğne izi kalmaz. Her mevsim uygulama yapılabilir.'
                },
                {
                    title: 'Scarlet X Seans Sayısı ve Sonuçlar',
                    description: 'Cildi gençleştirmek için yapılan uygulamalarda bir ay arayla üç seans yapılması yeterli olacaktır.'
                },
                {
                    description: 'Ultraviyole ışınları deri yaşlanmasını artıran faktörlerin başında gelmektedir. Güneş koruyucu kullanmak hem deri kanserlerinden hem de fotoyaşlanmadan korunmada önemlidir. Fotoyaşlanma sürecini azaltabilmek, cilt kalitesini artırmak, cildimizdeki kolajen, elastin kaybını minimalize edebilmek için Scarlet X ve mezoterapi en doğru yaklaşımdır.',
                },
            ]
        },
    },
    {
        mediaid: 'doc-dr-ebru-karagun-iyi-gorun-iyi-hisset',
        title: "Doç. Dr. Ebru Karagün – İyi Görün, İyi Hisset!",
        image: "/img/media/doc-dr-ebru-karagun-iyi-gorun-iyi-hisset.jpg",
        medialink: 'https://ankaralifedergisi.com/iyi-gorun-iyi-hisset',
        mediaDesc: 'İyi Görün, İyi Hisset! Yüz ip askılama, badem göz, saç ve yüz mezoterapisi, glutat- yon, ozon uygulaması tedavileri, altın...',
        date: '14 Temmuz 2023',
        content: {
            blogPageTitle: "Doç. Dr. Ebru Karagün – İyi Görün, İyi Hisset!",
            blogPageDesc: "İyi Görün, İyi Hisset! Yüz ip askılama, badem göz, saç ve yüz mezoterapisi, glutatyon, ozon uygulaması tedavileri, Scarlet X'in yanı sıra ameliyatsız yüz germe konusunda da adından oldukça söz ettiren Doç. Dr. Ebru Karagün, başkentin en çok okunan yayını Ankara Life Dergisi’ne konuk oldu. Endolift tedavisine yönelik bilgilerini siz değerli okurlarımız adına paylaştı. İyi okumalar dileriz. Ebru Hanım son dönemlerde kişisel bakıma yönelik çalışmaların hayli revaçta olduğunu görmekteyiz, ancak alanınıza dair sorularımıza geçmeden önce sizi tanımak istiyoruz. Ebru Karagün kimdir? ■ Samsunluyum. Öğrenim hayatımı üniversite dahil Samsun’da",
            metaDescription: "Doç. Dr. EbruKaragün, başkentin en çok okunan yayını Ankara Life Dergisi’ne konuk oldu. Endolift tedavisine yönelik bilgilerini siz değerli okurlarımız adına paylaştı.",
            blogDetailPageInfo: [
                {
                    title: 'İyi Görün, İyi Hisset!',
                    description: 'Yüz ip askılama, badem göz, saç ve yüz mezoterapisi, glutatyon, ozon uygulaması tedavileri, Scarlet X"in yanı sıra ameliyatsız yüz germe konusunda da adından oldukça söz ettiren Doç. Dr. Ebru Karagün, başkentin en çok okunan yayını Ankara Life Dergisi’ne konuk oldu. Endolift tedavisine yönelik bilgilerini siz değerli okurlarımız adına paylaştı. İyi okumalar dileriz.',
                },
                {
                    description: '<strong>Ebru Hanım son dönemlerde kişisel bakıma yönelik çalışmaların hayli revaçta olduğunu görmekteyiz, ancak alanınıza dair sorularımıza geçmeden önce sizi tanımak istiyoruz. Ebru Karagün kimdir?</strong>',
                },
                {
                    description: 'Samsunluyum. Öğrenim hayatımı üniversite dahil Samsun’da tamamladım. Asistanlık eğitimimi Ankara’da Sağlık Bilimleri Üniversitesi Dışkapı Yıldırım Beyazıt Eğitim Araştırma Hastanesi’nde aldım. Asistanlık sonrasında Ağrı Devlet Hastanesi’nde mecburi hizmetimi bitirdim.',
                },
                {
                    description: 'İstanbul Medipol Üniversitesi ve Düzce Üniversitesi Tıp fakültelerinde Yardımcı Doçent Doktor olarak görev yaptım. 2021 yılında Doçentlik unvanı alarak, akademik hayatıma İstinye Üniversitesi Tıp Fakültesi’nde misafir öğretim üyesi kadrosuyla devam etmekteyim. 2022 Nisan ayından itibaren ise Moment Beştepe Plaza’da bulunan kendi kliniğimde hekimlik görevimi yerine getirmekteyim.',
                },
                {
                    description: '<strong>Teknolojik gelişmelerle birlikte ameliyatsız gençleşme yöntemleri oldukça popüler hale geldi. Ancak konuya dair detaylı bilgiyi işin uzmanından dinlemenin daha sağlıklı olacağı kanaatindeyiz. Ebru Hanım, ameliyatsız yüz germe işlemi nedir ve nasıl gerçekleşir?</strong>',
                },
                {
                    description: 'Günümüzün hızla değişen sosyo-kültürel değerleri her yaş gurubunda, kadın ile erkeklerde genç ve dinamik görünüme sahip olma isteğini, buna bağlı olarak estetik işlemlerini artırmıştır. Bununla birlikte estetik uygulamalarda beklentiler de hızla değişmektedir. Doktor ve hasta olarak; uygulamaların hızlı yapılabilir olması, etkilerinin uzun sürmesi, güvenilir ve modern olmaları, uygulama sonrasında iş ile günlük yaşama çabuk dönülebilecek kadar yan etkilerinin az olması istenmektedir. Bu amaçla henüz ciddi bir operasyona hazır olmayanlar için yaşlanma belirtilerini hafifletecek, cilde daha genç, daha sıkı bir görünüm verecek “Ameliyatsız Estetik İşlemler” olarak tanımlanan yöntemler geliştirilmiştir. Son yıllarda bunların uygulanabilirliği, etkinliği ve çeşitliliği artmaktadır. Genel anestezi, ameliyathane koşulları, hastahane yatışları gerektirmeksizin hızlı iyileşme ve çabuk sonuçların gözlenmesi bu yöntemleri daha popüler yapmaktadır. Daha genç, daha sıkı, daha kaliteli bir cilt için gelişmiş lazer teknolojisi kullanılarak yapılan ameliyatsız lazerli cilt germe yöntemine Endolift Lazer (Lazer Facelift) denilmektedir.',
                },
                {
                    title: 'Endolift nedir, biraz açar mısınız?',
                    description: 'Lazer lifting uygulaması olan Endolift, 200-300 mikron kalınlıkta olan (yaklaşık bir saç teli kadar), 1470 nm dalga boyuna sahip cildin altına ulaşabilen ve enerji ileten bir teknolojidir. Bu gelişmiş lazer teknolojisi sayesinde ciltte sıkılaşma ve toparlanma sağlanır. Endolift uygulaması her mevsim yapılmaktadır.',
                },
                {
                    title: 'Endolift uygulama alanları nelerdir?',
                    description: 'Ameliyatsız yüz germe, boyun germe, gıdı küçültme, çene hattı belirginleştirme, göz altı kırışıklıkları ve torba tedavisi, vücuttaki gevşeklikler ve kırışıklıklarda uygulanmaktadır.',
                },
                {
                    title: 'Peki bu uygulamanın avantajları nelerdir?',
                    description: 'Uygulama anestezi olmadan gerçekleştirilmektedir. Güvenli bir uygulamadır ve uzun süre etkisini korumaktadır. Sadece tek bir seans yeterlidir, iz bırakmamakta ve cerrahi işlemlere gerek duyulmamaktadır. Klinik ortamda kolaylıkla uygulanabilmektedir. İyileşme sürecine ihtiyaç duyulmamakta olup, etkisi 2 yıla yakın sürmektedir.',
                },
                {
                    title: 'Okurlar olarak Endolift uygulamasından neler beklemeliyiz?',
                    description: 'Endolift, cilt altını bir ağ gibi lazerle tarayarak cildin elastikiyetini, kalitesini arttırmaktadır ve daha gergin ve sıkı bir cilde kavuşturmaktadır. Boyun bölgesindeki yatay çizgilerin görünümü azaltabilmektedir. Çene hattını daha belirgin hale getirmektedir. Göz altındaki elastikiyet kaybından kaynaklanan deformasyonu büyük ölçüde giderebilmektedir.',
                },
                {
                    description: 'Orta, alt yüz ve gıdı bölgesindeki lokalize aşırı yağı eritip sarkan deriyi toparlamaktadır. Ayrıca bacak içlerinde ve diz üstündeki yağ fazlalıklarını Endolift Lazer ile tedavi etmek mümkündür. Aynı zamada lazerli cilt germe uygulaması olan Endolift, Laser FDA onaylıdır.',
                },
                {
                    title: 'Uygulama sonrası herhangi bir bakım gerekir mi?',
                    description: 'Özel bir bakım gerektirmemektedir, sonrasında günlük yaşantınıza hemen dönebilirsiniz. Bunun haricinde sıcak banyo ve saunadan 2-3 hafta uzak kalmanızı, çok düzenli bir şekilde güneş koruyucu kullanmanızı öneriyoruz.',
                },
                {
                    title: 'Sonuçları ne zaman görülür?',
                    description: 'Endolift uygulamasından hemen sonra istenmeyen yağların erimesi sayesinde estetik etki anında gözlemlenir. Sonrasındaki 3-6 ay boyunca derinin elastikiyetini sağlayan fibröz bantların boyları kısalmaya ve yeni kolajen lifleri oluşmaya devam eder. Cildin kalitesi artar, daha gergin ve genç bir görünüme sahip olursunuz ve etkinlik çoğunlukla 2 yıl devamlılık gösterir.',
                }
            ]
        },
    },
    {
        mediaid: 'doc-dr-ebru-karagun-cildimizin-kalitesini-nasil-koruruz',
        title: "Doç. Dr. Ebru Karagün – Cildimizin Kalitesini Nasıl Koruruz?",
        image: "/img/media/cildimizi-nasil-koruruz-2-700x693.jpg",
        date: '14 Temmuz 2023',
        medialink: 'https://ankaralifedergisi.com/cildimizin-kalitesini-nasil-koruruz',
        mediaDesc: 'Cildimizin Kalitesini Nasıl Koruruz? Cildiniz, hayatınızın hikayelerini ortaya çıkaran vücudunuzun penceresidir. Ergenlik yıllarındaki sivilcelerden, ...',
        content: {
            blogPageTitle: "Doç. Dr. Ebru Karagün – Cildimizin Kalitesini Nasıl Koruruz?",
            blogPageDesc: "Cildimizin Kalitesini Nasıl Koruruz? Cildiniz, hayatınızın hikayelerini ortaya çıkaran vücudunuzun penceresidir. Ergenlik yıllarındaki sivilcelerden, hamileliğin parlak ışıltısına ve yaşlanmadaki güneş lekelerine kadar hem yaşınız hem de sağlığınız cildinize yansır. Birçok faktör cildinizi etkiler. Genetik, yaşın kronolojik olarak ilerlemesi, hormonlar ve diyabet gibi durumlar cildi etkileyen iç faktörlerdir. Korunmasız güneşe maruz kalma, sağlıksız beslenme, stres, uyku eksikliği, yetersiz egzersiz, az su tüketimi, sigara ve alkol dışsal faktörler olarak cildi yaşlandırır 1. Sağlıklı beslenin Ne yediğiniz, cildinize sürdüğünüz ürünler kadar önemlidir. Diyetiniz cilt sağlığınızı içten dışa doğru iyileştirebilir, bu nedenle sağlıklı...",
            metaDescription: "Doç. Dr. EbruKaragün, başkentin en çok okunan yayını Ankara Life Dergisi’ne konuk oldu. Cilt korunmasına yönelik bilgilerini siz değerli okurlarımız adına paylaştı.",
            blogDetailPageInfo: [
                {
                    title: 'Cildimizin Kalitesini Nasıl Koruruz?',
                    description: 'Cildiniz, hayatınızın hikayelerini ortaya çıkaran vücudunuzun penceresidir. Ergenlik yıllarındaki sivilcelerden, hamileliğin parlak ışıltısına ve yaşlanmadaki güneş lekelerine kadar hem yaşınız hem de sağlığınız cildinize yansır. Birçok faktör cildinizi etkiler. Genetik, yaşın kronolojik olarak ilerlemesi, hormonlar ve diyabet gibi durumlar cildi etkileyen iç faktörlerdir. Korunmasız güneşe maruz kalma, sağlıksız beslenme, stres, uyku eksikliği, yetersiz egzersiz, az su tüketimi, sigara ve alkol dışsal faktörler olarak cildi yaşlandırır.',
                },
                {
                    title: '1. Sağlıklı beslenin',
                    description: 'Ne yediğiniz, cildinize sürdüğünüz ürünler kadar önemlidir. Diyetiniz cilt sağlığınızı içten dışa doğru iyileştirebilir, bu nedenle sağlıklı bir cilt, sağlıklı beslenmeyle başlar. Antioksidan içeriği yüksek, trans yağ ve rafine şeker içermeyen bir diyetinizin olması gerekiyor. Ne kadar şeker içeriği yüksek karbonhidrat ağırlıklı bir beslenmeniz olursa cildinizdeki kolajenin yapısını o kadar çok bozarsınız.',
                },
                {
                    title: '2. Stresi kontrol altında tutun',
                    description: 'Önemli bir olaydan hemen önce yüzünüzde sivilce belirdiğini hiç fark ettiniz mi? Yapılan çalışmalarda stres seviyeleri ve cilt sorunları arasında ilişki olduğu cildin yaşlanma sürecini artırdığı tespit edilmiştir.',
                },
                {
                    title: '3. Cildinizi nemlendirin',
                },
                {
                    title: '4. Güneş Koruyucu Kullanımı',
                    description: 'Sizin yapabilecekleriniz arasında en önemlisi düzenli güneş koruyucu kullanmak. Cildimizin yaşlanması sürecinde kontrol altında tutabileceğimiz faktörler kadar kontrol altında tutamayacağımız faktörler de mevcuttur. Bunlar; genetik, kronolojik olarak yaşınızın ilerlemesidir. Cildinizin kalitesini korumak, daha ışıltılı daha parlak, daha ince çizgilerden arınmış, renk tonu eşitsizliği olmayan, daha sıkı daha elastikiyeti fazla cilt için kozmetik uygulamalarında yapılması gerekir.'
                },
                {
                    title: '5: Mineral aşı',
                    description: 'Seyreltilmiş yani dilüe edilmiş kalsiyum hidroksiapatit içeren bir uygulamadır, sıvı yüz germe olarak da adlandırılır. Cildi uyarıcı etkileri 9 ay-1 yıla kadar devam eder. Cildin parlaklığını, sıkılığını, ince çizgilerini azaltarak lifting etkisi sağlar.',
                },
                {
                    title: '6: Gençlik aşıları',
                    description: 'İçeriğinde cilt altına yayılabilen yoğun içerikli hyalüronik asit, mineral, vitamin, aminoasit, biyo uyarıcı etkinliği ile cildin yenilenme ve onarılma sürecini hızlandırır, nemini sağlar, ince çizgileri azaltır.',
                },
                {
                    title: '7: Somon DNA:',
                    description: 'Somon balığının DNA’sından elde edilen son derece doğal olan bir ürünün, cildi uyarıcı ve onarıcı etkinliği yüksektir.',
                },
                {
                    title: '8: Cihaz İşlemleri ( Scarlet X )',
                    description: 'Cildin yenilenmesi, gençleştirilmesi amacıyla son yıllarda Scarlet X teknolojisi kullanılmaya başlanmıştır. Scarlet X, ABD Silikon Vadisi’nde geliştirilen son teknoloji üst segment bir radyofrekans uygulamasıdır. Cildin bir bütün olarak gençleştirilmesinde, ince kırışıklıkların giderilmesinde, cilt lekeleri ve kızarıklığının tedavi edilmesinde, cilt tonunun aydınlatılmasında, gözeneklerin sıkılaştırılmasında, akne izlerinin iyileştirilmesinde, deri çatlaklarının (stria) tedavisinde güvenlik ve etkinlik açısından klinik olarak kanıtlanmış FDA onaylı bir cihazdır.',
                },
                {
                    description: 'Yaşlanmayı durduramayız ama azaltabiliriz. Beş yıllık sürecimizi 15 yıla yayabiliriz. Daha yaşımızın iyisi, daha sağlıklı, parlak, ince çizgileri az, gerginliği ve elastikiyeti fazla bir cilde sahip olmak için yapılması gerekenleri sizlere aktardım. Her insan hak ettiği yaştadır (Coco Chanel) ve hayat her zaman yakaladığımız yerden başlar.',
                }
            ]
        },
    }
];

export default media;

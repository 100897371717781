import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import HomeTwoSingleAbout from '../../../components/HomeTwoSingleAbout/HomeTwoSingleAbout';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const HomeTwoAbout = () => {
   const [visualLang, setVisualLang] = useState('Yaşınızın çok daha iyisi olmak için kliniğimizdeki son teknoloji tedaviler ile doğal, zarif ve güzel bir görünüme kavuşun. Dolgu, mezoterapi, iple yüz germe ve tüm kozmetik uygulamalar için iletişime geçin')
   const { t, i18n } = useTranslation();

   useEffect(() => {
      if (i18n.language === 'en') {
         setVisualLang('Attain a naturally elegant and beautiful appearance that far surpasses your age with our clinic’s cutting-edge treatments. Contact us for filler applications, face lifting, mesotherapy, and medical treatments.')
      } else {
         setVisualLang('Yaşınızın çok daha iyisi olmak için kliniğimizdeki son teknoloji tedaviler ile doğal, zarif ve güzel bir görünüme kavuşun. Dolgu, mezoterapi, iple yüz germe ve tüm kozmetik uygulamalar için iletişime geçin')
      }
   }, [i18n.language])

   return (
      <>
         <section className="about-area about-area-mid pt-80 pb-90">
            <Helmet>
               <title>{'Doç. Dr. Ebru Karagün'}</title>
               <meta name='description' content={visualLang} />
            </Helmet>
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-12 col-md-12">
                     <div className="row">

                        <HomeTwoSingleAbout icon="5" title={`${t('mainPage.mesotherapy')}`} link={i18n.language === 'en' ? "/en/applications/mesotherapy-applications" : "/uygulamalar/mezoterapi-uygulamalari"} spacing="b-10" description={`${t('mainPage.mesotherapyDesc')}`} />
                        <HomeTwoSingleAbout icon="2" title={`${t('mainPage.fillingApplications')}`} link={i18n.language === 'en' ? "/en/applications/filler-applications" : "/uygulamalar/dolgu-uygulamalari"} spacing="t-10" description={`${t('mainPage.fillingApplicationsDesc')}`} />
                        <HomeTwoSingleAbout icon="6" title={`${t('mainPage.faceLifting')}`} link={i18n.language === 'en' ? "/en/applications/thread-applications" : "/uygulamalar/iple-olan-uygulamalar"} description={`${t('mainPage.faceLiftingDesc')}`} />
                        <HomeTwoSingleAbout icon="7" title={`${t('mainPage.healthyLiving')}`} link={i18n.language === 'en' ? "/en/applications/healthy-living" : "/uygulamalar/saglikli-yasam"} spacing="t-10" description={`${t('mainPage.healthyLivingDesc')}`} />
                        <HomeTwoSingleAbout icon="8" title={`${t('mainPage.deviceOperations')}`} link={i18n.language === 'en' ? "/en/applications/device-procedures" : "/uygulamalar/cihaz-islemleri"} spacing="b-10" description={`${t('mainPage.deviceOperationsDesc')}`} />
                        <HomeTwoSingleAbout icon="1" title={`${t('mainPage.medicalTreatments')}`} link={i18n.language === 'en' ? "/en/applications/medical-applications" : "/uygulamalar/medikal-uygulamalar"} spacing="t-10" description={`${t('mainPage.medicalTreatmentsDesc')}`} />

                     </div>
                  </div>

                  <div className="col-xl-6 col-lg-12 col-md-11">
                     <div className="about-right-side pt-25 mb-30">
                        <div className="about-title mb-20">
                           <h3 className="pink-color">{t('mainPage.welcome')}</h3>
                           <h3>{t('mainPage.drTitle')}</h3>
                           <br />
                        </div>
                        <div className="about-text">
                           <p>
                              {t('mainPage.aboutDesc1')}
                              <br /><br /> {t('mainPage.aboutDesc2')}
                              <br /><br /> {t('mainPage.aboutDesc3')}  <br />
                              <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
                                 “{t('mainPage.aboutDesc4')}” </span> <br /><br />
                              <span style={{ fontStyle: "italic", fontWeight: "bold" }}> {t('mainPage.aboutDesc5')} </span>
                              {t('mainPage.aboutDesc6')}
                           </p><br />
                        </div>
                        <div className="button-area">
                           <Link to={i18n.language === 'en' ? "/en/resume" : "/ozgecmis"} className="primary_btn btn-icon ml-0"><span><i className="fab fa-leanpub"></i></span>{t('mainPage.readMore')}</Link>
                        </div>
                     </div>
                  </div>

               </div>
            </div>
         </section>
      </>
   );
};

export default HomeTwoAbout;
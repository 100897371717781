import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BlogSmSingleColumn = ({ image, masonry, column, id, title, decription, date }) => {
   const { t, i18n } = useTranslation()
   return (
      <>
         <div key={id} className={masonry ? '' : `col-lg-${column} col-md-6`}>
            <article className="postbox post format-image mb-40">
               <div className="postbox__thumb">
                  <Link to={`/${i18n.language === 'en' ? 'en/blogs' : 'blogs'}/${id}`}>
                     <img src={image} alt="blog img" />
                  </Link>
               </div>
               <div className="postbox__text p-30">
                  <div className="post-meta mb-15">
                     <span><i className="far fa-calendar-check"></i> {date} </span>
                  </div>
                  <h3 className="blog-title blog-title-sm">
                     <Link to={`/${i18n.language === 'en' ? 'en/blogs' : 'blogs'}/${id}`}>{title}</Link>
                  </h3>
                  <div className="post-text">
                     <p>{decription}</p>
                  </div>
                  <div className="read-more">
                     <Link to={`/${i18n.language === 'en' ? 'en/blogs' : 'blogs'}/${id}`} className="read-more">{t('mainPage.readMoreBlog')} <i className="fas fa-long-arrow-alt-right"></i></Link>
                  </div>
               </div>
            </article>
         </div>
      </>
   );
};

export default BlogSmSingleColumn;
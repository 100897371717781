import React from 'react';
import { Link } from 'react-router-dom';

const HomeTwoSingleAbout = ({ icon, title, spacing, link, description }) => {
    return (
        <>
            <div className="col-xl-6 col-lg-6 col-md-6">
                <Link to={link}>
                    <div className={`feature-box m${spacing}`}>
                        <div className="feature-small-icon mb-35">
                            <img src={`/img/icon/s-fea-icon-${icon}.png`} alt={icon} />
                        </div>
                        <div className="feature-small-content">
                            <h3>{title}</h3>
                            <p>{description}</p>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    );
};

export default HomeTwoSingleAbout;
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import ScrollTop from './components/ScrollTop';
import AllContext from './context/AllContext';
import Appointment from './pages/Appointment/Appointment';
import BlogDetails from './pages/BlogDetails/BlogDetails';
import BlogNoSideBar from './pages/BlogNoSideBar/BlogNoSideBar';
import BlogTwoColumn from './pages/BlogTwoColumn/BlogTwoColumn';
import Contact from './pages/Contact/Contact';
import DoctorDetails from './pages/Doctors/DoctorDetails/DoctorDetails';
import HomeTwo from './pages/HomeTwo/HomeTwo';
import HealthyLiving from './pages/HealthyLiving/HealthyLiving';
import PostDetails from './pages/PostDetails/PostDetails';
import MediaDetails from './pages/MediaDetails/MediaDetails';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import './App.css';
import "./i18n";
import 'react-calendar/dist/Calendar.css';
import NotFound from './pages/NotFound/NotFound';

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    let newPath = window.location.pathname.split("/")

    if (newPath[1] === 'en') {
      i18n.changeLanguage('en')
    } else {
      i18n.changeLanguage('tr')
    }
  }, [])

  return (
    <>
      <AllContext>
        <BrowserRouter>
          <ScrollTop />

          <Routes>
            <Route path={i18n.language === 'en' ? "/en" : "/"} element={<HomeTwo />} />

            {/* Dil değişimi yönlendirmeleri start */}
            <Route path={i18n.language === 'en' ? '/en/uygulamalar/iple-olan-uygulamalar' : '/applications/thread-applications'} element={<Navigate to={
              i18n.language === 'en' ? '/en/applications/thread-applications' : '/uygulamalar/iple-olan-uygulamalar'
            } />} />
            <Route path={i18n.language === 'en' ? '/en/uygulamalar/iple-olan-uygulamalar/:id' : '/applications/thread-applications/:id'} element={<Navigate to={
              i18n.language === 'en' ? '/en/applications/thread-applications' : '/uygulamalar/iple-olan-uygulamalar'
            } />} />

            <Route path={i18n.language === 'en' ? '/en/uygulamalar/medikal-uygulamalar' : '/applications/medical-applications'} element={<Navigate to={
              i18n.language === 'en' ? '/en/applications/medical-applications' : '/uygulamalar/medikal-uygulamalar'
            } />} />
            <Route path={i18n.language === 'en' ? '/en/uygulamalar/medikal-uygulamalar/:id' : '/applications/medical-applications/:id'} element={<Navigate to={
              i18n.language === 'en' ? '/en/applications/medical-applications' : '/uygulamalar/medikal-uygulamalar'
            } />} />

            <Route path={i18n.language === 'en' ? '/en/uygulamalar/saglikli-yasam' : '/applications/healthy-living'} element={<Navigate to={
              i18n.language === 'en' ? '/en/applications/healthy-living' : '/uygulamalar/saglikli-yasam'
            } />} />
            <Route path={i18n.language === 'en' ? '/en/uygulamalar/saglikli-yasam/:id' : '/applications/healthy-living/:id'} element={<Navigate to={
              i18n.language === 'en' ? '/en/applications/healthy-living' : '/uygulamalar/saglikli-yasam'
            } />} />

            <Route path={i18n.language === 'en' ? '/en/uygulamalar/mezoterapi-uygulamalari' : '/applications/mesotherapy-applications'} element={<Navigate to={
              i18n.language === 'en' ? '/en/applications/mesotherapy-applications' : '/uygulamalar/mezoterapi-uygulamalari'
            } />} />
            <Route path={i18n.language === 'en' ? '/en/uygulamalar/mezoterapi-uygulamalari/:id' : '/applications/mesotherapy-applications/:id'} element={<Navigate to={
              i18n.language === 'en' ? '/en/applications/mesotherapy-applications' : '/uygulamalar/mezoterapi-uygulamalari'
            } />} />

            <Route path={i18n.language === 'en' ? '/en/uygulamalar/cihaz-islemleri' : '/applications/device-procedures'} element={<Navigate to={
              i18n.language === 'en' ? '/en/applications/device-procedures' : '/uygulamalar/cihaz-islemleri'
            } />} />
            <Route path={i18n.language === 'en' ? '/en/uygulamalar/cihaz-islemleri/:id' : '/applications/device-procedures/:id'} element={<Navigate to={
              i18n.language === 'en' ? '/en/applications/device-procedures' : '/uygulamalar/cihaz-islemleri'
            } />} />

            <Route path={i18n.language === 'en' ? '/en/uygulamalar/dolgu-uygulamalari' : '/applications/filler-applications'} element={<Navigate to={
              i18n.language === 'en' ? '/en/applications/filler-applications' : '/uygulamalar/dolgu-uygulamalari'
            } />} />
            <Route path={i18n.language === 'en' ? '/en/uygulamalar/dolgu-uygulamalari/:id' : '/applications/filler-applications/:id'} element={<Navigate to={
              i18n.language === 'en' ? '/en/applications/filler-applications' : '/uygulamalar/dolgu-uygulamalari'
            } />} />


            <Route path={i18n.language === 'en' ? '/en/medyada-biz' : '/media'} element={<Navigate to={
              i18n.language === 'en' ? '/en/media' : '/medyada-biz'
            } />} />
            <Route path={i18n.language === 'en' ? '/en/medyada-biz/:id' : '/media/:id'} element={<Navigate to={
              i18n.language === 'en' ? '/en/media' : '/medyada-biz'
            } />} />


            <Route path={i18n.language === 'en' ? '/en/randevu-al' : '/appointment'} element={<Navigate to={
              i18n.language === 'en' ? '/en/appointment' : '/randevu-al'
            } />} />

            <Route path={i18n.language === 'en' ? '/en/ozgecmis' : '/resume'} element={<Navigate to={
              i18n.language === 'en' ? '/en/resume' : '/ozgecmis'
            } />} />
            {/* Dil değişimi yönlendirmeleri end */}


            <Route path={`/${i18n.language === 'en' ? 'en/applications' : 'uygulamalar'}/:application`} element={<HealthyLiving />} />
            <Route path={`/${i18n.language === 'en' ? 'en/applications' : 'uygulamalar'}/:application/:id`} element={<BlogDetails />} />


            <Route path={i18n.language === 'en' ? "/en/blogs" : "/blogs"} element={<BlogTwoColumn />} />
            <Route path={i18n.language === 'en' ? "/en/blogs/:id" : "/blogs/:id"} element={<PostDetails />} />

            <Route path={i18n.language === 'en' ? "/en/appointment" : "/randevu-al"} element={<Appointment />} />



            <Route path={i18n.language === 'en' ? "/en/media" : "/medyada-biz"} element={<BlogNoSideBar />} />
            <Route path={i18n.language === 'en' ? "/en/media/:id" : "/medyada-biz/:id"} element={<MediaDetails />} />

            <Route path={i18n.language === 'en' ? "/en/resume" : "/ozgecmis"} element={<DoctorDetails />} />
            <Route path={i18n.language === 'en' ? "/en/contact" : "/contact"} element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </AllContext>
    </>
  );
}

export default App;

import React from 'react';
import SingleBlog from '../../../components/SingleBlog/SingleBlog';
import Slider from "react-slick";
import { useTranslation } from 'react-i18next';
import media from '../../../fakeData/media/media';
import enMedia from '../../../fakeData/media/en-media';

const HomeTwoBlogs = () => {
    const { t, i18n } = useTranslation();
    const settings = {
        autoplay: true,
        autoplaySpeed: 5000,
        dots: true,
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
        ]

    };

    return (
        <>
            <section className="latest-news-area gray-bg pt-115 pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title text-center pos-rel mb-70">
                                <div className="section-icon">
                                    <img className="section-back-icon" src="/img/section/section-back-icon.png" alt="" />
                                </div>
                                <div className="section-text pos-rel">
                                    <h2 className='sub-custom-header'>{t('headerTwo.weInTheMedia')}</h2>
                                    <h1>{t('mainPage.mediaTitle')}</h1>
                                </div>
                                <div className="section-line pos-rel">
                                    <img src="/img/shape/section-title-line.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                        <Slider {...settings}>
                            {
                                i18n.language === 'en' ?
                                    enMedia.map((e, i) => {
                                        return <SingleBlog mediaid={`${e.mediaid}`} date={`${e.date}`} description={`${e.mediaDesc}`} title={`${e.title}`} image={`${e.image}`} />
                                    }) :
                                    media.map((e, i) => {
                                        return <SingleBlog mediaid={`${e.mediaid}`} date={`${e.date}`} description={`${e.mediaDesc}`} title={`${e.title}`} image={`${e.image}`} />
                                    })
                            }
                        </Slider>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HomeTwoBlogs;
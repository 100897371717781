import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Accordion = ({ title, description, blogTitle }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleAccordion = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="accordion inner-content mb-20">
            <div className="accordion-title" onClick={toggleAccordion}>
                <h4>{title}</h4>
                <span className="accordion-icon">
                    <i className={`fas ${isExpanded ? 'fa-caret-up' : 'fa-caret-down'}`} style={{ color: 'black' }}></i>
                </span>
            </div>
            {isExpanded && <p className="accordion-description" dangerouslySetInnerHTML={{ __html: description }} />}
        </div>
    );
};

const MediaDetailsLeftSide = ({ details_topBar, postData }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="col-lg-8">
                <article className="postbox post format-image mb-40">

                    {details_topBar}
                    <div className="postbox__text bg-none">
                        <div className="post-meta mb-15">
                            {postData && postData.date ? <span><i className="far fa-calendar-check"></i> {postData && postData.date} </span> : null}
                        </div>
                        <h3 className="blog-title">
                            {postData && postData.applicationTitle}
                        </h3>
                        <div className="post-text mb-40">
                            {postData && postData.content?.blogDetailPageInfo?.map((item, index) => (
                                <div key={index}>
                                    {item.title && <h4>{item.title}</h4>}
                                    {item.description && <p dangerouslySetInnerHTML={{ __html: item.description }} />}
                                </div>
                            ))}

                            {postData && postData.accordeon?.map((item, index) => (
                                <Accordion key={index} title={item.title} description={item.description} />
                            ))}

                            <div className="download-area">
                                {postData && postData.pdflink && <a className="download-box mb-20" href={postData.pdflink} target='_blank' rel="noreferrer">
                                    <div className="single-download-inner">
                                        <img src="/img/icon/pdf.png" alt="" />
                                        <span>{t('mediaDetailsPage.clickForPdf')}</span>
                                    </div>
                                </a>}
                                {postData && postData.medialink && <a className="download-box mb-20" href={postData.medialink} target='_blank' rel="noreferrer">
                                    <div className="single-download-inner">
                                        <img src="/img/icon/doc.png" alt="" />
                                        <span>{t('mediaDetailsPage.clickForNew')}</span>
                                    </div>
                                </a>}
                            </div>

                            <blockquote>
                                <p>{t('mainPage.aboutDesc4')}</p>
                                <footer>- Coco Chanel</footer>
                            </blockquote>
                        </div>

                    </div>
                </article>
            </div>
        </>
    );
};

export default MediaDetailsLeftSide;
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

const ContactFormArea = () => {
   const { t, i18n } = useTranslation();

   const handleClick = () => {
      Swal.fire({
         icon: 'success',
         title: `${t('contactPage.swalTitle')}`,
         text: `${t('contactPage.swalText')}`
      })
   }

   return (
      <>
         <section className="contact-form-area gray-bg pt-100 pb-100">
            <div className="container">
               <div className="form-wrapper">
                  <div className="row align-items-center">
                     <div className="col-xl-8 col-lg-8">
                        <div className="section-title mb-55">
                           <p><span></span> {t('contactPage.formSubHeader')}</p>
                           <h1>{t('contactPage.formHeader')}</h1>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-3 d-none d-xl-block ">
                        <div className="section-link mb-80 text-end">
                           <Link to={i18n.language === 'en' ? "/en/appointment" : "/randevu-al"}
                              className="primary_btn btn-icon ml-0"><span>+</span>{t('headerTwo.appointment')}</Link>
                        </div>
                     </div>
                  </div>
                  <div className="contact-form">
                     <form id="contact-form" action="#">
                        <div className="row">
                           <div className="col-lg-6">
                              <div className="form-box user-icon mb-30">
                                 <input type="text" name="name" placeholder={`${t('mainPage.formName')}`} />
                              </div>
                           </div>
                           <div className="col-lg-6">
                              <div className="form-box email-icon mb-30">
                                 <input type="text" name="email" placeholder={`${t('mainPage.formMail')}`} />
                              </div>
                           </div>
                           <div className="col-lg-6">
                              <div className="form-box phone-icon mb-30">
                                 <input type="text" name="phone" placeholder={`${t('mainPage.formPhone')}`} />
                              </div>
                           </div>
                           <div className="col-lg-6">
                              <div className="form-box subject-icon mb-30">
                                 <input type="text" name="subject" placeholder={`${t('mainPage.formSubject')}`} />
                              </div>
                           </div>
                           <div className="col-lg-12">
                              <div className="form-box message-icon mb-30">
                                 <textarea name="message" id="message" cols="30" rows="10"
                                    placeholder={`*${t('mainPage.formMessage')}`}></textarea>
                              </div>
                              <div className="contact-btn text-center">
                                 <button className="primary_btn btn-icon ml-0" onClick={handleClick} type="button"><span><i className="fas fa-share"></i></span> {`${t('mainPage.formSend')}`}</button>
                              </div>
                           </div>
                        </div>
                     </form>
                     <p className="ajax-response text-center"></p>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default ContactFormArea;
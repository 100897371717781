import React from 'react'

export const SingleSliderItem = ({description}) => {
    return(
        <>
           <div className="col-xl-12 col-lg-12 col-md-12">
                 <div className='custom-slider-div'>
                     <img src="/img/icon/content-marketing.png" className='custom-slider-img' alt="" />
                    <div className="latest-news-content-box" style={{ padding: "15px" }}>
                       <div className="latest-news-content">
                           <p> {description} </p>
                       </div>
                    </div>
                 </div>
           </div>
        </>
        );
}

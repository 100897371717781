import React from 'react';
import BlogDetailsLeftSide from '../../../components/BlogDetailsLeftSide/BlogDetailsLeftSide';
import BlogDetailsRightSide from '../../../components/BlogDetailsRightSide/BlogDetailsRightSide';
import { Helmet } from 'react-helmet-async';

const BlogDetailsArea = ({ blogData }) => {
   return (
      <>
         <section className="blog-area pt-50 pb-80">
            <Helmet>
               <title>{`${blogData[0].title}`}</title>
               <meta name='description' content={blogData[0].content.metaDescription} />
               {blogData[0].id === 'goz-alti-isik-dolgusu' ?
                  <meta name="robots" content="noindex" /> : null
               }
            </Helmet>
            <div className="container">
               <div className="header-info position-absolute d-md-none flex" style={{ top: 65 }}>
                  <a href="tel:+903125574646"> <span><i className="fas fa-phone"></i> +90 312 557 46 46</span></a>
                  <a href="tel:+905332691271"> <span><i className="fas fa-phone"></i> +90 533 269 12 71</span></a>
               </div>
               <div className="row">

                  <BlogDetailsLeftSide blogData={blogData[0]} details_topBar={<div className="postbox__thumb mb-35">
                     <img src={blogData[0]?.image} alt="blog img" />
                  </div>} />

                  <BlogDetailsRightSide />

               </div>
            </div>
         </section>
      </>
   );
};

export default BlogDetailsArea;
const media = [
    {
        mediaid: 'skin-stretch-marks',
        title: "Assoc. Prof. Dr. Ebru Karagün – Skin Stretch Marks",
        image: "/img/media/doc-dr-ebru-karagun-cilt-catlaklari.jpg",
        date: 'March 28, 2023',
        medialink: 'https://www.magdergi.com/roportajlar/doc-dr-ebru-karagun-cilt-catlaklari',
        mediaDesc: 'Assoc. Prof. Ebru Karagün Skin Cracked Dermatology Assoc. Prof. Ebru Karagün dealt with the formation of skin cracks.',
        content: {
            blogPageTitle: "Assoc. Prof. Dr. Ebru Karagün – Skin Stretch Marks",
            blogPageDesc: "Assoc. Prof. Dr. Ebru Karagün, who accepts patients in her clinic, sheds light on the formation of skin stretch marks, treatment methods, and ways to minimize the formation of stretch marks. Could you start by telling us a bit about yourself? What have you done so far, what are your educations and specialties? I am from Samsun. I completed my education, including university, in Samsun. I received my assistant training in Ankara, at the University of Health Sciences, Dışkapı Yıldırım Beyazıt Training and Research Hospital. After my assistantship, I completed my compulsory service at Ağrı State Hospital. I worked as an assistant professor at Istanbul Medipol University and Düzce University Faculty of Medicine. The most enjoyable periods of my professional life were fulfilling both the role of a physician and an instructor by training medical students and assistant physicians. In 2021, I received the title of associate professor and continue my academic career as a visiting faculty member at Istinye University Faculty of Medicine. Since April 2022, I have been fulfilling my duties as a physician in my clinic at Moment Beştepe Plaza.",
            metaDescription: "Assoc. Prof. Dr. Ebru Karagün sheds light on the treatment methods of skin stretch marks and ways to minimize their formation.",
            blogDetailPageInfo: [
                {
                    title: 'Skin Stretch Marks',
                    description: 'Assoc. Prof. Dr. Ebru Karagün, who accepts patients in her clinic, sheds light on the formation of skin stretch marks, treatment methods, and ways to minimize the formation of stretch marks.',
                },
                {
                    title: 'Could you start by telling us a bit about yourself? What have you done so far, what are your educations and specialties?',
                    description: 'I am from Samsun. I completed my education, including university, in Samsun. I received my assistant training in Ankara, at the University of Health Sciences, Dışkapı Yıldırım Beyazıt Training and Research Hospital. After my assistantship, I completed my compulsory service at Ağrı State Hospital. I worked as an assistant professor at Istanbul Medipol University and Düzce University Faculty of Medicine. The most enjoyable periods of my professional life were fulfilling both the role of a physician and an instructor by training medical students and assistant physicians. In 2021, I received the title of associate professor and continue my academic career as a visiting faculty member at Istinye University Faculty of Medicine. Since April 2022, I have been fulfilling my duties as a physician in my clinic at Moment Beştepe Plaza.',
                },
                {
                    title: 'What treatments do you apply in your clinic?',
                    description: 'In our clinic, we perform cosmetic applications such as filler-mesotherapy applications, thread lifting methods, device treatments (Scarlet X laser), ozone-glutathione therapy, and the diagnosis and treatment of all dermatological diseases.',
                },
                {
                    title: 'How and why do skin stretch marks form?',
                    description: '<p style="text-align:left">Striae or skin stretch marks can be described as the appearance of lines due to the weakening of support tissues in the lower layer of the skin and stretching beyond its expansion capacity. They are referred to as “striae distensae (SD)”, “stretch marks”, and “stria rubra” or “alba”. Often appearing during pregnancy, they are also known as “pregnancy stretch marks”. Skin stretch marks are not a serious health problem, but they are one of the most significant cosmetic problems today, as they were in the past. They are commonly seen between the ages of five and fifty, with an incidence rate ranging from eleven to eighty-eight percent. They are observed 2.5 times more frequently in women due to pregnancy. The exact cause of skin stretch marks is not known, but many hypotheses are discussed, including mechanical effects (e.g., mechanical stretching of tissues and a decrease in subcutaneous support tissue, such as during pregnancy, weight gain, weightlifting, especially pull-ups, rapid height growth), corticosteroid treatments, genetic factors. During adolescence, they are often seen following the appearance of armpit and genital hair. Typically, in women, they appear on the upper thighs, hips, and breasts, while in men, they appear on the outer thighs, middle back, and waist areas.</p>',
                },
                {
                    description: 'We can list the risk factors for stretch marks observed during pregnancy as follows: They often begin in the last third month of pregnancy and continue until the end of pregnancy. They are related to familial predisposition, getting pregnant at a young age, significant weight gain during pregnancy, and abdominal growth (due to a large baby, excess amniotic fluid, or multiple pregnancies). After pregnancy, skin stretch marks are most commonly observed on the abdomen, breasts, and upper thighs.',
                },
                {
                    description: 'There are two clinical forms of skin stretch marks:<ul><li><strong>Striae rubra (early stage)</strong></li><li><strong>Striae alba (late stage)</strong></li></ul>',
                },
                {
                    description: 'Initially, skin stretch marks appear red and purplish, hence they are termed “striae rubrae-SR” which means red. Later, this color fades, appearing like a wrinkle or even a scar on the skin, and is termed “striae albae-SA” which means white. Treatment options for skin stretch marks vary depending on whether the stretch marks are in the early or late stage clinically.',
                },
                {
                    title: 'Is it possible to completely treat these stretch marks?',
                    description: 'Although skin stretch marks are not a medical problem, they are perceived as a serious aesthetic problem, especially among women.',
                },
                {
                    description: 'To prevent them; it is crucial to identify the risks in skin stretch marks and educate the patient about these risk factors. Creams should be started from the twelfth week of pregnancy. Especially; creams containing centella asiatica (trofolastin), vitamin C, vitamin E, and rosehip oil have been proven to be effective in studies.',
                },
                {
                    description: 'Commonly used almond oil, cocoa butter, and olive oil have not shown sufficient positive results in scientific studies. It is known that massaging the creams during pregnancy reduces the formation of skin stretch marks. For treatment; Scarlet X, dermapen laser treatments, chemical peeling, PRP, and mesotherapy are applied. Treatment is more effective if done in the early stage, the pink-purple stage.',
                },
                {
                    description: 'Treatment options are available for all age groups and during breastfeeding periods. There are treatment options for both the pink-purple stage and the white stage of skin stretch marks; however, it is especially important to know that treatment is more effective if applied in the early, pink-purple stage.',
                },
                {
                    description: '<p>Creams containing centella asiatica (trofolastin), vitamin C, vitamin E, and rosehip oil have been proven to be effective in studies</p>',
                },
                {
                    title: 'In our clinic;',
                    description: 'we first evaluate why the person has skin stretch marks and whether the stretch marks are in the early or late stage. Then, we create a treatment plan for at least three or four sessions, scheduled as monthly treatment sessions. We combine Scarlet X, dermapen treatments with PRP-mesotherapy applications in our planning. Treatment options may vary depending on the response. Since the aim is to enhance the restructuring process under the skin, we see the effectiveness of the treatment from the second month. With patient-doctor cooperation, we can reduce skin stretch marks by forty to eighty percent.',
                },
                {
                    title: 'Will it recur? What steps should be taken after treatment?',
                    description: 'If the person gains and loses weight quickly, unfortunately, there is a possibility of recurrence. Weight balance should be particularly monitored after treatment. If there are uncontrollable situations, such as using corticosteroids due to any illness, gaining weight, or rapid height growth periods, it may be necessary to seek expert advice to minimize the formation of skin stretch marks.',
                },
            ]
        },
    },
    {
        mediaid: 'photoaging',
        title: "Assoc. Prof. Dr. Ebru Karagün – Photoaging",
        image: "/img/media/doc-dr-ebru-karagun-fotoyaslanma.jpg",
        date: 'March 28, 2023',
        mediaDesc: 'Assoc. Prof. Ebru Karagün Photoaging Explaining the causes and consequences of photoaging by addressing the factors affecting skin aging, Assoc. Prof. Ebru...',
        content: {
            blogPageTitle: "Assoc. Prof. Dr. Ebru Karagün – Photoaging",
            blogPageDesc: "Assoc. Prof. Dr. Ebru Karagün explains the factors affecting skin aging, the causes and effects of photoaging, and details methods to minimize sun damage for MAG readers… What is Photoaging (Sun-Damaged Skin)? Aging is an inevitable and natural process that increases over time. Our aging process begins from the day we are born; however, have you ever wondered why some people look much older than they are, while others look younger? Skin aging occurs both chronologically (age-related) and through photoaging. The changes in our skin caused by ultraviolet (UV) rays are called “photoaging.” Wrinkles, fine lines, and uneven skin tone are unavoidable skin problems that often appear as we age. The main culprit behind these changes in our skin is photoaging; the damage caused by exposure to sunlight and UV light. Photoaging, which accounts for ninety percent of visible changes in the skin, is a direct result of cumulative sun damage over your lifetime. How old your skin looks is directly proportional to how much time you spend in the sun.",
            metaDescription: "Assoc. Prof. Dr. Ebru Karagün explains the causes and effects of photoaging and methods to minimize sun damage to mag readers.",
            blogDetailPageInfo: [
                {
                    title: 'Photoaging',
                    description: 'Assoc. Prof. Dr. Ebru Karagün explains the factors affecting skin aging, the causes and effects of photoaging, and details methods to minimize sun damage for MAG readers…',
                },
                {
                    title: 'What is Photoaging (Sun-Damaged Skin)?',
                    description: 'Aging is an inevitable and natural process that increases over time. Our aging process begins from the day we are born; however, have you ever wondered why some people look much older than they are, while others look younger? Skin aging occurs both chronologically (age-related) and through photoaging. The changes in our skin caused by ultraviolet (UV) rays are called “photoaging.” Wrinkles, fine lines, and uneven skin tone are unavoidable skin problems that often appear as we age. The main culprit behind these changes in our skin is photoaging; the damage caused by exposure to sunlight and UV light. Photoaging, which accounts for ninety percent of visible changes in the skin, is a direct result of cumulative sun damage over your lifetime. How old your skin looks is directly proportional to how much time you spend in the sun.',
                },
                {
                    title: 'Photoaging Risk Factors',
                    description: 'I am from Samsun. I completed my education, including university, in Samsun. I received my assistant training in Ankara, at the University of Health Sciences, Dışkapı Yıldırım Beyazıt Training and Research Hospital. After my assistantship, I completed my compulsory service at Ağrı State Hospital. I worked as an assistant professor at Istanbul Medipol University and Düzce University Faculty of Medicine. The most enjoyable periods of my professional life were fulfilling both the role of a physician and an instructor by training medical students and assistant physicians. In 2021, I received the title of associate professor and continue my academic career as a visiting faculty member at Istinye University Faculty of Medicine. Since April 2022, I have been fulfilling my duties as a physician in my clinic at Moment Beştepe Plaza.',
                },
                {
                    title: 'Causes of Photoaging:',
                    description: 'Collagen fibers and elastin fibers are components that provide the firmness, elasticity, and bright, vibrant appearance of our skin. UVB rays (more intense during noon hours) damage the skin’s surface, causing sunburn; however, UVA rays (more intense in the evening) make up ninety-five percent of sunlight. This wavelength penetrates deeper into the skin, damaging collagen and causing abnormal elastin production. This leads to a more aged appearance, or photoaging. Additionally, our skin produces melanin (brown pigment) in response to UV exposure to protect itself, resulting in sun spots, freckles, and age spots, which cause uneven skin tone.',
                },
                {
                    title: 'Signs of Photoaging',
                    description: '<ul><li>Wrinkles</li><li>Sagging skin (loss of elasticity)</li><li>Dark spots (sun spots)</li><li>Prominent blood vessels</li><li>Uneven skin tone</li><li>Very dry and rough skin</li><li>Sensitive skin</li></ul><strong>To prevent photoaging, it is crucial to use sunscreen regularly and undergo treatments (mesotherapy, Scarlet X) that can reduce sun damage.</strong>',
                },
                {
                    title: 'How Can I Protect Myself from Sun Rays?',
                    description: '<ul><li>Use sunscreen with at least SPF 30. Using sunscreen with SPF 50 or higher does not provide additional benefits.</li><li>Sunscreens containing zinc oxide and/or titanium dioxide should be preferred. If your sunscreen contains iron oxide, it will also provide effective protection against blue light, as it is important for sunscreens to have protection factors against both UVB and UVA, as well as blue light.</li><li>As dermatologists, we advise, “Do not go out in the sun or swim between 10:00 and 16:00,” because UVB rays are more intense during these hours, causing sunburns and long-term skin cancers. Every severe sunburn you experience damages the DNA structure of your skin and unfortunately lays the groundwork for skin cancer in later years. After 16:00, UVB rays decrease, and UVA rays become more intense. UVA rays are less likely to cause sunburns and skin cancer but have a much higher risk of causing photoaging. Therefore, we recommend applying your sunscreen as soon as you wake up and continuing to use it until sunset. Although the sun’s burning effect decreases towards the evening, its aging effect increases.</li><li>You need to reapply your sunscreen every four hours during the day. Using sunscreen is essential to protect yourself from skin cancer and photoaging. To minimize the photoaging process, improve skin quality, achieve healthier skin, and reduce fine lines and sagging, you can consider Scarlet X and/or mesotherapy treatments during summer and winter periods.</li></ul>',
                },
                {
                    title: 'Can the Photoaging Process Be Reversed?',
                },
                {
                    title: 'Mesotherapy',
                    description: 'The main goal of facial mesotherapy is biorejuvenation (stimulating the skin) and mesolifting (tightening the skin).',
                },
                {
                    description: 'It is a treatment method that involves injecting substances needed by the skin, such as vitamins, minerals, amino acids, coenzymes, antioxidants, and hyaluronic acid, into the lower layers of the skin. The ingredients used in mesotherapy vary depending on the treatment purpose. Since sun damage is more prominent during summer, mesotherapies with high antioxidant effectiveness should be preferred. Sunspots are a major concern for many people during summer; to reduce this issue, mesotherapy products containing compounds that prevent the formation of spots or stop existing spots from spreading within the skin should be used.',
                },
                {
                    title: 'Mesotherapy Sessions',
                    description: 'We plan mesotherapy as three to four sessions with intervals of two to three weeks to reduce sun damage during the summer.',
                },
                {
                    title: 'Sun Exposure After Mesotherapy',
                    description: 'We advise avoiding intense sun exposure for two days; you can continue your routine life by using sunscreen. To minimize the damage caused by the sun to your skin and prevent the irreversible process underneath, you can consider mesotherapy options that will protect your skin by reducing sun damage and spots during the summer.',
                },
                {
                    title: 'Scarlet X',
                    description: 'In recent years, Scarlet X technology has been used for skin renewal and anti-aging purposes. Scarlet X is a state-of-the-art radiofrequency application developed in Silicon Valley, USA. It is a clinically proven, FDA-approved device that treats the entire skin, removes fine lines and wrinkles, treats skin spots and redness, brightens skin tone, tightens pores, heals acne scars, and treats stretch marks (striae) safely and effectively. Sun rays cause photoaging by reducing collagen synthesis and disrupting the structure of elastic fibers. We cannot stop the aging process, but we can slow it down. We can achieve this slowing by improving skin quality. The best option to improve skin quality and reduce photoaging is Scarlet X.',
                },
                {
                    title: 'Pain in Scarlet X Treatment',
                    description: 'Personalized tips are used in the application. These tips contain micro-needles that send radiofrequency waves to the skin through these micro-needles. The pain threshold varies from person to person. Generally, minimal pain is felt. To minimize the possibility of pain, a topical anesthetic cream is applied to the treatment area about thirty minutes before the procedure. The procedure takes about forty-five minutes.',
                },
                {
                    title: 'Recovery Process in Scarlet X Treatment',
                    description: 'After the application, a mild redness that subsides within one or two hours occurs. The patient can immediately return to social life. As microchannels form in the skin after the procedure, it is not recommended to bathe or swim for twenty-four hours. Since there is no damage to the upper layer of the skin, Scarlet X applications can be done in both summer and winter periods. It is sufficient to use sunscreen and moisturize afterward.',
                },
                {
                    title: 'Safety in Scarlet X Treatment',
                    description: 'The Scarlet X application is an extremely safe system. No bruising, bleeding, or needle marks occur after the application. The procedure can be performed in any season.',
                },
                {
                    title: 'Number of Scarlet X Sessions and Results',
                    description: 'For rejuvenation treatments, three sessions one month apart will be sufficient.',
                },
                {
                    description: 'Ultraviolet rays are one of the leading factors that accelerate skin aging. Using sunscreen is essential for protecting against skin cancers and photoaging. To minimize the photoaging process, improve skin quality, and reduce collagen and elastin loss in our skin, Scarlet X and mesotherapy are the best approaches.',
                },
            ]
        },
    },
    {
        mediaid: 'look-good-feel-good',
        title: "Assoc. Prof. Dr. Ebru Karagün – Look Good, Feel Good!",
        image: "/img/media/doc-dr-ebru-karagun-iyi-gorun-iyi-hisset.jpg",
        medialink: 'https://ankaralifedergisi.com/iyi-gorun-iyi-hisset',
        mediaDesc: 'Look Good, Feel Good! Face rope suspension, almond eye, hair and face mesotherapy, glutathione, ozone application treatments, gold...',
        date: 'July 14, 2023',
        content: {
            blogPageTitle: "Assoc. Prof. Dr. Ebru Karagün – Look Good, Feel Good!",
            blogPageDesc: "Look Good, Feel Good! Known for her treatments such as facial thread lift, almond eye, hair and face mesotherapy, glutathione, ozone therapy, and non-surgical facelift, Assoc. Prof. Dr. Ebru Karagün was featured in Ankara Life Magazine, the capital's most read publication. She shared her knowledge about Endolift treatment for our valued readers. Enjoy the read. Ms. Ebru, we see that personal care studies have become quite popular lately, but before we get into the questions about your field, we want to know you better. Who is Ebru Karagün? ■ I am from Samsun. I completed my education, including university, in Samsun.",
            metaDescription: "Assoc. Prof. Dr. Ebru Karagün was featured in Ankara Life Magazine, the capital's most read publication. She shared her knowledge about Endolift treatment for our valued readers.",
            blogDetailPageInfo: [
                {
                    title: 'Look Good, Feel Good!',
                    description: 'Known for her treatments such as facial thread lift, almond eye, hair and face mesotherapy, glutathione, ozone therapy, and non-surgical facelift, Assoc. Prof. Dr. Ebru Karagün was featured in Ankara Life Magazine, the capital"s most read publication. She shared her knowledge about Endolift treatment for our valued readers. Enjoy the read.',
                },
                {
                    description: '<strong>Ms. Ebru, we see that personal care studies have become quite popular lately, but before we get into the questions about your field, we want to know you better. Who is Ebru Karagün?</strong>',
                },
                {
                    description: 'I am from Samsun. I completed my education, including university, in Samsun. I received my assistant training at Ankara Health Sciences University Dışkapı Yıldırım Beyazıt Training and Research Hospital. After my assistantship, I completed my compulsory service at Ağrı State Hospital.',
                },
                {
                    description: 'I worked as an Assistant Professor at Istanbul Medipol University and Düzce University Medical Faculties. In 2021, I earned the title of Associate Professor and continued my academic career as a visiting faculty member at İstinye University Faculty of Medicine. Since April 2022, I have been practicing medicine at my clinic located in Moment Beştepe Plaza.',
                },
                {
                    description: '<strong>With technological advancements, non-surgical rejuvenation methods have become quite popular. However, we believe it is healthier to hear detailed information about the topic from an expert. Ms. Ebru, what is a non-surgical facelift and how is it performed?</strong>',
                },
                {
                    description: 'The rapidly changing socio-cultural values of today have increased the desire to have a young and dynamic appearance in all age groups, both men and women, leading to an increase in aesthetic procedures. In addition, expectations in aesthetic applications are rapidly changing. As doctors and patients, we want applications to be done quickly, to have long-lasting effects, to be safe and modern, and to have minimal side effects that allow for a quick return to work and daily life. For this purpose, methods defined as “Non-Surgical Aesthetic Procedures” have been developed to alleviate the signs of aging and give the skin a younger, tighter appearance for those who are not yet ready for serious surgery. In recent years, the applicability, effectiveness, and variety of these procedures have increased. The requirement for general anesthesia, operating room conditions, hospital stays, rapid recovery, and quick results without these make these methods more popular. Non-surgical laser skin tightening, using advanced laser technology for a younger, tighter, and higher quality skin, is called Endolift Laser (Laser Facelift).',
                },
                {
                    title: 'What is Endolift, can you explain a bit?',
                    description: 'Endolift, a laser lifting application, is a technology that can reach under the skin and transmit energy with a 200-300 micron thickness (approximately the thickness of a hair) and a 1470 nm wavelength. Thanks to this advanced laser technology, skin tightening and firming are achieved. Endolift application can be performed in any season.',
                },
                {
                    title: 'What are the application areas of Endolift?',
                    description: 'It is used for non-surgical face lifting, neck lifting, reducing double chin, defining the jawline, treating under-eye wrinkles and bags, and addressing looseness and wrinkles on the body.',
                },
                {
                    title: 'What are the advantages of this application?',
                    description: 'The application is performed without anesthesia. It is a safe procedure with long-lasting effects. Only a single session is sufficient, it leaves no scars, and does not require surgical procedures. It can be easily applied in a clinical setting. No recovery period is needed, and its effects last for up to 2 years.',
                },
                {
                    title: 'As readers, what should we expect from the Endolift application?',
                    description: 'Endolift increases the elasticity and quality of the skin by scanning the subcutaneous tissue with laser, resulting in a tighter and firmer skin. It can reduce the appearance of horizontal lines in the neck area. It makes the jawline more defined. It can significantly correct deformation caused by loss of elasticity under the eyes.',
                },
                {
                    description: 'It melts localized excess fat in the middle and lower face and double chin areas, tightening the sagging skin. It is also possible to treat excess fat in the inner thighs and above the knees with Endolift Laser. Additionally, Endolift, a laser skin tightening application, is FDA approved.',
                },
                {
                    title: 'Does the application require any post-care?',
                    description: 'No special care is needed; you can return to your daily life immediately afterward. However, we recommend avoiding hot baths and saunas for 2-3 weeks and using sunscreen regularly.',
                },
                {
                    title: 'When are the results seen?',
                    description: 'The aesthetic effect is immediately observed after the Endolift application due to the melting of unwanted fat. Over the next 3-6 months, the lengths of the fibrous bands that provide skin elasticity shorten and new collagen fibers continue to form. The quality of the skin improves, you achieve a tighter and younger appearance, and the effectiveness typically lasts for 2 years.',
                }
            ]
        },
    },
    {
        mediaid: 'how-do-we-maintain-our-skin-quality',
        title: "Assoc. Prof. Dr. Ebru Karagün – How Do We Maintain Our Skin Quality?",
        image: "/img/media/cildimizi-nasil-koruruz-2-700x693.jpg",
        date: 'July 14, 2023',
        medialink: 'https://ankaralifedergisi.com/cildimizin-kalitesini-nasil-koruruz',
        mediaDesc: 'How do we maintain the quality of our skin? Your skin is your body"s window to reveal the stories of your life. From acne in adolescence, ...',
        content: {
            blogPageTitle: "Assoc. Prof. Dr. Ebru Karagün – How Do We Maintain Our Skin Quality?",
            blogPageDesc: "How Do We Maintain Our Skin Quality? Your skin is the window to your body that reveals your life stories. From teenage acne to the radiant glow of pregnancy and age-related sun spots, both your age and health are reflected in your skin. Many factors affect your skin. Genetics, chronological aging, hormones, and conditions like diabetes are internal factors that affect the skin. Unprotected sun exposure, unhealthy diet, stress, lack of sleep, insufficient exercise, low water consumption, smoking, and alcohol age the skin as external factors. 1. Eat healthily What you eat is as important as the products you apply to your skin. Your diet can improve your skin health from the inside out, so healthy skin starts with a healthy...",
            metaDescription: "Assoc. Prof. Dr. Ebru Karagün was featured in Ankara Life Magazine, the capital's most read publication. She shared her knowledge about skin protection for our valued readers.",
            blogDetailPageInfo: [
                {
                    title: 'How Do We Maintain Our Skin Quality?',
                    description: 'Your skin is the window to your body that reveals your life stories. From teenage acne to the radiant glow of pregnancy and age-related sun spots, both your age and health are reflected in your skin. Many factors affect your skin. Genetics, chronological aging, hormones, and conditions like diabetes are internal factors that affect the skin. Unprotected sun exposure, unhealthy diet, stress, lack of sleep, insufficient exercise, low water consumption, smoking, and alcohol age the skin as external factors.',
                },
                {
                    title: '1. Eat healthily',
                    description: 'What you eat is as important as the products you apply to your skin. Your diet can improve your skin health from the inside out, so healthy skin starts with a healthy diet. You need a diet high in antioxidants and free from trans fats and refined sugars. The higher the sugar content in your diet, the more you disrupt the structure of collagen in your skin.',
                },
                {
                    title: '2. Keep stress under control',
                    description: 'Have you ever noticed a pimple appearing on your face right before an important event? Studies have found a relationship between stress levels and skin problems, which accelerates the skin aging process.',
                },
                {
                    title: '3. Moisturize your skin',
                },
                {
                    title: '4. Use Sunscreen',
                    description: 'The most important thing you can do is use sunscreen regularly. In the aging process of our skin, there are factors that we can control and factors that we cannot. These are genetics and the chronological progression of your age. To maintain your skin quality, have a more radiant, more luminous, less fine lines, no uneven skin tone, firmer, and more elastic skin, cosmetic applications are required.',
                },
                {
                    title: '5: Mineral Injection',
                    description: 'It is an application containing diluted calcium hydroxyapatite, also known as liquid facelift. Its stimulating effects on the skin continue for 9 months to 1 year. It provides a lifting effect by increasing the brightness, firmness, and reducing fine lines of the skin.',
                },
                {
                    title: '6: Youth Injections',
                    description: 'Containing high-concentration hyaluronic acid, minerals, vitamins, amino acids, and bio-stimulatory effects that can spread under the skin, these injections accelerate the renewal and repair process of the skin, provide moisture, and reduce fine lines.',
                },
                {
                    title: '7: Salmon DNA:',
                    description: 'A highly natural product obtained from the DNA of salmon fish, with high stimulating and repairing effects on the skin.',
                },
                {
                    title: '8: Device Treatments (Scarlet X)',
                    description: 'In recent years, the Scarlet X technology has been used for skin rejuvenation. Scarlet X is a top-segment radiofrequency application developed in Silicon Valley, USA. It is clinically proven for safety and efficacy with FDA approval for overall skin rejuvenation, reducing fine wrinkles, treating skin spots and redness, brightening skin tone, tightening pores, improving acne scars, and treating skin stretch marks (striae).',
                },
                {
                    description: 'We cannot stop aging, but we can reduce it. We can extend our five-year process to 15 years. I have explained what needs to be done to have a healthier, more radiant, fewer fine lines, firmer, and more elastic skin. Everyone is at the age they deserve (Coco Chanel), and life always starts from where we catch it.',
                }
            ]
        },
    }
];

export default media;    
const internationalPublications = [
    "Karagün Ebru, Ergin Can, Baysak Sevim, Erden Gönül, Aktaş Habibullah, Ekiz Özlem. The Role Of Serum Vitamin D Levels İn Vitiligo. Advances İn Dermatology And Allergology. 2016;33(4):300-2. doi: 10.5114/pdia.2016.59507",
    "Karagün Ebru, Eröz Recep, Gamsızkan Mehmet, Baysak Sevim, Eyüp Yavuz, Özcan Yunus. Novel Mutation İdentified İn The Ddb2 Gene İn Patients With Xeroderma Pigmentosum Group–E. International Journal Of Dermatology. 2020 ;59(8):989-996. doi: 10.1111/ijd.14957",
    "Karagün Ebru, Eröz Recep. Argyrophilic Nucleolar Organizing Regions İn Patients With Xeroderma Pigmentosum Group E. Experimental Dermatology. 2021;30(3):416-417. doi: 10.1111/exd.14238.",
    "Karagün Ebru, Yıldız Pınar, Cangür Şengül. Effects Of Climate And Air Pollution Factors On Outpatient Visits For Eczema: A Time Series Analysis. Archives Of Dermatological Research. 2021;313(1):49-55. doi: 10.1007/s00403-020-02115-9",
    "Karagün Ebru, Baysak Sevim. Levels Of Tnf-Α, Il-6, Il-17, Il-37 Cytokines İn Patients With Active Vitiligo. The Aging Male. 2020;23(5):1487-1492. doi: 10.1080/ 13685538.2020.1806814",
    "Karagün Ebru, Gamsızkan Mehmet, Büyücek Şeyma, Kantarcıoğlu Çoşkun Sinem. Cd123 İmmunoexpression İn Cutaneous Lupus Erythematosus, Polymorphous Light Eruption, Pityriasis Rosea, And Mycosis Fungoides. Advances İn Dermatology And Allergology/Postępy Dermatologii İ Alergologii. 2021;38(6):994-1000. doi: 10.5114/ada.2020.100336",
    "Akçam Hanife Tuba,Karagün Ebru,İritaş İlter,Eyüp Yavuz. Keratoconus Could Be Associated With Psoriasis: Novel Findings From A Comparative Study. Cornea. 2019;38(12):1524-1530. doi: 10.1097/ ICO.0000000000002161",
    "Sukgen Gökmen, Ellibeş Kaya Aşkı, Karagün Ebru, Çalışkan Eray. Platelet-Rich Plasma Administration To The Lower Anterior Vaginal Wall To İmprove Female Sexuality Satisfaction. Journal Of Turkish Society Of Obstetric And Gynecology. 2019;16(4):228-234. doi: 10.4274/ tjod.galenos.2019.23356",
    "Baysak Sevim, Kılıç Fatma Arzu, Karagün Ebru, Baysak Erkan. Relationship Of Alexithymia, Rumination And Coping Style With Psoriasis And Their Effects On The Clinical Features. Turkish Journal Of Psychiatry. 2020 ;31(4):252-258. doi: 10.5080/u23743.",
    "Gündoğdu Mustafa, Erden Nihan, Karagün Ebru, Acıpayam Ayşe Şermin Filiz, Vural Seçil. Annual Pattern And Clinical Characteristics Of Herpes Zoster İn İmmunocompetent Children İn A Rural Area. Dermatologic Therapy. 2021;34(1):e14570. doi: 10.1111/dth.14570",
    "Karagün Ebru. Aquagenic Acrokeratoderma Due To Frequent Handwashing During The Covıd‐19 Pandemic Outbreak. Dermatologic Therapy. 2021 ;34(2):e14796. doi: 10.1111/dth.14796",
    "Karagün Ebru. The Effect Of Isotretinoin-Induced Myalgia On Daily Quality Of Life And Evaluation Of Serum Creatine Phosphokinase Levels. Van Medical Journal. 2019; 26(4): 452-455 doi: 10.5505/vtd.2019.26918",
    "Karagün Ebru, Baysak Sevim. Demographic Findings Of Patients Diagnosed With Pernio And Comparison Of Their Vitamin B12, Folate And Ferritin Levels With A Control Group. Türkderm. 2019; 53(3): 88-92 doi: 10.4274/ turkderm.galenos.2018.27576",
    "Karagün Ebru. Assesment Of Acne Vulgaris Patients’ Attention Tests During The İsotretinoin Treatment. Türkderm. 2020;54:1-4 doi: 10.4274/ turkderm.galenos.2019.92332",
    "Karagün Ebru. Çocukluk Çağı Herpes Zoster Enfeksiyonu: Retrospektif Çalışma. Turkish Journal Of Dermatology. 2019: 13 (1);20-24. DOI:10.4274/tdd.galenos.",
    "Karagün Ebru. İmmunsupresif Hastaların Ağız Bakım Alışkanlıklarının Değerlendirilmesi Ve Oral Enfeksiyonlar Arasındaki İlişkinin İncelenmesi. Journal Of Biotechnology And Strategic Health Research. 2018; 2(3):189 – 195",
    "Canpolat Filiz, Erdoğmuş İnce Nilüfer, Karagün Ebru, Kandi Başak, Öztekin M. Fevzi. İzotretinoin Tedavisinin Duysal Sinirler Üzerine Etkileri: Elektromyografik Bulgular. Türkderm. 2012; 46: 210-212. doi: 10.4274/turkderm.33600",
    "Karagün Ebru. An Investigation Of The Relationship Between Involvement Sites İn Vitiligo Patients And Autoimmune Diseases And Hematological Parameters. International Journal Of Innovative Research İn Medical Science. 2020;14(3):76-82. doi:10.4274/ jtad.galenos. 2020. 07379",
    "Karagün Ebru. Psoriazis Hastalarında Sistemik Tedavinin Hematolojik Parametreler Üzerine Etkisi. Konuralp Medical Journal. 2019;11(1): 95-100",
    "Karagün Ebru. An Investigation Into The Frequency Of Use Of Traditional And Complementary Medicine İn Patients Presenting To The Dermatology Clinic: A Survey Study. Journal Of The Turkish Academy Of Dermatology. 2020;14(3):76-82. doi: 10.4274/ jtad.galenos.2020.07379",
    "Karagün Ebru, Eşbah Onur. Dermatological Side-Effects Developing İn Patients Using Targeted Chemotherapy Drugs: A Review Of The Literature. Eurasian Journal Of Medical Investigation. EJMI 2020;4(2):173–180. doi: 10.14744/ejmi.2020.44067",
    "Karagün Ebru, Güleç Balbay Ege. Prevalence Of Obstructive Sleep Apnea Syndrome İn Psoriasis Patients. Duzce Medical Journal. 2019;1(2): 127 – 131. doi.org/10.18678/dtfd.560477",
    "Büyücek Şeyma, Gamsızkan Mehmet, Kantarcıoğlu Coşkun Sinem, Karagün Ebru, Gamsızkan Zerrin, Naldemir Aslı, Önal Binnur. National Review Of Melanomas İn Turkey And Comparison With Clinicopathological Features Of Melanomas Diagnosed At A Northwestern Academic Tertiary Center. Sn Comprehensive Clinical Medicine. volume 3, pages104–116 (2021)",
    "Sevim Baysak, Ebru Karagün, Havva Hilal Ayvaz . The Association Between Low Serum Vitamin-B12 Levels And Hyperpigmentation İn Patients Who Use İsotretinonin. 2021; 1(1): 19-24",
    "Yunus Ozcan Ebru Karagün Merve Alpay. Interplay Between Vitiligo And Oxidative Stress: Evaluation Of The Mechanisms Of Autoımmune Diseases. Doi.Org/10.18521/Ktd.880577"
]

export default internationalPublications;

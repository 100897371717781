const TRANSLATION_TR = {
    headerTwo: {
        homePage: 'Anasayfa',
        resume: 'Öz Geçmiş',
        applications: 'Uygulamalar',
        blog: 'Blog',
        weInTheMedia: 'Medyada Biz',
        contact: 'İletişim',
        contactUs: 'Bize Ulaşın',
        medicalDermatology: 'Medikal Dermatoloji',
        cosmeticDermatology: 'Kozmetik Dermatoloji',
        fillingApplications: 'Dolgu Uygulamaları',
        faceLiftingApplications: 'Yüz Askılama Uygulamaları',
        mesotherapyApplications: 'Mezoterapi Uygulamaları',
        deviceOperations: 'Cihaz İşlemleri',
        healthyLiving: 'Sağlıklı Yaşam',
        appointment: 'Randevu Al'
    },
    mainPage: {
        drTitle: 'Doç. Dr. Ebru Karagün',
        dermatologySpecialist: 'Dermatoloji Uzmanı',
        mainHeader: 'Dermatoloji & Kozmetik',
        mesotherapy: 'Mezoterapi',
        fillingApplications: 'Dolgu Uygulamaları',
        faceLifting: 'Yüz Askılama',
        healthyLiving: 'Sağlıklı Yaşam',
        deviceOperations: 'Cihaz İşlemleri',
        medicalTreatments: 'Medikal Tedaviler',
        mesotherapyDesc: 'Mezoterapi ürünlerindeki antioksidan maddeler yaşlanmayı tersine çevirir.',
        fillingApplicationsDesc: 'Dolgu uygulamaları, kozmetik uygulamalar arasında 2.sırada olan uygulamalardır.',
        faceLiftingDesc: 'İnsan dokusuna uyumlu özel üretilmiş ipliklerin ince bir iğne yardımıyla cilt altına yerleştirilmesine dayanan bir işlemdir.',
        healthyLivingDesc: 'Birçok insan için sağlıklı yaşam tanımı farklı olsa da genel anlamda bedenimize ve ruhumuza iyi gelen, fayda sağlayan alışkanlıklara sahip olma durumu olarak tanımlanabilir.',
        deviceOperationsDesc: 'Cildin yenilenmesi, gençleştirilmesi amacıyla son yıllarda cihaz işlemleri giderek yaygın ve güvenli hale gelmiştir.',
        medicalTreatmentsDesc: 'Medikal tedavi, hastalıkların veya rahatsızlıkların teşhis ve tedavisinde kullanılan tıbbi yöntemler ve ilaçlarla yapılan müdahalelerdir.',
        welcome: 'Hoş Geldiniz',
        aboutDesc1: 'Güzel görünmek, bakımlı ve çekici olmak arzusu Mısır Kraliçesi Kleopatra dönemine kadar uzanan insanlık tarihi kadar eski sosyal bir davranıştır.',
        aboutDesc2: 'Kozmetik, Yunanca “süslemekte usta” anlamına gelen kos-metikos sözcüğünden türetilmiştir.',
        aboutDesc3: 'Yaşlanmakla uzlaşmak istemeyen Coco Chanel’ın da dediği gibi',
        aboutDesc4: 'Bir kadın hak ettiği yaştadır.',
        aboutDesc5: '“Look good……Feel Better” (iyi görün…iyi hisset)',
        aboutDesc6: 'vurgusuyla kliniğimizde tüm dermo-kozmetik işlemler ve sağlıklı yaşama dair tamamlayıcı tedavilerle Moment Beştepe Plazada hizmet vermekteyiz.',
        readMore: 'Daha Fazlası',
        readMoreBlog: 'Devamını Oku',
        aboutMe: 'Hakkımda',
        missionDesc: 'Ondokuz Mayıs Üniversitesinden mezun olduktan sonra uzmanlık eğitimimi Ankara Dışkapı Yıldırım Beyazıt Eğitim ve Araştırma Hastanesi Deri ve Zührevi Hastalıkları Kliniğinde tamamladım. Mecburi hizmetimi Ağrı Devlet Hastanesinde tamamladıktan sonra İstanbul Medipol Üniversitesi Tıp Fakültesi ve Düzce Üniversitesi Tıp Fakültesinde Yrd.Doç.Dr unvanı ile görev yaptım ve 2021 yılında Doçent Doktor unvanını aldım. 2021 yılından itibaren çalıştığım Ankara Liv Hospital hastanesinden ayrılarak, 2022 Nisan ayından itibaren kendi kliniğimde devam etmekteyim.',
        missionSubDesc1: 'Yurt içi ve yurt dışı dergilerde yayınlanmış 50’nin üzerinde makalem bulunmaktadır.',
        missionSubDesc2: 'Özel ilgi alanlarım; tüm dermatolojik hastalıkların tanı ve tedavisi, dermokozmetik işlemler ve dermatoskopi yer almaktadır.',
        blogTitle: 'Sizin İçin Hazırladıklarımız',
        blog1Title: 'Akne - Sivilce İzlerinin Tedavisi',
        blog2Title: 'Cildiniz İçin En İyi 7 Antioksidan',
        blog3Title: 'Güneş Yanığı',
        blog4Title: 'Kollajen Nedir?',
        blog5Title: 'Saçlar Neden Beyazlar?',
        blog6Title: 'Erkek Tipi Saç Dökülmesi',
        blog1Date: '25 Kasım 2022',
        blog2Date: '3 Mart 2023',
        blog3Date: '14 Temmuz 2023',
        blog4Date: '28 Mart 2023',
        appoinmentSubHeader: 'Sağlıklı ve güzel hissetmek için ihtiyacınız olan her şey',
        appoinmentHeader: 'Kozmetik Uygulamalarda İlk Görüşme Ücretsizdir.',
        formName: 'Adınız',
        formMail: 'Eposta',
        formSubject: 'Konu',
        formPhone: 'Telefon Numarası',
        formMessage: 'İletiniz (Tercihe Bağlı)',
        formSend: 'Gönder',
        mediaTitle: 'Sizleri Bilgilendirmek İçin',
        media1Title: 'Doç. Dr. Ebru Karagün - Cildimizin Kalitesini Nasıl Koruruz?',
        media1Desc: 'Cildimizin Kalitesini Nasıl Koruruz? Cildiniz, hayatınızın hikayelerini ortaya çıkaran vücudunuzun pence- residir. Ergenlik yıllarındaki sivilcelerden, ...',
        media1Date: '14 Temmuz 2023',
        media2Title: 'Doç. Dr. Ebru Karagün - İyi Görün, İyi Hisset!',
        media2Desc: 'İyi Görün, İyi Hisset! Yüz ip askılama, badem göz, saç ve yüz mezoterapisi, glutat- yon, ozon uygulaması tedavileri, altın...',
        media2Date: '14 Temmuz 2023',
        media3Title: 'Doç. Dr. Ebru Karagün - Fotoyaşlanma',
        media3Desc: 'Doç. Dr. Ebru Karagün Fotoyaşlanma Cildin yaşlanmasına etki eden faktörleri ele alarak fotoyaşlanmanın sebeplerini ve sonuçlarını açıklayan Doç. Dr. Ebru...',
        media3Date: '28 Mart 2023',
        media4Title: 'Doç. Dr. Ebru Karagün - Cilt Çatlakları',
        media4Desc: 'Doç. Dr. Ebru Karagün Cilt Çatlakları Kendi kliniğinde hastalarını kabul eden Dermatoloji Doç. Dr. Ebru Karagün, cilt çatlaklarının oluşumunu ele...',
        media4Date: '28 Mart 2023',
        kvkkPdf: 'KVKK Metni',
        developedByMobiversite: 'Mobiversite Yazılım Bilişim Reklam ve Danışmanlık Hizmetleri Sanayi Tic. Ltd. Şti. tarafından hazırlanmıştır',
    },
    footerTwo: {
        question: 'Bir sorunuz mu var?',
        socialMedia: 'Sosyal Medya',
        ourServices: 'Hizmetlerimiz',
        lastUpdatedDateText: 'Son Güncelleme Tarihi',
        lastUpdatedDate: '25.05.2024',
        kvkk: 'Bu site kişileri bilgilendirmek amacıyla hazırlanmış olup, sağlık hizmeti vermemektedir. Sitedeki bilgiler hiçbir şekilde hastaların tanı ve tedavisinde kullanılmamalıdır. Site içeriğinin tanı ve tedavi amacıyla kullanımından doğacak tüm sorumluluk ziyaretçiye aittir. Sitedeki her bilgiyi ya da sağlık sorunlarını ziyaretçiler mutlaka doktora danışmalıdırlar. Bu siteyi ziyaret edenler bu uyarıları kabul etmiş sayılır. Bu site reklam, tanı ve tedaviye yönlendirme amacı taşımamaktadır sadece ziyaretçileri bilgilendirmek amaçlıdır.'
    },
    resumePage: {
        docDr: 'Doç. Dr.',
        languages: 'Diller',
        introducingMyself: 'Kendimi Tanıtıyorum',
        resumeDesc: 'Ondokuz Mayıs Üniversitesinden mezun olduktan sonra uzmanlık eğitimimi Ankara Dışkapı Yıldırım Beyazıt Eğitim ve Araştırma Hastanesi Deri ve Zührevi Hastalıkları Kliniğinde tamamladım. Mecburi hizmetimi Ağrı Devlet Hastanesinde tamamladıktan sonra İstanbul Medipol Üniversitesi Tıp Fakültesi ve Düzce Üniversitesi Tıp Fakültesinde Yrd.Doç.Dr unvanı ile görev yaptım ve 2021 yılında Doçent Doktor unvanını aldım. 2021 yılından itibaren çalıştığım Ankara Liv Hospital hastanesinden ayrılarak, 2022 Nisan ayından itibaren kendi kliniğimde devam etmekteyim. Aynı zamanda İstinye Üniversitesi Tıp Fakültesi Deri ve Zührevi Hastalıkları kliniğinde misafir öğretim üyesi olarak görev yapmaktayım. Özel ilgi alanlarım arasında dermokozmetik uygulamaları (mezoterapi, dolgu, yüz askılama yöntemleri), ozon tedavisi, akne, vitiligo, deri kanserleri, dermoskopi yer almaktadır. Yurt içi ve yurt dışı dergilerde yayınlanmış 50’nin üzerinde makalem bulunmaktadır.',
        myExperience: 'Deneyimlerim',
        experience1Title: 'Doçent Doktor Ünvanı',
        experience1Desc: 'Ünvan Alınış Tarihi: 2021',
        experience2Title: 'Yardımcı Doçent Doktor Ünvanı',
        experience2Desc: 'İstanbul Medipol Üniversitesi Tıp Fakültesi',
        experience3Title: 'Yardımcı Doçent Doktor Ünvanı',
        experience3Desc: 'Düzce Üniversitesi Tıp Fakültesi',
        experience4Title: 'Mecburi Hizmet',
        experience4Desc: 'Ağrı Devlet Hastanesi',
        experience5Title: 'Uzmanlık Eğitimi',
        experience5Desc: 'Ankara Dışkapı Yıldırım Beyazıt Eğitim ve Araştırma Hastanesi',
        experience6Title: 'Tıp Fakültesi Lisans Derecesi',
        experience6Desc: 'Ondokuz Mayıs Üniversitesi',
        internationalPublications: 'Uluslararası Yayınlar',
        nationalPublications: 'Ulusal Yayınlar',
        postersAccepted: 'Uluslararası ve Ulusal Kongrelerde Kabul Edilmiş Posterler - Sözlü Sunumlar'
    },
    applicationPage: {
        header: 'Uygulamalar'
    },
    appDetailPage: {
        popularApplications: 'Popüler Uygulamalar',
        popularApp1Title: 'Dolgu Uygulaması',
        popularApp1Desc: 'Günümüzde artık yüz estetiği ile ilgili cerrahi olmayan işlemler çok daha fazla tercih edilmektedir.',
        popularApp2Title: 'Scarlet X',
        popularApp2Desc: 'Özel tasarlanmış mikro iğneler ile cilt altını radyo frekans dalgalarıyla uyarır ve ciltte rejuvenasyon (yenilenme) sürecini başlatır.',
        popularApp3Title: 'Endolift',
        popularApp3Desc: 'It is a combined laser application that offers skin renewal and facial contouring together.',
        detail: 'Detay',
    },
    blogsPage: {
        subHeader: 'Blog Yazıları',
        header: 'Son Yazılar',
    },
    mediaDetailsPage: {
        newsSources: 'Haber Kaynakları',
        clickForPdf: 'Pdf için tıklayın...',
        clickForNew: 'Haberi görmek için tıklayın...',
        mostRead: 'En Çok Okunanlar',
        blog1Title: 'Güneş Yanığı',
        blog1Desc: 'Güneş yanığı, dokunulduğunda sıcak hissedilen ağrılı bir cilttir...',
        blog2Title: 'Cildiniz İçin En İyi 7 Antidoksan',
        blog2Desc: 'Antioksidanlar, hücrelerin yaşlanmasına neden olan oksidatif stresi tersine çevirerek veya önleyerek çalışırlar...',
        blog3Title: 'Erkek Tipi Saç Dökülmesi',
        blog3Desc: 'Erkek Tipi Saç Dökülmesi: Genetik olarak yatkın kişilerde hormonların etkisiyle kıl follikülünün incelmesidir...',
    },
    contactPage: {
        email: 'Eposta',
        address: 'Adres',
        phone: 'Telefon',
        formSubHeader: 'Aklınıza Takılan Her Şey İçin',
        formHeader: 'Bizimle İletişime Geçin',
        swalTitle: 'Bizimle İletişime Geçtiğiniz İçin Teşekkür Ederiz.',
        swalText: 'Ekibimiz Sizlere En Kısa Sürede Geri Dönüş Yapacaktır.'
    },
    appointmentPage: {
        weekendsErrorTitle: 'Hafta sonlarına randevu alınamaz!',
        weekendsErrorDesc: 'Lütfen hafta içi bir tarih seçin.',
        emailError: 'Eposta Adresi Boş Geçilemez!',
        nameError: 'Ad Soyad Boş Geçilemez!',
        phoneError: 'Telefon Numarası Boş Geçilemez!',
        successful: 'Başarılı!',
        appointmentRequest: 'Randevu Talebiniz Alınmıştır.',
        error: 'Hata!',
        appointmentError: 'Randevu Talebinizde Bir Sorun Oluştu. Lütfen Telefon İle İletişime Geçin!',
        tooManyRequest: '1 saat içerisindeki maksimum randevu talebi gönderme sınırını aştınız!',
        makeAnAppointment: 'Randevu Al',
        onlineAppointment: 'Online Randevu Sistemi',
        selectAppointmentTime: 'Randevu Saatini Seçiniz...',
        selectApplication: 'Uygulama Seçiniz...',
        medicalDermatology: 'Medikal Dermatoloji',
        cosmeticDermatology: 'Kozmetik Dermatoloji',
        wellness: 'Sağlıklı Yaşam',
        selectDate: 'Tarih Seçiniz',
        selectTime: 'Saat Seçiniz'
    },
    notFoundPage: {
        header: 'Sayfa Bulunamadı!',
        description: 'Oops! Aradığınız sayfa mevcut değil. Taşınmış veya silinmiş olabilir.',
        mainPage: 'ANASAYFA',
        weAreHereForYou: 'Sizin İçin Buradayız.'
    }

};

export default TRANSLATION_TR;


import React from 'react';
import Footer from '../../components/Shared/Footer';
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MediaDetailsArea from './MediaDetailsArea';
import media from '../../fakeData/media/media';
import enMedia from '../../fakeData/media/en-media';

const MediaDetails = () => {
   const { i18n } = useTranslation();
   const location = useLocation();
   const regex = /\/([^\/]+)\/?$/;
   const match = location.pathname.match(regex);
   let filteredData = '';

   if (match) {
      filteredData = i18n.language === 'en' ? enMedia.filter(data => data.mediaid === match[1]) : media.filter(data => data.mediaid === match[1])
   }

   return (
      <>
         <HomeHeader />
         <MediaDetailsArea postData={filteredData} />
         <Footer />
      </>
   );
};

export default MediaDetails;
const applications = [
    {
        applicationid: "dolgu-uygulamalari",
        name: "Dolgu Uygulamaları",
        description: "Dolgu uygulamaları, çeşitli dolgu maddeleri ile yapılır. Enjekte edilen malzeme, hacim oluşturarak çizgileri ve kırışıklıkları yok eder."
    },
    {
        applicationid: "saglikli-yasam",
        name: "Sağlıklı Yaşam",
        description: "Glutatyon C Vitamini, Alfa lipoik asit, Ozon tedavisi, Akapuntur, Sigara bırakma tedavileri ile hayatın olumsuz etkilerini geri çevirmeyi başarabilirsiniz."
    },
    {
        applicationid: "iple-olan-uygulamalar",
        name: "Yüz Askılama Uygualamaları",
        description: "İp askı lokal anestezi ile kulak bölgesinin deri altına yerleştirilerek yapılır. Bu ipler çekildiğinde yüz gerilir. Dikiş ve ağrı yoktur."
    },
    {
        applicationid: "mezoterapi-uygulamalari",
        name: "Mezoterapi Uygulamaları",
        description: "Mezoterapi uygulamaları, cilt yenileme, selülit, çatlaklar, lekeler, yara izleri ve saç tedavisi gibi çeşitli deri hastalıklarının tedavisinde kullanılır.",
    },
    {
        applicationid: "cihaz-islemleri",
        name: "Cihaz İşlemleri",
        description: "Dolgu Uygulaması, Mezoterapi, Scarlet X ve Saç Mezoterapisi gibi bir çok işlemler kliniğimizde yapılmaktadır.",
    },
    {
        applicationid: "medikal-uygulamalar",
        name: "Medikal Dermatoloji",
        description: "Alopes Areata (Saç Kıran), Psoriazis (Sedef Hastalığı), Keloid, Akne-Sivilce gibi birçok hastalığın tedavisi kliniğimizde yapılmaktadır.",
    }
];

export default applications;
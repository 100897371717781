import React from 'react';
import MediaDetailsLeftSide from '../../components/MediaDetailsLeftSide/MediaDetailsLeftSide';
import MediaDetailsRightSide from '../../components/MediaDetailsRightSide/MediaDetailsRightSide';
import { Helmet } from 'react-helmet-async';

const MediaDetailsArea = ({ postData }) => {
   return (
      <>
         <section className="blog-area pt-50 pb-80">
            <div className="header-info position-absolute d-md-none flex" style={{ top: 65 }}>
               <a href="tel:+903125574646"> <span><i className="fas fa-phone"></i> +90 312 557 46 46</span></a>
               <a href="tel:+905332691271"> <span><i className="fas fa-phone"></i> +90 533 269 12 71</span></a>
            </div>
            <Helmet>
               <title>{`${postData[0].title}`}</title>
               <meta name='description' content={postData[0].content.metaDescription} />
            </Helmet>
            <div className="container">
               <div className="row">

                  <MediaDetailsLeftSide postData={postData[0]} details_topBar={<div className="postbox__thumb mb-35">
                     <img src={postData[0]?.image} alt="blog img" />
                  </div>} />

                  <MediaDetailsRightSide pdflink={postData[0]?.pdflink} medialink={postData[0]?.medialink} />

               </div>
            </div>
         </section>
      </>
   );
};

export default MediaDetailsArea;
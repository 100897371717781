import React from 'react';

const HomeTwoSingleMission = ({icon,border, data}) => {

   return (
      <>
         <div className={border ? `mv-single-list d-flex ${border}` : "mv-single-list d-flex"} >
            <div className="mv-icon">
               <img src={`/img/icon/mv-icon-${icon}.png`} style={{ width: "33px", height: "33px", maxWidth: "33px" }} alt="#" />
            </div>
            <div className="mv-text">
               <p>{data}</p>
            </div>
         </div>
      </>
   );
};

export default HomeTwoSingleMission;
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const HomeTwoSingleService = ({icon,number,title, link, data, date}) => {
   const { t } = useTranslation();
    return (
        <>
          <div className="col-xl-10 col-lg-10 col-md-10">
             <div className="service-box service-box-2 mb-30 pos-rel">
                <div className="service-thumb">
                   <img src={`${icon}`} alt=""/>
                </div>
                <div className="service-content service-content-2">
                   <h6 className="green-color text-up-case letter-spacing mb-20">{date}</h6>
                   <h3 style={{ minHeight: "170px" }}><Link to={`${link}`}>{title}</Link></h3>
                   <p>{data}</p>
                   <Link className="service-link" to={`${link}`}><i className="fas fa-arrow-right"></i>{t('mainPage.readMore')}</Link>
                </div>
                <div className="service-number">
                   <h1 className="service-big-number">{`${number}`}</h1>
                </div>
             </div>
          </div>
        </>
    );
};

export default HomeTwoSingleService;
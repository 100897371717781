import React from 'react';
import Footer from '../../components/Shared/Footer';
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PostDetailsArea from './PostDetailsArea';
import blog from '../../fakeData/blogs/blogs';
import enBlog from '../../fakeData/blogs/en-blogs';
import { useNavigate } from 'react-router-dom';

const PostDetails = () => {
   const { i18n } = useTranslation();
   const navigate = useNavigate();
   const location = useLocation();
   const regex = /\/([^\/]+)\/?$/;
   const match = location.pathname.match(regex);
   let filteredData = '';

   if (match) {
      filteredData = i18n.language === 'en' ? enBlog.filter(data => data.blogid === match[1]) : blog.filter(data => data.blogid === match[1])

      if (filteredData.length === 0) {
         navigate('/blogs')
      }
   }

   return (
      <>
         <HomeHeader />
         <PostDetailsArea postData={filteredData} />
         <Footer />
      </>
   );
};

export default PostDetails;
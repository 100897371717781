import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ServicesTwoSingleItem = ({ image, title, description, id, applicationid }) => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="service-box-3 mb-30 text-center">
                    <div className="service-thumb" >
                        <Link to={`/${i18n.language === 'en' ? 'en/applications' : 'uygulamalar'}/${applicationid}/${id}`}>
                            <img src={image} alt="" style={{ height: "270px", width: "370px" }} />
                        </Link>
                    </div>
                    <div className="service-content-box">
                        <div className="service-content">
                            <h3 style={{ height: "55px" }}><Link to={`/${i18n.language === 'en' ? 'en/applications' : 'uygulamalar'}/${applicationid}/${id}`}>{title}</Link></h3>
                            <p>{`${description?.slice('0', '200')}...`}</p>
                        </div>
                        <Link to={`/${i18n.language === 'en' ? 'en/applications' : 'uygulamalar'}/${applicationid}/${id}`} className="service-link">{t('appDetailPage.detail')}</Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ServicesTwoSingleItem;
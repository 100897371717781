import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BlogFirstArticle = ({ mediaId, image, title, decription, date }) => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <article className="postbox post format-image mb-40">
                <div className="postbox__thumb" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Link to={`/${i18n.language === 'en' ? 'en/media' : 'medyada-biz'}/${mediaId}`}>
                        <div>
                            <img src={image} alt="blog img" style={{ width: "800px", height: "100%", objectFit: "cover" }} />
                        </div>
                    </Link>
                </div>
                <div className="postbox__text p-50">
                    <div className="post-meta mb-15">
                        <span><i className="far fa-calendar-check"></i> {date} </span>
                    </div>
                    <h3 className="blog-title">
                        <Link to={`/${i18n.language === 'en' ? 'en/media' : 'medyada-biz'}/${mediaId}`}>{title}</Link>
                    </h3>
                    <div className="post-text mb-20">
                        <p>{decription}</p>
                    </div>
                    <div className="read-more mt-30">
                        <Link to={`/${i18n.language === 'en' ? 'en/media' : 'medyada-biz'}/${mediaId}`} className="primary_btn theme-btn">{t('mainPage.readMoreBlog')}</Link>
                    </div>
                </div>
            </article>
        </>
    );
};

export default BlogFirstArticle;
import React from 'react';
import Footer from '../../components/Shared/Footer';
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader';
import ServicesTwoArea from '../Services/ServicesTwo/ServicesTwoArea/ServicesTwoArea';
import application from '../../fakeData/applications/application';
import enApplication from '../../fakeData/applications/en-application';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const HealthyLiving = () => {
    const { i18n } = useTranslation()
    const location = useLocation();
    const regex = /\/([^\/]+)\/?$/;
    const match = location.pathname.match(regex);
    let filteredData = '';

    if (match) {
        filteredData = i18n.language === 'en' ? enApplication.filter(data => data.applicationid === match[1]) : application.filter(data => data.applicationid === match[1])
    }

    return (
        <>
            <HomeHeader />
            <ServicesTwoArea data={filteredData} />
            <Footer />
        </>
    );
};

export default HealthyLiving;
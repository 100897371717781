const nationalPublications = [
    "Karagün Ebru, Kandi Başak. Boyun Ve Dekolte Bölgesi Gençleştirme. Turkiye Klinikleri Cosmetic Dermatology – Special Topics",
    "Tezel Nihal, Bal Hastürk Ajda, Karagün Ebru, Çakar Nilgün, Çakcı Fatma Aytül. Psöriatik Eritrodermiye Bağlı Rikets. Türkiye Klinikleri Journal Of Case Reports",
    "Karagün Ebru. Ksantom Bulgusuyla Tanı Konulan Üç Yaşında Familyal Homozigot Hiperkolesterolemi. Turkiye Klinikleri Journal Of Dermatology",
    "Karagün Ebru, Özcan Yunus. Erken Evre Lyme Hastalığı Tanısı Konulan İki Olgu Sunumu. Turkiye Klinikleri Journal Of Dermatology. 2019; 29: 36 – 39doi: doi.org:10.5336",
    "Karagün Ebru, Öneç Birgül, Gamsızkan Mehmet, Özcan Yunus, Bahcıvan Atike. A Case Of Paraneoplastıc Elastosıs Perforans Serpıgınosa Assocıated Wıth T-Cell Non-Hodgkın Lymphoma. Journal Of Medıcal Case Reports And Revıews",
    "Kurmuş Gökçe Işıl, Karagün Ebru, Baysak Sevim, Ergin Can, Yılmaz Engin Deniz, Gönül Müzeyyen. Romatoid Artritli Bir Hastada Görülen Nekrotizan Fasiit. Dermatoz. 2014/3/dermatoz14053o3.pdf doi: 10.15624.dermatoz14053o3",
    "Karagün Ebru. Akuajenik Siringeal Akrokeratoderma: 4 Ve 8 Yaşında Tanı Konulan İki Olgu. Dermatoz",
    "Karagün Ebru, Öneç Birgül, Akbaş Türkay, Gamsızkan Mehmet. Sjögren Sendromu Ve Şiddetli Cilt Tutulumu Mevcut Olan Katastrofik Antifosfolipid Sendromu. Cukurova Medical Journal. 2020; 45( 2): 746 – 751",
    "Çevirgen Cemil Bengü,Canpolat Filiz,Ergin Can,Karagün Ebru,Albayrak Aynur. Kronik Aktinik Dermatit. Turkish Journal Of Dermatology / Türk Dermatoloji Dergisi. 2017;11:86-8 doi: 10.4274/tdd.2055",
    "Karagün Ebru, Gamsızkan Mehmet, Eyüp Yavuz, Bahcıvan Atike, Özcan Yunus, Büyücek Şeyma. Atypically Located Basal Cell Carcinoma: Three Case Reports. Osmangazi Journal Of Medıcıne. 2020; 42(3):334 – 338. doi.org/10.20515/otd.612342"
]

export default nationalPublications;
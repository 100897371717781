import React from 'react';
import { Link } from 'react-router-dom';
import HomeTwoSingleAppoinment from '../../../components/HomeTwoSingleAppoinment/HomeTwoSingleAppoinment';
import { useTranslation } from 'react-i18next';

const HomeTwoAppoinmentArea = () => {
   const { t } = useTranslation();
   return (
      <>
         <section className="appoinment-area gray-bg pb-15">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12">
                     <div className="appoinment-box-2">
                        <div className="row no-gutters">
                           <div className="col-xl-8 col-lg-12">
                              <div className="appoinment-box-content">
                                 <div className="about-title mb-80">
                                    <h3 className="pink-color">{t('mainPage.appoinmentSubHeader')}</h3>
                                    <h1>{t('mainPage.appoinmentHeader')}</h1>
                                 </div>
                                 <div className="row">
                                    <HomeTwoSingleAppoinment icon="3" name={`${t('mainPage.formName')}`} />
                                    <HomeTwoSingleAppoinment icon="4" name={`${t('mainPage.formMail')}`} />
                                    <HomeTwoSingleAppoinment icon="1" name={`${t('mainPage.formSubject')}`} />
                                    <HomeTwoSingleAppoinment icon="5" name={`${t('mainPage.formPhone')}`} />
                                    <div className="col-xl-6 col-lg-12">
                                       <div className="appoint-button">
                                          <Link to="/contact" className="primary_btn green-bg-btn">{t('mainPage.formSend')}</Link>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-xl-4">
                              <div className="appoinment-right f-right">
                                 <img src="/img/appoinment/cosmetology-doctor-mask.jpg" alt=""/>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeTwoAppoinmentArea;
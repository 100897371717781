import React from 'react';
import HomeTwoSingleMission from '../../../components/HomeTwoSingleMission/HomeTwoSingleMission';
import { useTranslation } from 'react-i18next';

const HomeTwoMissionArea = () => {
   const { t } = useTranslation();
   return (
      <>
         <section className="about-area gray-bg pt-115 pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-10 col-md-12">
                     <div className="about-title mb-20">
                        <h3 className="pink-color">{t('mainPage.aboutMe')}</h3>
                        <h2 className='custom-main-header'>{t('mainPage.drTitle')}</h2>
                        <h3>{t('mainPage.dermatologySpecialist')}</h3>
                     </div>
                     <div className="about-text mission-about-text">
                     <p>{t('mainPage.missionDesc')}</p><br/>
                     </div>
                     <div className="mission-vision-list pr-90">
                        <HomeTwoSingleMission icon="1" data={`${t('mainPage.missionSubDesc1')}`} />
                        <HomeTwoSingleMission icon="1" border="border-0" data={`${t('mainPage.missionSubDesc2')}`} />
                     </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 d-lg-none d-xl-block">
                     <div className="mv-right-img pos-rel mb-30">
                        <div>
                           <img src="/img/media/doc-dr-ebru-karagun-fotoyaslanma.jpg" style={{ width: "540px", height: "100%"  }} alt=""/>
                        </div>
                     </div>
                     <div className="testi-quato-icon about-icon-white d-none d-xl-block">
                        <img src="img/about/about-icon-white.png" alt=""/>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeTwoMissionArea;
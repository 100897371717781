import React from 'react';

const HomeTwoCounter = () => {
    return (
        <>
            <section className="counter-wraper theme-bg pt-120 pb-175">
            </section>
        </>
    );
};

export default HomeTwoCounter;
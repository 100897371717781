const enApplications = [
    {
        applicationid: "filler-applications",
        name: "Filler Applications",
        description: "Filler applications are performed with various filler materials. The injected material eliminates lines and wrinkles by creating volume."
    },
    {
        applicationid: "healthy-living",
        name: "Healthy Living",
        description: "With Glutathione Vitamin C, Alpha lipoic acid, Ozone therapy, Acupuncture, Smoking cessation treatments, you can reverse the negative effects of life."
    },
    {
        applicationid: "thread-applications",
        name: "Thread Face Lifting Applications",
        description: "Thread lifting is done by placing threads under the skin around the ear area under local anesthesia. When these threads are pulled, the face is lifted. There are no stitches and no pain."
    },
    {
        applicationid: "mesotherapy-applications",
        name: "Mesotherapy Applications",
        description: "Mesotherapy applications are used in the treatment of various skin diseases such as skin rejuvenation, cellulite, stretch marks, spots, scars, and hair treatment."
    },
    {
        applicationid: "device-procedures",
        name: "Device Procedures",
        description: "Many procedures such as Filler Application, Mesotherapy, Scarlet X, and Hair Mesotherapy are performed in our clinic."
    },
    {
        applicationid: "medical-applications",
        name: "Medical Dermatology",
        description: "Treatment of many diseases such as Alopecia Areata, Psoriasis, Keloid, Acne-Pimples is performed in our clinic."
    }
];
export default enApplications;
import React from 'react';
import HomeTwoSingleService from '../../../components/HomeTwoSingleService/HomeTwoSingleService';
import Slider from "react-slick";
import { useTranslation } from 'react-i18next';
import blog from '../../../fakeData/blogs/blogs';
import enBlog from '../../../fakeData/blogs/en-blogs';

const HomeTwoServicesArea = () => {
   const { t, i18n } = useTranslation();

   const settings = {
      autoplay: true,
      autoplaySpeed: 5000,
      dots: true,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 2,
      responsive: [
         {
            breakpoint: 1200,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 2,
               infinite: true,
            }
         },
         {
            breakpoint: 991,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               arrows: false
            }
         },
      ]

   };

   return (
      <>
         <section className="servcies-area gray-bg pt-100 pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
                     <div className="section-title text-center pos-rel mb-75">
                        <div className="section-icon">
                           <img className="section-back-icon" src="/img/section/section-back-icon.png" alt="" />
                        </div>
                        <div className="section-text pos-rel">
                           <h2 className='sub-custom-header'>{t('headerTwo.blog')}</h2>
                           <h1>{t('mainPage.blogTitle')}</h1>
                        </div>
                        <div className="section-line pos-rel">
                           <img src="/img/shape/section-title-line.png" alt="" />
                        </div>
                     </div>
                  </div>
               </div>

               <div>
                  <Slider {...settings}>
                     {
                        i18n.language === 'en' ?
                           enBlog.map((e, i) => {
                              return <HomeTwoSingleService icon={`${e.image}`} link={`/en/blogs/${e.blogid}`} number={i + 1} title={`${e.title}`} date={`${e.date}`} />
                           }) :
                           blog.map((e, i) => {
                              return <HomeTwoSingleService icon={`${e.image}`} link={`/blogs/${e.blogid}`} number={i + 1} title={`${e.title}`} date={`${e.date}`} />
                           })
                     }

                  </Slider>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeTwoServicesArea;
const blog = [
    {
        blogid: 'rujlarin-yan-etkileri',
        title: "Rujların Yan Etkileri",
        image: "/img/blog-images/rujlarin-yan-etkileri.jpg",
        date: '16 Ekim 2023',
        content: {
            blogPageTitle: "Rujların Yan Etkileri",
            blogPageDesc: "Her kadın ideal dudak rengine sahip olmayı hayal eder. Kadınlar ruj renklerini kıyafetlerine, zevklerine, ruh hallerine ve stil duygusuna göre seçerler. Ruj sevenler için kötü haberlerimiz var...",
            metaDescription: "Ruj, sağlığınıza zarar verebilecek ve bir dizi sağlık sorununa neden olabilecek tehlikeli bileşenlere sahiptir. Rujların kurşun, nikel, alüminyum, arsenik, kadmiyum, antimon ve krom gibi ağır metaller içerebilir ve bu ağır metaller sağlığa zararlıdır.",
            blogDetailPageInfo: [
                {
                    title: 'Bu Makyaj Ürünü Size Nasıl Zarar Verebilir',
                    description: 'Her kadın ideal dudak rengine sahip olmayı hayal eder. Kadınlar ruj renklerini kıyafetlerine, zevklerine, ruh hallerine ve stil duygusuna göre seçerler. Ruj sevenler için kötü haberlerimiz var.  Ruj, sağlığınıza zarar verebilecek ve bir dizi sağlık sorununa neden olabilecek tehlikeli bileşenlere sahiptir. Rujların kurşun, nikel, alüminyum, arsenik, kadmiyum, antimon ve krom gibi ağır metaller içerdiği bilinmektedir.',
                },
                {
                    description: 'Yemeğimizi yerken veya herhangi bir şey içerken rujların vücudunuza girdiğini biliyor muydunuz? Belki ağır metallere sadece çok çok küçük miktarlarda maruz kalıyoruz ancak sürekli olarak maruz kalmak kronik sağlık riskinin gelişmesinde önemli hale gelmektedir. Kronik sağlık riski, düşük toksik doza sürekli maruz kalmanın ardından kademeli olarak oluşan geri dönüşü olmayan bir sonuçtur.',
                },
                {
                    title: '1. Vücutta Toksisiteye Neden Olurlar',
                    description: 'Şimdiye kadar rujlardaki toksik bileşenlerin sayısını biliyoruz, bu nedenle vücudunuzun bu toksisiteleri emmesi muhtemeldir. Aslında, farkında bile olmadan, kadınlar bazen bu toksik malzemeleri yutarlar. Ruj satın almadan önce daima içeriğine bakın, kurşun, nikel, alüminyum, arsenik, kadmiyum, antimon ve krom gibi ağır metaller içeren ürünleri tercih etmeyin.',
                },
                {
                    description: 'Kronik kurşun maruziyeti nörolojik, hamilelik döneminde bebek için riskli duruma neden olabilir.',
                },
                {
                    description: 'Kadmiyuma uzun süre maruz kalma ateroskleroz ve hipertansiyon gibi kardiyovasküler hastalıklarla yakından bağlantılıdır. Nadiren de olsa düzenli olarak özellikle koyu renk ruj kullanmanın sonucunda vücutta kadminyum birikimine bağlı olarak böbrek yetmezliği de meydana gelebilir.',
                },
                {
                    title: '2. Çeşitli Cilt Alerjilerinin Nedenidir',
                    description: 'Rujlar koruma için bizmut oksiklorür adı verilen bir kimyasala sahiptir. Bu bileşen hem dudak çevresinde alerjiye neden olur hem de kanserojendir, bu yüzden dikkatli olun!',
                },
                {
                    title: '3. Kansere Bile Neden Olabilirler',
                    description: 'Hayır, şaka yapmıyoruz! Bileşenlerin çoğu doğada kanserojen olduğundan bu bileşenlere sürekli maruz kalmak kanser riskini artırır. Mide tümörleri için risk faktörleri arasında kadminyum toksik bileşenine uzun süre maruz kalmak bir nedendir.',
                },
                {
                    title: 'Bu Etkileri En Aza İndirmek İçin Ne Yapabilirsiniz?',
                    description: 'Her sorunun bir çözümü vardır. Bazı daha güvenli alternatifler rujun zararlı etkilerini en aza indirebilir.',
                },
                {
                    title: 'Sık Ruj Kullanıcıları Tarafından Akılda Tutulması Gerekenler:',
                    description: 'Ağır metallerin rujların daha koyu tonlarında fazladır. Ruj kullanırken daha açık tonlar tercih edin.',
                },
                {
                    description: 'Kimyasalların yan etkilerinden kaçınmak için rujların içeriklerine dikkat edin.',
                },
                {
                    description: 'Ucuz olan bir ruj ne kadar çekici görünse de sizler için fiyatına değil içeriğine bakmanızı öneririz.',
                },
                {
                    description: 'Ruju sürmeden önce daima vazelin sürün. Dudaklarınıza bir koruma tabakası sağlayabilir ve hasarı en aza indirebilir.',
                },
                {
                    description: 'Hamilelik sırasında ruj kullanımınızı mümkün olduğu kadar kısıtlayın.',
                },
                {
                    description: 'Yatmadan önce rujunuzu iyice temizleyin.',
                },
                {
                    description: 'Organik ve doğal rujlar satın almaya özen gösterin.',
                },
                {
                    description: 'Haftada 2-3 defadan fazla ruj sürmeyin.',
                },
                {
                    title: 'Kaynaklar:',
                    description: 'doi.org/10.1016/j.yrtph.2015.07.005 <br />doi.org/10.1016/j.freeradbiomed.2013.08.167 <br /> doi:10.52711/2321-5844.2023.00004',
                },
            ]
        },
    },
    {
        blogid: 'gunes-yanigi',
        title: "Güneş Yanığı",
        image: "/img/blog-images/gunes-yanigi.jpg",
        date: '14 Temmuz 2023',
        content: {
            blogPageTitle: "Güneş Yanığı",
            blogPageDesc: "Güneş yanığı, dokunulduğunda sıcak hissedilen ağrılı bir cilttir. Kızarıklık güneş maruziyetini takiben 4-6 saat içinde belirgin olmaya başlar, 24 saatte...",
            metaDescription: "Güneş Yanığı neden oluşur- nasıl önlerim-eğer güneş yanığı oluşmuşsa ne yapmalıyım. Ayrıntılı bilgi için tılayın.",
            blogDetailPageInfo: [
                {
                    description: 'Güneş yanığı, dokunulduğunda sıcak hissedilen ağrılı bir cilttir. Kızarıklık güneş maruziyetini takiben 4-6 saat içinde belirgin olmaya başlar, 24 saatte en şiddetli haline ulaşır. Kızarıklık, ödem, içi su toplayan kabarıkcıklar oluşabilir. Hafif bir kızarıklıktan, 2. derece yanık gibi şiddetli tablolara varan bir yelpazede karşımıza çıkabilir. Vücudun açıkta kalan alanları- kulak memeleri, kafa derisi ve dudaklar dahil- güneş yanığı olabilir. Giysilerin eğer ultraviyole (UV) koruması yoksa kapalı vücut alanlarında da güneş yanığı gelişebilir.',
                },
                {
                    title: 'Güneş yanığı belirtileri:',
                    description: '- Güneş yanığı belirtileri genellikle güneşe maruz kaldıktan 4-6 saat sonra ortaya çıkar. <br /> - Beyaz tende pembe veya kırmızı görünürken, esmer tenlerde zor fark edilebilir. <br /> - Sıcak hissedilen cilt <br /> - Ağrı, hassasiyet ve kaşıntı mevcut olabilir <br /> - İçi su dolu kabarcıklar oluşabilir <br /> - Ağrılı veya kumlu (batıcı hissiyatlı) hissedilen gözler <br /> - Güneş yanığı şiddetliyse baş ağrısı, ateş, bulantı-kusma',
                },
                {
                    title: 'Ne zaman doktora gidilmeli',
                    description: '- Büyük su toplayan kabarcıklar varsa <br /> - Yüzde, ellerde veya genital bölgede su toplayan kabarcıklar gelişirse <br /> - Güneş yanığı olan alanlarda giderek artan ödem mevcut ise <br /> - İçi su toplayan kabarcıklar sarımsı renge dönen irinli görünümü olursa <br /> Vücuttaki ağrının artması, baş dönmesi, bilinç bulanıklığı, mide bulantısı, ateş gelişirse doktora başvurunuz.',
                },
                {
                    title: 'Güneş Yanığını Önleme:',
                    description: '<ul><li><span style="font-weight:400">Serin, bulutlu veya puslu günlerde bile güneş yanığını önlemek için güneş koruyucu kullanın. Güneş ışınlarını yansıttıkları için su, kar, beton ve kuma ekstra dikkatli olun.</span></li><li><span style="font-weight:400">Yüksek rakımlarda güneş yanığı riski daha fazladır.</span></li><li><span style="font-weight:400">10:00 ile 16:00 saatleri arasında güneşe maruz kalmaktan kaçının.</span></li><li><span style="font-weight:400">Güneş altına çıkmadan 15 ila 30 dakika önce sürülmelidir.</span></li><li><span style="font-weight:400">Bulutlu günlerde bile suya dayanıklı, geniş spektrumlu dudak balsamı ve SPF’si en az 30 olan güneş kremi kullanın. </span></li><li><span style="font-weight:400">Geniş spektrumlu ürünler ultraviyole A (UVA) ve ultraviyole B (UVB) ve mavi ışık spektrumuna karşı koruma sağlar. </span></li><li><span style="font-weight:400">Güneş koruyucu sizi tamamen güneşten korumaz, siz de mümkün olduğu kadar güneşten kaçınmalısınız. Hiçbir boşluk bırakmadan düzenli ve bol miktarda uygulanmalıdır.</span></li><li><span style="font-weight:400">Güneş altında durulacaksa her 2 ila 3 saatte bir tekrar sürülmelidir.</span></li><li><span style="font-weight:400">Suya, terlemeye dayanıklı olduğu belirtilen ürünler de yine yıkama, duş, terleme, yüzme sonunda</span><span style="font-weight:400">beklemeksizin uygulanmalıdır.</span></li><li><span style="font-weight:400">Toplumda yapılan en önemli yanlış uygulama günde bir kez ve çok az uygulamadır.</span></li></ul>',
                },
                {
                    title: 'Güneş Yanığına Ne İyi Gelir? Güneş Yanıkları Nasıl Geçer?',
                    description: '<ul><li><span style="font-weight:400">Ağrı kesici almak ve alanı soğutmak ilk yapılması gerekendir. Ancak güneş yanığının</span><span style="font-weight:400">geçmesi günler alabilir.</span></li><li><span style="font-weight:400">Yanık olan alanları mümkün olduğunca serin tutun. Soğuk su ile ıslatılmış bezler ile kompres yapmak veya serin su ile duş alabilirsiniz.</span></li><li><span style="font-weight:400">Bol su için, günde 3-4 litre.</span></li><li><span style="font-weight:400">Enfeksiyon riski olduğu için içi su toplayan bülleri patlatmayın sönmesini bekleyin. Çok büyük olursa dermatoloji doktoruna başvurun, doktorunuz kontrollü bir şekilde boşaltacaktır.</span></li><li><span style="font-weight:400">Antiinflamatuar etkili ağrı kesiciler alabilirsiniz 3-5 gün (Mide problemi olanlar dikkatli kullanmalıdır)</span></li><li><span style="font-weight:400">Yanık olan bölgelerin tekrar güneşe maruz kalmasını önleyin.</span></li><li><span style="font-weight:400">Halk arasında, güneş yanığı sonucu oluşan yanma ve ağrı hissinin giderilmesi için yoğurt veya diş macunu sürmekte olan halen devam eden yanlış uygulamalardır. Soğuk oldukları için acıyı hafifletir, ancak besiyeri görevi görerek enfeksiyona neden -olabilir.</span></li><li><span style="font-weight:400">Güneş yanığı olan alanları nemlendirin. Özellikle aleo vera içeren nemlendiricilerin etkinliği çok daha iyidir.</span></li><li><span style="font-weight:400">Bu uygulamalar sizlerin evde yapabileceğiniz uygulamalardır, eğer rahatlama hissiyatınız yoksa dermatoloji doktoruna başvurunuz</span></li></ul>',
                }
            ]
        },
    },
    {
        blogid: 'kollajen-nedir',
        title: "Kollajen Nedir?",
        image: "/img/blog-images/kollajen-nedir.jpg",
        date: '14 Temmuz 2023',
        content: {
            blogPageTitle: "Kollajen Nedir?",
            blogPageDesc: "Kollajen; cildin, Kemiklerin, bağ dokusunun ve kasların yapısında bulunan önemli bir protein bileşenidir. Kemikleriniz ve cildiniz için yapısal matrisi oluşturur....",
            metaDescription: "Kollajen vücudumuzun temel yapı taşlarındadır. Takviye olarak alınırken nelere dikkat etmeliyiz. Ayrıntılı bilgi için tıklayın.",
            blogDetailPageInfo: [
                {
                    description: 'Kollajen; cildin, Kemiklerin, bağ dokusunun ve kasların yapısında bulunan önemli bir protein bileşenidir. Kemikleriniz ve cildiniz için yapısal matrisi oluşturur. Cilt kollajeni sayesinde cildinizi genç, gergin ve esnek hisseder, eklemleriniz sorunsuz hareket eder ve kemikleriniz güçlü kalır.',
                },
                {
                    description: 'Kollajen, insan vücudundaki kollajen fibrillerinin yapıtaşını üç amino asit oluşturur. Bu kollajen fibrilleri daha sonra kollajen lifleri yapmak için daha büyük gruplar halinde toplanır. Bu sayede kollajen kablo benzeri bir yapıya sahiptir, bir araya toplanmış birçok küçük protein demetinden oluşur ve bu da kollajene büyük gerilme dayanımını de dahil olmak üzere çeşitli fiziksel unsurlar kazandırır.',
                },
                {
                    description: 'Tip I kollajen:Deri başta olmak üzere, bir çok bağ dokusu, özellikle kemik, tendon',
                },
                {
                    description: 'Tip II kollajen: Kıkırdak (başlıca), vitröz sıvı',
                },
                {
                    description: 'Tip III kollajen: Deri, akciğer, damar gibi esnek yapılarda bulunur.',
                },
                {
                    description: 'Yetişkinlerin cildinde, kolajenin % 80’ini kolajen tip I oluşturur; kolajen tip III % 15 düzeyindedir; % 5 oranında da tip IV ve V bulunmaktadır',
                },
                {
                    title: 'Biliyor musunuz?',
                    description: 'Vücudunuz, yirmili yaşlarımızdan itibaren her yıl doğal kollajeninin %1,5-%2.5’unu her yıl kaybederiz. Böylelikle yaşlanma belirtileri yavaşca belirmeye başlar. Cilt elastikiyeni kaybeder, matlaşır, ince çizgiler oluşur, sarkmalar fark edilir. Kırılgan saçlar, ağrılı eklemler ve kemikler zamanla gelişir. Vücudumuzda kollajen kaybını azaltmaya yönelik takviyeler kulanılabilir.',
                },
                {
                    title: 'Ciltteki Kollajen Kaybına Ne Sebep Olur?',
                    description: 'Ciltteki kollajen kaybına neden olabilecek faktörler şunlardır: <br /><br />UV maruziyeti (fotoyaşlanma olarak da adlandırılır)<br />Yaş <br />Sigara içmek <br /> Genetik <br />Menopoz <br /> Çok kirli havayı solumak <br /> İşlenmiş gıdaların yüksek tüketimi <br /> Stres',
                },
                {
                    title: 'Kolajen alımında nelere dikkat edilmelidir?',
                },
                {
                    title: '1. İçeriğinde kollajen çeşidi ne olmalıdır?',
                    description: 'Hidrolize kolajenin emilimi çok daha iyidir. Daha biyoaktif bu kolajen, hidrolize kolajen veya kolajen peptitleri olarakta bilinir.'
                },
                {
                    title: '2. İçeriğinde ne kadar kollajen olmalı?',
                    description: 'Kollajen miktari en az 2.5 gr olmalıdır. 2.5 gr ila 10 gr arası idealdir. Kollajen alımında içeriğine ve miktarına dikkat ediniz.'
                },
                {
                    title: '3. Hangi kolajen kaynaklarını seçmelisiniz?',
                    description: 'Deniz kolajeni: balığın derisinden veya pullarından elde edilir. Tip 1 ve 2 kollajen peptitleri mevcuttur. Deniz kollajeni, vücudunuz tarafından daha iyi emildiği için etkinliği daha iyidir, cilt için en etkili kollajen kaynağı olduğu ve diğer kolajenlere kıyasla 1.5 kat daha fazla yarar sağlamaktadır. Fakat deniz yaşamından elde edildiği için deniz kolajeni, sığır kolajeninden biraz daha pahalıdır.'
                },
                {
                    description: '<strong>Sığır Kolajeni:</strong> sığır derisinden yapılır, tip 1 ve tip 3 kollajen içerir.'
                },
                {
                    title: '4. Kollajenin hangi formunu tercih edelim?',
                    description: 'Şase veya likid formunun, kapsül veya tablet formuna göre emilimi daha hızlı etkinliği daha iyidir.'
                },
                {
                    title: '5. Kollajeni ne kadar süre kullanalım:',
                    description: 'Kollajeni ortalama 12 hafta kullanılması uygundur sonrasında 12-16 hafta ara verilerek tekrar kullanılabilinir. Kolajen içerken bol su tüketimine özen göstermeliyiz.'
                },
            ]
        },
    },
    {
        blogid: 'saclar-neden-beyazlar',
        title: "Saçlar Neden Beyazlar",
        image: "/img/blog-images/drebrukaragun-cilt-catlaklari.jpg",
        date: '28 Mart 2023',
        content: {
            blogPageTitle: "Saçlar Neden Beyazlar",
            blogPageDesc: "Saçtaki kıl foliküllerin etrafında saçın rengini sağlayan pigment (melanin) hücreleri bulunur. Zamanla saç folikülleri pigment hücrelerini kaybederek beyaz saç rengine...",
            metaDescription: "Saçlarımızın beyazlamasının nedenleri nedir, beyazlayan saçlarının bir tedavisi var mıdır, beyazlamaması için neler yapmalıyız.",
            blogDetailPageInfo: [
                {
                    description: 'Saçtaki kıl foliküllerin etrafında saçın rengini sağlayan pigment (melanin) hücreleri bulunur. Zamanla saç folikülleri pigment hücrelerini kaybederek beyaz saç rengine dönüşür. İnsanlar yaşlandıkça saç renginin değişmesi normaldir. Fakat gri veya beyaz saç, hayatın hemen hemen her döneminde ortaya çıkabilir.',
                },
                {
                    description: 'Genetik faktörler, vitamin eksiklikleri, beslenme faktörleri, stres, saç boyaları ve diğer faktörler neden olabilir.',
                },
                {
                    description: 'Bu blogda, erken beyazlamış saçların yaygın nedenlerini yanı sıra bazı durumlarda beyazlama sürecini yavaşlatmanın veya engellemenin süreçlerinden bahsedeceğiz.',
                },
                {
                    title: 'Beyaz Saçın Nedensel Faktörleri:',
                    description: 'Bir kişinin saçlarının beyazlamasına neden olan yaş dışında birçok neden olabilir. Beyaz ve gri saçlar her yaşta çıkmaya başlayabilir ve bir dizi farklı faktörden kaynaklanabilir.',
                },
                {
                    title: '1. Vitamin eksiklikleri',
                    description: 'Beyazlama çoğunlukla genetik olsa da, B-6 vitamini, B-12, biotin, D vitamini veya E vitamini eksiklikleri erken beyazlamaya neden olabilir.',
                },
                {
                    description: 'Beslenme eksikliklerinin pigmentasyonu etkilediğini birçok çalışmada tespit edilmiştir, beyaz saç vitamin eksikliğine bağlı olan kişilerde vitamin takviyesi ile geri dönecektir.',
                },
                {
                    description: 'International Journal of Trichology dergisinde bildirilen 2016 tarihli bir araştırma makalesinde, 25 yaşın altındaki erken saç beyazlaması ile ilgili faktörleri incelenmiş. Erken saç beyazlaması olan katılımcılarda vücutta demir depolayan serum ferritin, B-12 düşük seviyelerde yaygın olduğunu tespit edilmiş. Erken yaşta saç beyazlaması yaşayanlarda kandaki vitamin değerlerine bakılması gerekir.',
                },
                {
                    title: '2. Genetik',
                    description: 'Indian Journal of Dermatology, Venereology and Leprology’de yayınlanan 2013 tarihli bir çalışmanın sonuçlarına göre bir kişinin saçının erken beyazlaması büyük ölçüde genetiğe bağlıdır. Saçtaki melanin kaybı, özellikle 30’lu yaşlardan sonra doğal olarak meydana gelir. Bununla birlikte, saç rengi kaybı büyük ölçüde genleriniz tarafından belirlenir. Ebeveynleriniz erken beyazlama yaşadıysa, muhtemelen sizin de saçınız erken beyazlaşacaktır.',
                },
                {
                    description: 'İnternette ve ürün pazarlamacıları tarafından yapılan iddialara rağmen, neden genetik ise beyaz saçı tersine çevirmek çoğu kez zordur.',
                },
                {
                    title: '3. Oksidatif stres',
                    description: 'Beyazlama çoğunlukla genetik olsa da, saç beyazlaşma süreci erken yaşlarda gerçekleştiğinde vücuttaki oksidatif stres rol oynayabilir.',
                },
                {
                    description: 'Serbest radikaller, hücrelere zarar veren, yaşlanmaya ve hastalığa neden olan kararsız moleküllerdir, antioksidanlar serbest radikallerin zararlı etkilerini ortadan kaldırırlar. Oksidatif stres, antioksidanlar serbest radikallerin zararlı etkilerine karşı koymak için yeterli olmadığında dengesizliklere neden olur. Vücutta antioksidanlar azaldığında oksidatif stres artar. Stresli yaşam tarzı, trans yağ ve rafine şeker içeren hazır ve paketli gıdalar, günde 7-8 saatten daha az uyku, hava kirliliği yüksek olan çevrede yaşamak vücuttaki oksidatif stresi artırır.',
                },
                {
                    title: '4. Eşlik Eden Hastalıklar',
                    description: 'Tiroid hastalıkları, alopesia areata (saç kıran) hastalığında da saçlar beyazlama eğilimindedir.',
                },
                {
                    title: '5. Stresli Yaşam',
                    description: 'Stresin saçların erken beyazlamasına neden olabileceği yaygın olarak düşünülmektedir. 2021 yılında yayınlanan Columbia Univ tarafından yapılan bir araştırmada saçların strese bağlı olarak beyazlaşabildiğini ve dahası stres düzeyi azalınca tekrar eski rengini alabildiğini tespit edilmiştir.',
                },
                {
                    title: '6. Sigara içmek',
                    description: 'İtalyan da yapılan 2013 tarihli bir araştırmada, sigara içenlerin sigara içmeyenlere göre 30 yaşından önce beyazlamaya başlama olasılığının 6 kat daha fazla olduğunu tespit edilmiştir.',
                },
                {
                    description: 'Journal of the American Academy of Dermatology’de 2015 yılında yayınlanan bir araştırmada, sigara içmenin genç erkeklerde 20’li yaşlarda saçlarda erken beyazlaşma ile ilgili bağlantılı olduğunu tespit edilmiştir.',
                },
                {
                    title: '7. Kimyasal saç boyaları ve saç ürünleri',
                    description: 'Kimyasal saç boyaları ve saç ürünleri, hatta şampuanlar bile erken saç beyazlamasına katkıda bulunabilir. Bu ürünlerin çoğu melanini azaltan zararlı bileşenler içerir.',
                },
                {
                    description: 'Birçok saç boyasında bulunan hidrojen peroksit de bu tür zararlı kimyasallardan biridir. Saçların rengini açan ürünlerin aşırı kullanımı da zamanla beyazlamasına neden olur.',
                },
                {
                    title: 'Erken Beyaz Saçı Önleme ve Tersine Çevirme',
                    description: 'Sebep genetik veya yaşlanma ise, çoğu kez süreç önleyemez veya tersine çevrilemez. Bununla birlikte, altta yatan bir neden varsa beyazlayan saçları tedavi etmek renk pigmentasyonunun geri dönmesini sağlamaktadır.',
                },
                {
                    description: 'Vitamin eksiklikleri erken beyaz saçların nedeni olduğunda, bunları düzeltmek sorunu tersine çevirebilir veya kötüleşmesini önleyebilir.',
                },
                {
                    description: 'Daha fazla antioksidan içeren sebze ve meyveler de dahil olmak üzere antioksidan bakımından zengin gıdalardan oluşan beslenme alışkanlığı, saçların beyazlamasını önlemeye yardımcı olabilir. Ve sigarayı bırakmak…',
                },
                {
                    title: 'SON SÖZ:',
                    description: 'Erken beyazlama olasılığı, saç köklerinizin melanin üretiminin azalması nedeniyle oluşur.  Bazen stres, kötü beslenme alışkanlıkları ve vücuttaki oksidatif stresi artıran diğer yaşam tarzı faktörleri melanin üretimini durdurabilir. Bu sorunlar tersine çevrildiğinde, melanin tekrar oluşur. Mezoterapi tedavi seçenekleri de saçlardaki renk kazanımına yardımcı olacaktır.',
                },
                {
                    description: 'Yine de çoğu durumda, grileri görmeye başladığınız yaşlar ve bunların kapsamı genleriniz tarafından kontrol edilir. Genetik olarak oluşan beyazlayan saçlar çoğu kez geri alınamaz. Fakat son yıllardaki mezoterapi seçeneklerinin gelişmesi ile genetik faktörlere bağlı olsa da süreç yavaşlatılabiliyor ve renk geri kazanımı sağlanabiliniyor.',
                },
                {
                    description: 'Mezoterapi tedavi seçeneği erken saç beyazlamasının her döneminde etkili olabileceği tespit edilmiştir.',
                },
                {
                    description: 'Beyazlarınızı kamufle etmek veya kabullenmek her ikisi de sizin tercihiniz. Kendinizi ayna karşısında nasıl mutlu hissediyorsanız o sizsinizdir.',
                },
                {
                    description: '<p style="text-align:right"><span style="font-weight:400">Doç. Dr. Ebru Karagün</span></p><p style="text-align:right"><span style="font-weight:400">Dermatoloji Uzmanı</span></p><span style="font-weight:400">Ve unutmayın ki hayat her zaman yakaladığımız yerden başlar.</span>',
                },
            ]
        },
    },
    {
        blogid: 'erkek-tipi-sac-dokulmesi',
        title: "Erkek Tipi Saç Dökülmesi",
        image: "/img/blog-images/erkek-tipi-sac-dokulmesi.jpg",
        date: '28 Mart 2023',
        content: {
            blogPageTitle: "Erkek Tipi Saç Dökülmesi",
            blogPageDesc: "Erkek Tipi Saç Dökülmesi: Genetik olarak yatkın kişilerde hormonların etkisiyle kıl follikülünün incelmesidir. Erkek ve kadınlarda temel nedenler aynıdır. Her iki...",
            metaDescription: "Erkek tipi saç dökülmesi neden olur, tedavilerin etkinliği var mıdır, saç ekimi çözüm müdür? Ayrıntılı bilgi için tıklayın.",
            blogDetailPageInfo: [
                {
                    description: 'Erkek Tipi Saç Dökülmesi: Genetik olarak yatkın kişilerde hormonların etkisiyle kıl follikülünün incelmesidir. Erkek ve kadınlarda temel nedenler aynıdır. ',
                },
                {
                    description: 'Her iki cinsiyette başlangıç ergenlikten sonra herhangi bir zamanda oluşabilir. Klinik olarak erkeklerde 17 yaş, kadınlarda 25-30 yaşlarında fark edilir. Her iki cinsde saç kaybı öncelikle saçlı derinin tepesinden öne doğru ilerler. Erkeklerde genellikle alın saç çizgisi geriye doğru çekilir Erkeklerde saçlı derinin tepe kısmının öne doğru, saçlı derinin ön bölgesi geriye doğru çekilmesi ile  tam kelliğe doğru ilerler.',
                },
                {
                    description: 'Genetik faktörler önemlidir; erkeklerin ailerinde saç dökülmesi problemi olan erkek akrabaların sayısı artıkça bu problemle karşılaşabilme ihtimalleri artar. Baba veya dayıda olması önemli risk faktörüdür.',
                },
                {
                    description: 'Kadınlarda ise annenin benzer saç dökülmesi olması risk faktörü oluşturur.',
                },
                {
                    title: 'Erkek Tipi Saç Dökülmesi Neden Olur?',
                    description: 'Saç köklerinde östrojen ve testosteron hormonlarına karşı reseptörler bulunmaktadır. Testosteron saçın düşmanıdır, saçı inceltir ve döker, tekrardan saçlar incelenerek çıkar ve tekrar dökülür, her defasında incelerek tekrar çıkar ve bir süre sonra saç o kadar incelir ki minyatürize bir saç teli olur ve artık saç kökünden çıkamaz, saç kökünden çıkamayan saç teli eğer 5-6 yıl çıkmaz ise o saç kökünü maalesef kaybederiz. Bu durum saç köklerindeki testosteron hormon duyarlılığın artmasından dolayı kaynaklanır bu nedenle kandaki testosteron düzeyleri normaldir. Hormon duyarlılığı olan saç kökleri saçın tepe, ön  ve yan bölgelerin üst kısımlarıdır. Saçlı deride ense bölgesinde ve yan bölgedeki saçların alt kısımlarında saçlarınızın hormon duyarlılığı olmadığından dökülmeyecektir. ',
                },
                {
                    title: 'Nasıl Tanı Konulur?',
                    description: 'Tanı genellikle klinik muayene ile konulmaktadır. Dermaskopik muayene ile saç köklerinizin değerlendirilmesi yapılmaktadır. Kadınlarda eğer adet düzensizliği mevcut ise hormon değerlerine bakılması gerekmektedir. Özellikle erkeklerde kellik sürecine ilerleme farklılık göstermektedir. Bazı kişilerde bu süreç 5 yıl kadar kısa olabilirken bazı kişilerde 15-20 yıl kadar uzun olabilmektedir. Kadınlarda kellik süreci olmaz fakat saçlı deride tepe kısmı yılbaşı ağacı gibi bir görünüme sahip olabilir. ',
                },
                {
                    title: 'Erkek Tipi Saç Dökülmesinde Tedavi',
                },
                {
                    title: 'Evde Yapılabileceğiniz Tedaviler',
                    description: 'Minoksidil: Minoksidil en etkili evde kullanabilecek tedavi seçeneğidir. Kullanıldığı sürece etkinliği devam eder, tedavinin kesilmesiyle 3-4 ay sonra saçlar dökülmeye başlar. Erkeklerde %5 topikal minoksidil daha etkili bulunmuştur. Sabah ve akşam uygulanmalıdır. Saçlı deride en az 4 saat kalmalıdır. Kadınlarda %2 ve %5’lik formu kullanılır sıklıkla tedavide %2’lik formu tercih edilir. Tedaviye başladıktan sonra ortalama 8 hafta saç dökülmesi görülebilir, bu normal bir süreçtir.  Etkinliği 6.ayda başlar, maksimum etkinlik için 1 yılda elde edilir. ',
                },
                {
                    title: 'Mezoterapi Uygulamaları',
                    description: 'Erkek tipi saç dökülmesinde mezoterapi çok etkin bir tedavi seçeneğidir. Doktorunuz saç dökülmenizi değerlendirildikten sonra mezoterapi tedavi seçeneğinin protololünü belirler. Bu protokolde bazı hastalar için haftalık seanslar olabilir bazı hastalar için aylık seanslar olabilir. Başlangıçtaki tedavi protokolü genellikle 3-4 aya tamamlanır. Sonrasında devam tedavisinde 3-4 ayda bir olacak şekilde mezoterapi uygulamasının yapılması önemlidir. Mezoterapi uygulamasındaki amaç saç köklerindeki testosteron hormonuna karşı duyarlılığı azaltmak ve saç döngüsü için önemli olan büyüme faktörleri, vitaminler, mineraller desteği sağlayarak minyatürize olmuş saç telinin tekrar kalın saç kılına dönmesini sağlamak amaçlanır.',
                },
                {
                    description: 'Mezoterapi uygulamasından 2-3 ay sonra saç dökülmesinde azalma, 3-4.ayda yeni saç çıkışlarında başlama ve 6-8. aylarda saç yoğunluğunda artış beklenir. Devam tedavisine uyum sağlanırsa iyilik hali devam eder. ',
                },
                {
                    description: 'Tedavi etkinliğini belirleyen en önemli faktör; saç dökülmesinin başlandığı zamandır. Eğer mintatürize olmuş saç teli saç kökünden  5-6 yıl çıkmaz ise bizler o saç kökünü kaybediyoruz, tekrardan saç kökünü geri kazanmamız mümkün olmaz. Saç dökülmesi başladıktan sonra ne kadar erken dönemde tedavi başlanırsa tedavi etkinliği o kadar artar. ',
                },
                {
                    title: 'Platelet Rich Protein (PRP)',
                    description: 'Mezoterapi uygulamalarında PRP uygulamasını sıkça tercih edilir. PRP’de yoğun trombosit içeriği mevcuttur. Trombositler büyüme faktörlerinden zengin hücrelerdir ve uyarıcı-onarıcı-besleyici etkinliği mevcuttur. Mezoterapi tedavi protokolünde PRP uygulaması çoğu kez yer almaktadır.',
                },
                {
                    title: 'Dermapen',
                    description: 'Saç köklerini uyarmak ve yeniden saç çıkışını artırmak için dermapen tedavisi, mezoterapi tedavi seçeneği ile kombine edilmektedir.',
                },
                {
                    title: 'Saç Ekimi',
                    description: 'Son yıllarda saç ekimi uygulamaları sıklıkla yapılmaktadır. Bu yöntem erkek tipi saç dökülmesi için başarılı sonuçlar göstermiştir. Kozmetik cevap, ense bölgesindeki verici saçların miktarına ve yoğunluğu kadar uygulayan kişinin tecrübesinde de bağlıdır. Saç ekimi yapılan saçlar ense bölgesinden alındığından ve bu bölge saçlarının hormon duyarlılığı olmadığından transfer edildiği alanda dökülmeyecektir. Fakat saç ekimi yapılmış olsa bile mevcut olan tepe-ön bölge ve yan kısımların üst kısımlarındaki saçların korunması için mezoterapi uygulamalarına devam edilmesi gerekmektedir. ',
                },
                {
                    description: '<p style="text-align:right"><span style="font-weight:400">Doç. Dr. Ebru Karagün</span></p><p style="text-align:right"><span style="font-weight:400">Dermatoloji Uzmanı</span></p><span style="font-weight:400">Ve unutmayın ki hayat her zaman yakaladığımız yerden başlar.</span>',
                },
            ]
        },
    },
    {
        blogid: 'cildiniz-icin-en-iyi-7-antidoksan',
        title: "Cildiniz İçin En İyi 7 Antioksidan",
        image: "/img/blog-images/cildiniz-icin-en-iyi-7-antidoksan.jpg",
        date: '3 Mart 2023',
        content: {
            blogPageTitle: "Cildiniz İçin En İyi 7 Antioksidan",
            blogPageDesc: "Antioksidanlar, hücrelerin yaşlanmasına neden olan oksidatif stresi tersine çevirerek veya önleyerek çalışırlar.Oksidatif stresi tanımlamak için bir örnek verecek olursak; dilimlenmiş...",
            metaDescription: "Antioksidanlar, hücrelerin yaşlanmasına neden olan oksidatif stresi tersine çevirerek veya önleyerek çalışırlar.",
            blogDetailPageInfo: [
                {
                    description: 'Antioksidanlar, hücrelerin yaşlanmasına neden olan oksidatif stresi tersine çevirerek veya önleyerek çalışırlar.',
                },
                {
                    description: 'Oksidatif stresi tanımlamak için bir örnek verecek olursak; dilimlenmiş bir elmanın kahverengiye dönüşmesidir.',
                },
                {
                    description: 'Ne yazık ki, oksidatif stres hasarı cildin düzenli olarak kendi kendini onarma sistemlerini engeller. Yaş ile birlikte cildimizde oksidatif stress artmaktadır aynı zamanda güneş ışığı, hava kirliliği, çevresel toksinler, sigara, yetersiz uyku, kötü beslenme ve stres de oksidatif stresi artırmaktadır. Bu nedenle kullanılacak olan topikal antioksidanlar oksidatif stresi azaltmakta etkilidir.',
                },
                {
                    title: 'C Vitamini',
                    description: 'Dermatologlar arasında favori olan C vitamini, en çok çalışılan antioksidanlardan biridir. Serbest radikal temizleyici olarak, C vitamini kollajen üretimini artırıcı ve lekeleri azaltıcı etkinliğe sahiptir. Ürünlerde L-askorbik asit, C vitamini esteri, askorbil palmitat, sodyum askorbil fosfat, magnezyum askorbil fosfat veya lipofilik C vitamini olarak listelenebilir. En etkin kontrasyonu %8-15 arasındadır. Uygulama: sabahları tüm yüze uygulandıktan sonra üzerine güneş kremini kullanılmasını şeklindedir.',
                },
                {
                    title: 'Niacinamide',
                    description: 'B3 vitamini olarak da bilinen niasinamid cildin dokusunu ve tonusunu iyileştiren güçlü bir antioksidandır. İnce çizgileri, kırışıklıkları, lekeleri azaltır ve ciltteki yağ dengesini düzenleyerek sivilce tedavisinde de etkinliği mevcuttur. Yatıştırıcı etkinliği ile kızarıklığı da azaltır.',
                },
                {
                    title: 'Resveratrol',
                    description: 'Resveratrol, vücuda gerekli olan maddelerden bir tanesidir. Çünkü resveratrol tam bir antioksidan kaynağıdır. Vücudun yenilenmesine ve sağlıklı olmasına büyük katkı sağlar. Çoğunlukla kırmızı-mor meyvelerin kabuklarında bulunan bir antioksidan olan resveratrol, bitkilerin savunma zırhı olarak işlev görür ve cilt bakım ürünlerinde kullanıldığında benzer şekilde onarıcı bir etkiye sahiptir. Resveratrol, cildi sakinleştiren, cilt yaşlanmasını azaltan güçlü bir antioksidandır. Uygulamayı: akşamları önermekteyiz, çünkü UV ışınlarına maruz kalma resveratrolün etkinliğini azaltmaktadır.',
                },
                {
                    title: 'E vitamini',
                    description: 'E vitamini, ciltte dahil olmak üzere vücuttaki birçok organın düzgün çalışması için gerekli olan önemli bir antioksidandır. Cildin iyileşme sürecini hızlandırma kabiliyeti için yaygın olarak kullanılır. E vitamininin genellikle kuru cildi tedavi etmek için formüle edilmiş nemlendiricilerde, kremlerde, losyonlarda ve ayrıca çatlak izlerini azaltmak için tasarlanmış ürünlerde bulunmaktadır. E vitamini ayrıca diğer antioksidanları stabilize etmeye ve etkinliklerini artırmaya yardımcı olur, bu nedenle genellikle C vitamini veya resveratrol ile beraber kullanılması her birinin ürünün etkinliğini artırır.'
                },
                {
                    title: 'Retinol (A vitamini)',
                    description: 'Yaşlanan ciltteki biyolojik saati geri döndürmeye yardımcı olmak için tekrar tekrar kanıtlanmış bir bileşen varsa, bu retinoldür. A vitamininin bir türevi olan bu antioksidan, kollajen üretimini etkili bir şekilde uyarabileceği ve hücre yenilenmesini ve onarımını hızlandırabileceği kadar derine nüfuz etmesini sağlayan küçük moleküler yapısı nedeniyle özellikle etkilidir. Sadece kış döneminde kullanılmasını öneriyoruz.',
                },
                {
                    title: 'Koenzim Q10',
                    description: 'Yaş aldıkça vücudumuzda ubikinon olarakta bilinen koenzim Q10’un varlığı yavaş yavaş azalır ve bizi kırışıklıklara ve foto-yaşlanmaya karşı daha duyarlı hale getirir. Araştırmalar, bu antioksidanın artan topikal uygulamasının serbest radikal hasarıyla savaşmaya yardımcı olduğunu ve cilt hücrelerimizi sağlıklı tuttuğunu göstermektedir. Bu ürün cilt tarafından kolayca emilir ve elastikiyeti artırarak dokuyu iyileştirmeye yardımcı olur ve kollajen üretimini artırır.',
                },
                {
                    title: 'Polifenoller',
                    description: 'Polifenoller her molekülde birden fazla fenol grubunun bulunduğu, antioksidan özelliği gösteren, bitkilerde doğal olarak bulunan ve onların renklerinden sorumlu olan bileşiklerdir. Meyvelerde, sebzelerde, yeşil veya siyah çayda (flavonoidler olarak da adlandırılır) bulunan polifenol türüdür. Çalışmalar, polifenoller bakımından zengin bileşenlerin tüketiminin ve topikal kullanımının, cildimizin oksidatif strese karşı doğal savunmasını geliştirmeye, yaşlanma belirtilerini önlemeye ve DNA hasarını onarmaya yardımcı olduğunu göstermektedir.',
                },
                {
                    description: '20’li yaşlardan itibaren cildimize antioksidan ürünlerin kullanmaya başlanılmasını önermekteyiz.',
                },
            ]
        },
    },
    {
        blogid: 'akne-sivilce-izlerinin-tedavisi',
        title: "Akne - Sivilce İzlerinin Tedavisi",
        image: "/img/blog-images/akne-sivilce-izlerinin-tedavisi.jpg",
        date: '25 Kasım 2022',
        content: {
            blogPageTitle: "Akne - Sivilce İzlerinin Tedavisi",
            blogPageDesc: "Sivilce İzleri Neden Olur: Sivilcelerin erken ve etkili tedavisi sivilce izi oluşumunu büyük ölçüde önleyecektir, hedef iz gelişmeden önce tedavi...",
            metaDescription: "Akne-Sivilce izleri canınızı sıkabilir, ancak sivilce izlerinizi daha az fark edilir hale getirebilecek birçok tedavi seçeneği vardır.",
            blogDetailPageInfo: [
                {
                    title: 'Sivilce İzleri Neden Olur:',
                    description: 'Sivilcelerin erken ve etkili tedavisi sivilce izi oluşumunu büyük ölçüde önleyecektir, hedef iz gelişmeden önce tedavi edebilmektir.',
                },
                {
                    description: 'Şunu unutmayın; Sivilce izini tedavi etmenin en iyi yolu, ilk etapta sivilceyi önlemektir.',
                },
                {
                    description: 'Eğer sivilcelerinizle oynarsanız sivilce izleri geliştirme olasılığını artırırsınız. Sivilcelerinizi koparmaktan, patlatmaktan veya sıkmaktan kaçınmalısınız.',
                },
                {
                    description: 'Oluşmuş olan sivilce izleriniz kendiliğinden düzelmeyecektir, düzelmesi için cildi uyararak tedavi işlemlerinin olması gerekmektedir.',
                },
                {
                    title: 'Sivilce İzlerinin Çeşitleri:',
                    description: '<b>Eritem (Kızarıklık):</b> Sivilce geçtikten sonra sivilce izleri alanlarında kızarıklık kalabilir. Zamanla gerileyebilir. Eğer kalıcı olmuş ise damar lazeri uygulamasının tedavi etkinliği mevcuttur.',
                },
                {
                    description: '<b>Çökük Sivilce İzleri:</b> Sivilce izlerin %80-90’ı kollajen doku kaybı ile oluşan çökük skarlardır. Başlıca icepick (buz kıracağı veya kazma izi tipinde), boxcar (vagon tipi) ve rolling (yuvarlak veya dalgalı tip) skarlar olarak sınıflandırılmaktadır.',
                },
                {
                    description: '<b>İcepick (buz kıracağı veya kazma izi tipinde):</b> Dar (<2mm), derin, keskin sınırlı tünellerdir ve derinin alt tabakasına ulaşır. Tüm akne izlerinin %60-70’ini oluşturur.',
                },
                {
                    description: '<b>Rolling (yuvarlak veya dalgalı tip):</b>  Derinin derine çekilmiş görünümü olan lezyonlardır ve 4-5mm’den daha geniştirler. Dalgalı görünümleri mevcuttur.',
                },
                {
                    description: '<b>Boxcar (vagon tipi):</b> Keskin sınırlı köşeli, yuvarlak veya oval çöküntülerdir. Genellikle 1,5 ila 4 mm çaplarındadır.',
                },
                {
                    title: 'Sivilce İzlerinin Tedavi Seçenekleri:',
                    description: 'Sivilce izleri inatcıdır ve herkes için geçerli bir tedavi seçeneği yoktur. Sivilce izi tedavisinde detaylı bir öykü ve muayene ile uygun ve etkili bir tedavi planı için kilit rol oynamaktadır. Hastanın yaşı, sivilce izlerinin türü, sivilce izlerinin rengi, cilt tipine göre tedavi seçenekleri değişkenlik göstermektedir. Sivilce izi tedavisinde tek başına ideal bir prosedür olmadığından en iyi sonuçları elde etmek için kombinasyon tedavileri gereklidir. Farklı sivilce izi tipleri ve hastalara göre değişen, kombine tedavi yöntemlerini uygulamak daha etkili olmaktadır.',
                },
                {
                    description: 'Tedavi etkinliği kişiden kişiye göre değişmekle birlikte %30 ila %80 arasında iyileşme gözlenmektedir.',
                },
                {
                    description: 'Tedaviye ne kadar genç yaşlarda başlanırsa tedavinin sonuçları o kadar tatmin edici olmaktadır.',
                },
                {
                    title: 'Cihaz işlemleri',
                    description: 'Scarlet X ve/veya franksiyonel lazer işlemleri uygulanabilir. Uygulamalar seanslar şeklinde yapılır. Seans sayısı sivilce izlerinin türüne ve şiddetine bağlı olarak değişkenlik göstermektedir ortalama olarak en az üç seans önerilir, bazı kişilerde seans sayısı artabilir. Beraberinde PRP uygulamasının yapılması tedavi etkinliğini artırmaktadır.',
                },
                {
                    description: 'Scarlet X uygulamaları her mevsim uygulanabilir, işlem sonrasında ciltte soyulma olmadığından daha konforlu uygulamadır.',
                },
                {
                    description: 'Franksiyonel lazer işlemleri kış döneminde uygulanmaktadır, işlemden sonra ciltte soyulma olduğundan çok iyi güneşten korunması ve cildi nemlendirilmesi gerekmektedir.',
                },
                {
                    title: 'Subsizyon',
                    description: 'Subsizyon, cilt altında deriyi alt tabakalara doğru çeken bantları serbeştirmek için uygulanır. Rolling (yuvarlak veya dalgalı tip) tip sivilce izlerinde uygulanması tedavinin etkinliğini artırır.',
                },
                {
                    title: 'Kimyasal Peeling',
                    description: 'Sivilce izlerinin olduğu deri dış katmanları soyularak deriden uzaklaştırılır ve ayrıca hasarlanma sonucu deri uyarılarak yeni kollajen sentezi tetiklenir. Koyu tenli kişilerde tedavi sonrasında lekelenme riski nedeniyle uygulanırken dikkatli olmak gerekmektedir. Özellikle ice-pick sivilce izlerinde CROSS-TCA yönteminin etkinliği mevcuttur.',
                },
                {
                    title: 'Dermapen',
                    description: 'Özellikle yeni gelişmiş olan sivilce izlerinin tedavisinde etkinliği mevcuttur. Kişi koyu tenli ise uygulama sonrasında leke kalabilir bu nedenle uygun hasta seçimi önemlidir.',
                },
                {
                    title: 'Yumuşak doku dolgu maddeleri',
                    description: 'Cildin altına kollajen, hyalüronik asit enjekte edilmesi girintili yara izleri üzerinde cildi dolgunlaştırır. Amaç, sivilce izlerini daha az fark edilir hale getirmektir. Bu uygulamaları özellikle cihaz işlemlerinden sonra uygulanması tedavi etkinliği artırır.',
                },
                {
                    title: 'Son Söz:',
                    description: 'Sivilce izlerini tamamen iyileştiren tek bir yöntem yoktur, kombine tedavi gerekir.',
                },
                {
                    description: 'Tedavi seçeneklerinden sivilce izinin tipine göre hastaya özel tedavi protokolü oluşturulmalıdır.',
                },
                {
                    description: 'Bir sivilce izi tedavi planı her hastada aynı sonucu vermez.',
                },
                {
                    description: 'Sivilce izleri canınızı sıkabilir, ancak sivilce izlerinizi daha az fark edilir hale getirebilecek birçok tedavi seçeneği vardır.',
                },
            ]
        },
    },
    {
        blogid: 'deri-catlaklari-neden-olur-nasil-onlerim',
        title: "Deri Çatlakları Neden Olur, Nasıl Önlerim?",
        image: "/img/blog-images/deri-catlaklari-neden-olur-nasil-onlerim.jpg",
        date: '8 Kasım 2022',
        content: {
            blogPageTitle: "Deri Çatlakları Neden Olur, Nasıl Önlerim?",
            blogPageDesc: "Yaygın olarak deri çatlakları-cilt çatlakları olarak bilinen ‘Stria’ cildin aşırı gerilmesi sonucu cildin alt tabakalarında gelişen ve deri yüzeyinde görünür...",
            metaDescription: "Deri çatlakları genel sağlık açısından önemli olmamakla birlikte, etkilenen bireylerde özellikle de kadınlarda kozmetik endişe yaratan bir durumdur. Ayrıntılı bilgi için tıklayın.",
            blogDetailPageInfo: [
                {
                    description: 'Yaygın olarak deri çatlakları-cilt çatlakları olarak bilinen ‘Stria’ cildin aşırı gerilmesi sonucu cildin alt tabakalarında gelişen ve deri yüzeyinde görünür hale gelen yara izleridir. Deri çatlakları son derece yaygındır, kadınlarda iki kat daha sıktır. 5-50 yaş grubunda gözlenebilir ve görülme sıklığı %11-88 arasında değişmektedir. Hamilelik döneminde kadınların %90’ında gözlenmektedir.',
                },
                {
                    description: 'Deri çatlakları genellikle hamilelik, ergenlik döneminde hızlı büyüme dönemlerinde veya ağırlık kaldırmak şeklinde spor yapanlarda, hızlı kilo alındığı veya hızla kilo kaybı gibi hızlı kilo değişimi gibi çeşitli fizyolojik durumlarda gelişir.',
                },
                {
                    description: 'Ergenlik döneminde görülen deri çatlakları kızlarda üst bacak, alt bacak, kalça ve göğüslerin üzerinde, erkeklerde ise genellikle bel bölgesinde, kalçada ve üst bacak bölgesinde oluşur.',
                },
                {
                    description: 'Gebelikte gözlenen deri çatlakları; son üç ayda belirir, sıklıkla karın, meme ve üst bacak bölgesinde gözlenir.',
                },
                {
                    title: 'Gebelikte gözlenen deri çatlakları için risk faktörleri:',
                    description: '<ul><li>Ailesel yatkınlık,</li><li>Genç yaşta gebe kalmak,</li><li>Gebelikte kilo artışının fazla olması,</li><li>Karın büyüklüğü ile (kilolu bebek, amniyon suyunun çok olması veya çoğul gebelikler) ile ilişkilidir.</li></ul>',
                },
                {
                    title: 'Deri Çatlakların Önlenmesi ve Tedavisi:',
                    description: 'Deri çatlakları genel sağlık açısından önemli olmamakla birlikte, etkilenen bireylerde özellikle de kadınlarda kozmetik endişe yaratan bir durumdur.',
                },
                {
                    title: 'Önlemek için;',
                    description: '1. Deri çatlaklarında ilk olarak riskleri iyi belirlemek ve hastayı bu risk faktörleri konusunda bilinçlendirmek son derece önemlidir.',
                },
                {
                    description: '2. Gebelik döneminde kremler 12. Gebelik haftasından itibaren başlanmalıdır.',
                },
                {
                    description: '3. Özellikle; Centella asiatica içeren kremler (trofolastin), C vitamini, E vitamini, kuşburnu yağının etkili olduğu çalışmalarda tespit edilmiştir.',
                },
                {
                    description: 'Yaygın bir şekilde kullanılan badem yağı, kakao yağı, zeytinyağının etkinliğine dair bilimsel çalışmalarda yeterli olumlu sonuçlar alınamamıştır.',
                },
                {
                    description: 'Özellikle gebelikte kremlerin masaj yaparak uygulanması deri çatlağı oluşumunu azalttığı bilinmektedir.',
                },
                {
                    title: 'Tedavi İçin;',
                    description: 'Dermapen, Scarlet-X, Kimyasal Peeling, Lazer Tedavileri, PRP, Mezoterapiler uygulanmaktadır. Özellikle erken evrede pembe mor evresinde tedavi yapılırsa tedavi etkinliği artmaktadır.',
                },
                {
                    description: 'Her yaş grubunda ve emzirme dönemlerinde uygulanacak olan tedavi seçenekleri mevcuttur. Ve deri çatlaklarının hem pembe mor evresinde, hem de beyaz evresinde tedavi seçenekleri vardır. Ama erken dönemde pembe-mor evresinde tedavi uygulanırsa tedavi etkinliğini artıracağı özellikle bilinmelidir.',
                },
                {
                    title: 'REFERANSLAR:',
                    description: 'Lokhande AJ, Mysore V. Striae Distensae Treatment Review and Update. Indian Dermatol Online J. 2019;10(4):380-395.',
                },
                {
                    description: 'A Hague, A Bayat .Dermatology,Therapeutic targets in the management of striae distensae: A systematic review. J Am Acad Dermatol. 2017 Sep;77(3):559- 568.e18.',
                },
                {
                    description: 'Yu Y, Wu H, Yin H, Lu Q. Striae gravidarum and different modalities of therapy: a review and update. J Dermatolog Treat. 2022 May;33(3):1243-1251',
                },
                {
                    description: 'Forbat E, Al-Niaimi F. Treatment of striae distensae: An evidence-based approach. J Cosmet Laser Ther. 2019;21(1):49-57.',
                },
                {
                    description: 'Ud-Din S, McGeorge D, Bayat A. Topical management of striae distensae (stretch marks): prevention and therapy of striae rubrae and albae. J Eur Acad Dermatol Venereol. 2016 Feb;30(2):211-22.',
                },
            ]
        },
    },
    {
        blogid: 'uyuz-nasil-bulasir-ve-korunma-yontemleri-nelerdir',
        title: "Uyuz Nasıl Bulaşır ve Korunma Yöntemleri Nelerdir?",
        image: "/img/blog-images/uyuz-nasil-bulasir-ve-korunma-yontemleri-nelerdir.jpg",
        date: '21 Ekim 2022',
        content: {
            blogPageTitle: "Uyuz Nasıl Bulaşır ve Korunma Yöntemleri Nelerdir?",
            blogPageDesc: "Uyuz hastalığı (Skabiyez), akarların neden olduğu ve bütün dünyada görülen bulaşıcı bir parazitik  deri hastalığıdır. Mikroskobik akarlar cilde girer...",
            metaDescription: "Uyuz hastalığı (Skabiyez), akarların neden olduğu ve bütün dünyada görülen bulaşıcı bir parazitik deri hastalığıdır. Bilgi almak için tıklayın.",
            blogDetailPageInfo: [
                {
                    title: 'Uyuz Nedir?',
                    description: 'Uyuz hastalığı (Skabiyez), akarların neden olduğu ve bütün dünyada görülen bulaşıcı bir parazitik  deri hastalığıdır. Mikroskobik akarlar cilde girer ve yumurta bırakarak yoğun kaşıntı ve döküntüye yol açan bir reaksiyona neden olurlar. Bulaş olduktan 3-6 hafta sonra da skabiyez belirtileri ortaya çıkar.',
                },
                {
                    title: 'Uyuzun Belirtileri Nelerdir?',
                    description: 'Uyuz, kişinin genel sağlık durumu için tehdit oluşturmasa da hayat kalitesini oldukça düşürür.',
                },
                {
                    description: '1. Uyuzun en belirgin özelliği şiddetli gece kaşıntısı olup, kaşıntı kişinin yatağa girip ısınması ile artış gösterir. Kaşıntı bu durumda kişiyi uykudan uyandırabilecek kadar şiddetli olurken gündüzleri ise daha hafif bir kaşıntı görülmektedir.',
                },
                {
                    description: '2. Kaşıntı şikayeti tipik olarak bulaştan ortalama 4 hafta (3 ile 6 hafta) sonra görülür. ',
                },
                {
                    description: '3.Hastalarda kaşıntı tipik olarak parmak araları, el bileklerinin iç yüzü, koltuk altları, meme başları, kollar ve bacaklar, ayak bilekleri ve göbek çevresinde belirgindir. Bebekler ve küçük çocuklarda avuç içlerinin tutulumu, ayak tabanları, ayak bilekleri ve bazen kafa derisi de dahil olmak üzere daha yaygın bir döküntü görülebilir. Özellikle yetişkin erkeklerin genital bölgesinde kırmızı deriden kabarık sert lezyonlar gözlenebilir.',
                },
                {
                    description: '4. Başarılı bir tedavi sonrasında bile kaşıntı şikayeti 2 ila 6 hafta kadar daha devam edebilir.',
                },
                {
                    title: 'Uyuz Nasıl Bulaşır?',
                    description: '1. Uyuzun bulaşması genellikle aile üyeleri veya cinsel partnerler arasında doğrudan ve uzun süreli (en az 30 dk) cilt temasıyla gerçekleşir. Kısa süreli cilt temasıyla (örn. el sıkmak) bulaş olmaz.',
                },
                {
                    description: '2. Uyuzlu bir kişi tarafından kullanılan giysiler bir başkası tarafından giyildiğinde. Yatak örtüleri ile bulaşma nadir olmakla birlikte bu da ihtimal dahilindedir.',
                },
                {
                    description: '3. Kıyafetlerden bulaşma nadir olmakla birlikte özellikle kafelerde kullandığımız şallar risk oluşturabilir.',
                },
                {
                    title: 'Uyuz Teşhisi Nasıl Konur?',
                    description: 'Uyuz düşünülen hastalarda olası tanı çoğunlukla öykü ve muayeneye göre konulur. Uyuz çok bulaşıcı olup yaş, cins ve sosyoekonomik düzey gözetmeksizin herkesi enfekte edebileceğinden erken tanı ve kontrol önlemlerinin erken alınmasının önemi çok büyüktür.',
                },
                {
                    title: 'Uyuzdan Korunma Yöntemleri',
                    description: '1. Hastalığın önlenmesi ve kontrolü için uyuz tanısı konulmuş kişi ile aynı evi paylaşan herkesin (kaşıntı şikayeti olsun olmasın) aynı zamanda tedaviye alınması gereklidir (Aile tedavisi önemlidir). Aynı anda tedavi uygulanmazsa hastalık kişiden kişiye bulaşmaya devam edecektir. Bulaş ile şikayetlerin gelişimi arasındaki süre zarfında henüz kaşıntı gelişmeden bile bulaştırıcı olabilirsiniz.',
                },
                {
                    description: '2. Ev içinde kullanılan kişisel eşyalar ayrılmalı; giysiler, çarşaflar ve havlular en az 70-80 derecede yıkanmalı ve özellikle çamaşırların dikiş yerleri ütülenmelidir.',
                },
                {
                    description: '3. Yatak takımları sürekli değiştirilmelidir.',
                },
                {
                    description: '4. Yıkanamayacak eşyaların (mont-kaban gibi) ağzı bağlı bir poşet içerisinde 5-7 gün bekletilmesi gerekir.',
                },
            ]
        },
    },
    {
        blogid: 'yuz-lekelerinden-kurtulmak-mumkun-mu',
        title: "Yüz Lekelerinden Kurtulmak Mümkün Mü?",
        image: "/img/blog-images/yuz-lekelerinden-kurtulmak-mumkun-mu.jpg",
        date: '14 Ekim 2022',
        content: {
            blogPageTitle: "Yüz Lekelerinden Kurtulmak Mümkün Mü?",
            blogPageDesc: "Yüzdeki lekeler birçok insan için talihsiz bir gerçektir. Melasma, çeşitli nedenlerden dolayı yüzde kahverengi leke olarak kendini gösteren bir cilt...",
            metaDescription: "Yüzdeki lekelerin nedenlerini ve lekelerden kurtulmanıza yardımcı olacak çözümleri öğrenmek ve tedavi ile ilgili bilgi almak için hemen tıklayın.",
            blogDetailPageInfo: [
                {
                    description: 'Yüzdeki lekeler birçok insan için talihsiz bir gerçektir. Melasma, çeşitli nedenlerden dolayı yüzde kahverengi leke olarak kendini gösteren bir cilt hastalığıdır.',
                },
                {
                    description: 'Bu yazıda yüzlerimizde oluşan bu lekelerin nedenlerini anlatacak ve onlardan kurtulmanıza yardımcı olacak çözümler sunacağız.',
                },
                {
                    title: 'Yüz Lekeleri (Melasma) Ne Kadar Yaygındır?',
                    description: 'Genellikle 20-40 yaş arasında başlar ve kadınların yüzde 40-50’sini etkileyecek kadar sık gözlenir. Yüz lekesi, özellikle hamile kadınlar arasında çok yaygın bir cilt bozukluğudur. Hamile kadınların %15 ila %50’sini etkiler.',
                },
                {
                    title: 'Kimler Yüz Lekesi Oluşturma Riski Altındadır?',
                    description: '1. Koyu ten rengine sahipseniz, açık ten rengi olanlara göre lekelenme riskiniz daha fazladır. ',
                },
                {
                    description: '2. Doğum kontrol ilacı ve hormon tedavisi alıyorsanız risk altındasınızdır. ',
                },
                {
                    description: '3. Hamilelik: Hamilelik sürecinde hormonların seviyeleri artmıştır ve bu hormonlar da lekelenmeye neden olur. Lekelenmedeki artış 3. aydan sonra başlar. Hamile iken oluşan lekelerin %60’ı doğumdan sonra bir yıl içinde geriler.',
                },
                {
                    description: '4. Genetik: Yüzünde leke olan kişilerin yaklaşık %33 ila %50’sinin ailesinde başka bireylerde de leke mevcuttur.',
                },
                {
                    description: '5. Mavi Işık: Televizyonunuzdan, dizüstü bilgisayarınızdan, cep telefonunuzdan ve tabletinizden gelen mavi ışık yüzünüzde lekelenmeye neden olmaktadır.',
                },
                {
                    description: '6. Makyaj (kozmetik): Bazı kozmetikler fototoksik (cildi güneş ışığına duyarlı hale getiren) reaksiyon olarak adlandırılan reaksiyona neden olurlar ve lekelenmeyi artırırlar.',
                },
                {
                    description: '7. Sir ağda gibi sıcak ağda uygulamaları ciltte lekelenmelere yol açar.',
                },
                {
                    description: '8. Yüze uyguladığınız cildi hasarlandıracak uygulamalar (evde yapılan peelingler).',
                },
                {
                    description: '9. Fototoksik ilaçlar (sizi güneş ışığına duyarlı hale getiren ilaçlar): Bunlar arasında bazı antibiyotikler, sivilce ilacı veya kremleri, bazı psikiyatri ilaçları ve anti nöbet ilaçları bulunur.',
                },
                {
                    title: 'Yüz Lekeleri (Melasma) Kalıcı Mıdır?',
                    description: 'Bazı kişiler yıllarca veya tüm yaşamları boyunca yüz lekesine sahip olabilirler ve bu kişilere uygulanacak olan tedavilerin devamlılık göstermesi gerekmektedir.',
                },
                {
                    title: 'Güneş Kremini Nasıl Kullanmalısınız?',
                    description: 'Hangi leke tedavisini yaptırırsanız yaptırın güneş koruyucusuz olmaz. Güneş, leke oluşumda tek yönlü bir bilettir. ',
                },
                {
                    description: '1. En az SPF 30’a sahip olan (SPF 50 üstü güneş koruyucu kullanmak ek katkı sağlamayacaktır).',
                },
                {
                    description: '2. İçeriğinde çinko oksit ve/veya titanyum dioksit içeren güneş koruyucu tercih edin. Özellikle güneş koruyucunuz demir oksit içeriyorsa mavi ışık spektrumuna da etkin koruma sağlayacaktır. ',
                },
                {
                    description: '3. Güneş koruyucunuzu dışarı çıkmadan yarım saat önce sürüp dört saatte bir tekrarlamaya özen gösterin.',
                },
                {
                    description: '4. Bilgisayar ekranı, telefon ekranı gibi mavi ışık kaynaklarına maruziyetiniz varsa kapalı ortamlarda da güneş koruyucu kullanmayı ihmal etmeyin.',
                },
                {
                    description: '5. Ve unutmayın ki; akşam güneşi lekelenmenizi daha çok artırır, güneş batana kadar kullanmaya devam edin. Cam, güneşten koruyucu bir alan oluşturmaz. Araba sürerken de güneş koruyucu kullanmayı unutmayın. ',
                },
                {
                    title: 'Yüzdeki Lekeler (Melasma) Nasıl Tedavi Edilir?',
                    description: '<span style="font-weight:400">Leke tedavisi bireysel farklılıklar göstermektedir. Öncelikle lekelerinizin derinliğinin tespit edilmesi gerekir. Lekelerinizin yaygınlığı, daha önce tedavi uygulanıp uygulanmadığı değerlendirilerek tedavi programı oluşturulmaktadır. Leke tedavisi hakkında bilgi almak ve randevu oluşturmak için</span><a href="https://www.drebrukaragun.com/iletisim/"><span style="font-weight:400">tıklayın.</span></a>',
                },
            ]
        },
    },
    {
        blogid: 'dijital-ekranlar-cildi-yaslandiriyor',
        title: "Dijital Ekranlar Cildi Yaşlandırıyor!",
        image: "/img/blog-images/dijital-ekranlar-cildi-yaslandiriyor.jpg",
        date: '11 Ekim 2022',
        content: {
            blogPageTitle: "Dijital Ekranlar Cildi Yaşlandırıyor!",
            blogPageDesc: "Güneş bizim ana ve doğal mavi ışık kaynağımızdır. Güneşten gelen doğal mavi ışık, sirkadiyen ritmi (doğal uyku / uyanıklık döngümüz)...",
            metaDescription: "Telefon, tablet ve bilgisayar ekranlarından yansıyan mavi ışıklar ciltte yaşlanmaya neden oluyor. Ayrıntılı bilgi için tıklayın!",
            blogDetailPageInfo: [
                {
                    description: 'Güneş bizim ana ve doğal mavi ışık kaynağımızdır. Güneşten gelen doğal mavi ışık, sirkadiyen ritmi (doğal uyku / uyanıklık döngümüz) korumak için önemlidir. Görme işlevinin sağlıklı gerçekleşmesi, dikkat ve odaklanma süresinin artması, belleğin doğru çalışması, gün boyu zinde ve uyanık kalmak gün ışığı, yani mavi-turkuaz renk aralığındaki ışık sayesinde mümkün olur.',
                },
                {
                    description: '<b>Ellerinde telefonlara, tabletlere, ekranlara kilitlenip kalan insanların yüzüne yansıyan mavi ışık hiç dikkatinizi çekti mi?</b>',
                },
                {
                    description: 'Işık yayan diyotlardan (LED) Xenon ışıklarına, enerji tasarruflu ampullerden elektronik cihazlara (televizyon, tablet, akıllı telefon, bilgisayar) kadar yeni nesil teknoloji ürünü tüm yapay ışık kaynakları ve dijital ekranlar mavi-mor renkli ışık yayarlar. Bu kaynaklar güneşten çok daha düşük yoğunlukta mavi ışık üretirler fakat bu cihazlara daha uzun süre ve çok daha yakın mesafelerde maruz kalmaktayız. ',
                },
                {
                    title: 'Mavi Işığın Vücuda Etkileri Nelerdir?',
                    description: '1. Gündüzleri teknolojik cihazlardaki mavi-mor ışığa maruziyetimiz dijital göz yorgunluğuna neden olmaktadır.',
                },
                {
                    description: '2. Araştırmalarda insanların mavi ışık yayan dijital cihazları kullandıklarında, normalden daha az sıklıkta göz kırpma eğiliminde oldukları tespit edilmiştir. Bu durum da göz kuruluğuna neden olmaktadır. ',
                },
                {
                    description: '3. Geceleri çok fazla yapay mavi ışığa maruz kalındığında, melatonin (uyku ritmini düzenleyen hormon) üretimi azalarak sirkadiyen ritim bozulur. Bunun neticesinde de uykuya dalmakta zorluk, düşük uyku kalitesi ve gündüz yorgunluğu gibi sorunlar ortaya çıkar. Sirkadiyen sistemin bozulması aynı zamanda diyabet, kardiyovasküler hastalık ve bilişsel işlev bozukluklarının gelişimine neden olmaktadır.',
                },
                {
                    description: '4. Mavi-Mor ışığın cildimize etkisi: Mavi ışık, hem UVA hem de UVB ışığına kıyasla cildin daha alt tabakalarına ulaşarak cildimize de hasar vermektedir. Bilgisayar başında altı saatlik iş günü geçirmek, öğlen güneşinde 20 dakika güneşlenmekle aynı miktarda güneş hasarına maruz bırakmaktadır. Mavi ışık maruziyeti cildimizde lekelenmeye, güneşe hassasiyet gösteren hastalıkların mavi ışık tarafından tetiklenmesine, aynı zamanda fotoyaşlanma (güneş ışınların neden olduğu yaşlanma) ve fotokarsinogeneze (güneş ışınların neden olduğu deri kanserlerine) neden olmaktadır. Mavi ışığın ciltte lekelenmeye neden oluşu 1980’li yılların başında tespit edilmiştir. ',
                },
                {
                    title: 'Mavi Işıktan Nasıl Korunabiliriz?',
                    description: 'Ve bu blog yazısını da mavi ışık yayan bir cihazda okuyorsunuz. Ekranların önünde geçirdiğiniz süre göz önüne alındığında, mavi ışığın hayatımızdaki etkinliğini azaltmak için yapılabileceklerinize gelin birlikte göz atalım.',
                },
                {
                    description: '1. Dijital göz yorgunluğunu azaltmak için mavi ışık engelleyici gözlükler kullanabilirsiniz.',
                },
                {
                    description: '2. Dijital göz yorgunluğunu azaltmak için 20-20-20 kuralını izleyin. Her 20 dakikada bir 20 metre uzaklıktaki bir şeyi görüntülemek için 20 saniyelik bir mola verin.',
                },
                {
                    description: '3. Tüm yüzünüzü korumak için geniş spektrumlu güneş koruyucularınızı mavi ışık maruziyetiniz olduğu süre boyunca dört saatte bir yenileyecek şekilde kullanmalısınız.',
                },
                {
                    description: '4. Akıllı telefon, tablet ve bilgisayarınızın ekranını mavi ışık filtreleri ile kullanabilirsiniz. Filtreler, ekranın görünürlüğünü etkilemeden mavi ışığın çoğunun yüzünüze ve gözlerinize ulaşmasını engeller.',
                },
                {
                    description: '5. Birçok akıllı cihaz aslında mavi ışığı sarı ışık lehine devre dışı bırakan bir ayara sahiptir. “Gece modu” olarak adlandırılan bu ayarı, cildinizi ve gözlerinizi korumak için bütün gün boyunca kullanabilirsiniz.',
                },
                {
                    description: '6. Sirkadiyen ritminizi korumak için mavi ışık maruziyetinizi mümkünse uykuya geçmeden iki saat önce sonlandırın.',
                },
            ]
        },
    },
    {
        blogid: 'yuz-ve-boyun-genclestirmede-mezoterapi-uygulamalari',
        title: "Yüz ve Boyun Gençleştirmede Mezoterapi Uygulamaları",
        image: "/img/blog-images/yuz-ve-boyun-genclestirmede-mezoterapi-uygulamalari.jpg",
        date: '30 Eylül 2022',
        content: {
            blogPageTitle: "Yüz ve Boyun Gençleştirmede Mezoterapi Uygulamaları",
            blogPageDesc: "Cilt içsel faktörler (kronolojik) ve dışsal (extrensek) faktörlerle yaşlanmaktadır. İçsel faktör genetik olmakla birlikte, dışsal faktörler arasında UV ışınları, sigara...",
            metaDescription: "Mezoterapi her yaş grubu için anti-aging uygulamaların her aşamasında önemlidir. Mezoterapi hakkında detaylı bilgi edinmek için hemen tıklayın.",
            blogDetailPageInfo: [
                {
                    description: 'Cilt içsel faktörler (kronolojik) ve dışsal (extrensek) faktörlerle yaşlanmaktadır. İçsel faktör genetik olmakla birlikte, dışsal faktörler arasında UV ışınları, sigara kullanımı, alkol tüketimi, kötü beslenme (paketli gıdalar, trans yağ ve rafine şeker içeren), yetersiz uyku (günde 7-8 saat uyku gereklidir), psikolojik ve fiziksel stres ile çevre kirliliği yer almaktadır. Kronolojik yaşlanma genetik programa bağlı olduğundan sonuçları bireysel farklılıklar gösterir. ',
                },
                {
                    description: 'Kronolojik yaşlanmayla ilgili en çok çalışılan hipotezlerden biri oksidatif bileşenlerin deride artmasıdır. Oksidatif bileşenlerin deride artması, cildimizin gerginliğinini sağlayan kolajen liflerinin, elastikiyetini sağlayan elastin liflerinin, cildin nemini sağlayan hyalüronik asitin yıkımı artırarak yapımını azaltır. Cildimizde gerginliği sağlayan kolajen liflerin zamanla azalması sarkmalara, elastikiyetini sağlayan elastik liflerin azalması ise kırışıklıklara neden olmaktadır. Hyalüronik asidin azalması ise cildi donuklaştırarak daha mat görünüme neden olur. ',
                },
                {
                    description: 'Yüz ve boyun bölgesindeki yaşa bağlı olarak görülebilen değişiklikler farklı şekilde değerlendirilir.',
                },
                {
                    description: 'Yüz için yaş ile gelişen değişiklikler değerlendirme skalası aşağıda belirtilmiştir;',
                },
                {
                    description: '<table><tbody><tr><td><span style="font-weight:400">Tip I</span></td><td><span style="font-weight:400">• Minimal kırışıklık</span><span style="font-weight:400">• Hafif renk tonu düzensizliği </span><span style="font-weight:400">• 20-30 yaş arası hastalar</span></td></tr><tr><td><span style="font-weight:400">Tip II</span></td><td><span style="font-weight:400">• Mimikler ile belirginleşen kırışıklıklar</span><span style="font-weight:400">• Yanaklarda paralel gülümseme çizgileri </span><span style="font-weight:400">• Yüzde kahverengi lekeler oluşmaya başlayabilir (güneş lekeleri)</span><span style="font-weight:400">• 30 ila 40 yaş arası hastalar</span></td></tr><tr><td><span style="font-weight:400">Tip III</span></td><td><span style="font-weight:400">• Statik kırışıklıklar (yüz hareket etmediğinde bile olan kırışıklıklar)</span><span style="font-weight:400">• Yaşlılık lekeleri olarak adlandırılan kahverengi lekelerin büyümesi ve deriden kabarması</span><span style="font-weight:400">• 50 ila 60 yaş arası hastalar</span></td></tr><tr><td><span style="font-weight:400">Tip IV</span></td><td><span style="font-weight:400">Tüm yüz boyunca kırışıklıklar</span></td></tr></tbody></table>',
                },
                {
                    description: 'İdeal genç boyun ise cilt tonusunun iyi olduğu, belirgin çene hattının olduğu ve çene altında yağ dokusunun olmadığı boyundur.',
                },
                {
                    title: 'Yüz ve Boyun Gençleştirme için Mezoterapiye Kaç Yaşında Başlanmalıdır?',
                    description: 'Yüz ve boyun mezoterapisinde amaç cilt doku mimarisini oluşturan fibroblast hücrelerinin uyarılması ile kolajen elastin liflerinin ve hyalüronik asit üretiminin artırılarak, cildin neminin, parlaklığının ve sıkılığının artırılması hedeflenir.',
                },
                {
                    description: 'Mezoterapi uygulamalarının 20’li yaşların başında başlanması önemlidir çünkü 20’li yaşlarda kolajen ve elastin liflerimizin her yıl %1,5’ini kaybederiz. 20’li yaşlarda sadece mezoterapi uygulanması cilt altını yapılandırmak için yeterlidir. Fakat 30’lu yaşların ortalarından sonra artık mezoterapi uygulamaları diğer cihaz işlemleri ile kombine edilmelidir. ',
                },
                {
                    description: 'Mezoterapi her yaş grubu için anti-aging uygulamaların her aşamasında önemlidir.',
                },
                {
                    description: '- Hafif elastikiyet ve parlaklık kaybı hafif-orta (Yüz için değerlendirme skalasındaki Tip I-II),',
                },
                {
                    description: '- Tip III-IV skalasına sahip bireylerde cihaz işlemleriyle kombine edilerek uygulanır,',
                },
                {
                    description: '- Her yaş döneminde mezoterapinin cilde iyileştirici etkinliği mevcuttur,',
                },
                {
                    description: '- Donuk, cansız, yorgun görünümlü ciltlerde,',
                },
                {
                    description: '- Nemsiz ciltlerde,',
                },
                {
                    description: '- Sigara içenler, yetersiz uyku, düzensiz beslenen bireyler ve hava kirliliğine maruz kalan ve güneş hasarlı cilde sahip olanlar,',
                },
                {
                    description: '- Hafif akneli kişiler.',
                },
                {
                    title: 'Mezoterapide Kullanılan Ürünler Nelerdir?',
                    description: 'Cilt gençleştirme amacıyla kullanılan çok sayıda mezoterapi ürünü vardır. Mezoterapi ürünlerinin bileşimlerinde farklılıklar olsa da, ürünlerin çoğu aynı genel içerik sınıfına sahiptir. ',
                },
                {
                    description: 'Mezoterapi ürünleri içerisinde esas olarak vitaminler, mineraller, aminoasitler, ko-enzimler, antioksidan ürünler, eser elementler, glutatyon ve hyalüronik asit bulunmaktadır. Mezoterapi ürünleri kişinin ciltteki sorunları, yaşı değerlendirilerek kişiye özel mezoterapi ürünleri karıştırılarak bir kokteyl üründe oluşturulabilmektedir.',
                },
                {
                    title: 'Vitamin ve Türevlerinin Cilt Üzerinde Etkisi',
                    description: 'Vitaminler, cilt yaşlanma süreciyle ilgili önemli işlevlere sahiptirler. Antioksidan fonksiyonları mevcuttur. Proteinli ürünlerin sentezinde önemlidir. Bu nedenle kolajen ve elastin liflerinin sentezi için önemli moleküllerdir.',
                },
                {
                    title: 'C vitamini (askorbik asit)',
                    description: 'Kolajen sentezi için gerekli olan önemli bir antioksidandır.',
                },
                {
                    title: 'A vitamini (retinol)',
                    description: 'Ciltteki hücre yenilenmesini artırır, lekelenmeye neden olan hücrelerin fonksiyonlarını düzenler ve aynı zamanda yağ bezi aktivitesini kontrol eden antioksidan etkileri olan bir başka önemli vitamindir.',
                },
                {
                    title: 'E vitamini (tokoferol)',
                    description: 'Yüksek düzeyde antioksidan aktivitesi olan diğer önemli bir bileşendir. ',
                },
                {
                    title: 'B vitamini türevleri',
                    description: 'B vitaminleri temel olarak koenzimler olarak işlev gören birçok hücre fonksiyonunun kontrolünde rol oynarlar.',
                },
                {
                    title: 'Antioksidan Takviyelerinin Kullanımı',
                    description: 'Yüksek doz antioksidan takviyeleri bazı durumlarda zararlı olabilir. Örneğin, bazı çalışmaların sonuçları, yüksek doz beta-karoten takviyelerinin kullanımının, sigara içenlerde akciğer kanseri riskinin artmasına, yüksek doz E vitamini takviyelerinin kullanılmasının antikoagülan ilaçlar (“kan sulandırıcılar”) alan kişilerde kanama riskini artırmasına ve hemorajik inme (beyindeki kanamanın neden olduğu bir tür inme) ve prostat kanseri risklerinin artmasına neden olabileceğini göstermiştir.',
                },
                {
                    title: 'K vitamini',
                    description: 'Kan dolaşımın düzenlenmesinde önemli rol alır.',
                },
                {
                    title: 'Amino asitler ve ilgili bileşikler',
                    description: 'Bunlar esas olarak kolajenler olmak üzere derideki protein içeren bileşenlerin sentezi temel yapıtaşlarıdır. ',
                },
                {
                    description: '<b>Mineraller:</b> Kalsiyum, fosfor hücre döngüsünün sağlanması için gereklidir. Magnezyum çok sayıda enzimatik reaksiyon (180’den fazla) için gerekli önemli bir mineraldir.',
                },
                {
                    description: '<b>Hyaluronik asit (HA):</b> Deride, hücreler arası aralığı destekler, su tutucu özelliğinden dolayı deriyi nemlendirir ve ayrıca hücrelerin normal fonksiyonlarını desteklemek için bir çatı oluşturur.  HA hacminin 1000 katı kadar su bağlama kapasitesi vardır. Mezoterapi solüsyonları içerisindeki HA ürünleri cildin neminin kazandırılmasında önemlidir.',
                },
                {
                    title: 'Mezoterapinin Uygulama Sıklığı Nasıl Olmalıdır?',
                    description: 'Yüz ve dekolte bölgesindeki mezoterapi uygulamaları iki hafta aralıklarla toplam üç seans şeklinde planlanır. Sonrasında 2-3 ay aralıklarla uygulanan seanslarla devam tedavisi şeklinde düzenlenir. ',
                },
                {
                    title: 'Mezoterapi sonrası hastanın dikkat etmesi gereken durumlar:',
                    description: 'Uygulama sonrası en az 8 saat boyunca yüz ve boyun bölgesini yıkamamaları, terlememeleri ve spor yapmamaları önerilmelidir. Hastalar ayrıca en az 72 saat güneşlenmemeli ve soyucu işlemlerden (ev tipi peelingler bile olsa) kaçınmaları gerekmektedir.',
                },
                {
                    title: 'Sonuç Olarak:',
                    description: '- Cilt yaşlanmasını engelleyerek daha genç bir görünüme sahip olmak için artan talep ameliyat dışı kozmetik uygulamalara ilgiyi artırmıştır. Mezoterapi, cilt yaşlanmasında (intrensek ve extrensek yaşlanmada) tedavi olarak yaygın ve başarılı bir şekilde kullanılmaktadır.',
                },
                {
                    description: '- Mezoterapinin temel amacı, ciltte yaşlanma sürecini artıran, cildi donuklaştırarak daha mat daha cansız görünümüne neden olan, nemi azalarak kuru cilt ile sonuçlanan süreçlerinin başlıca nedeni olan cilt altındaki oksidasyonu azaltır. Cilt doku mimarisini oluşturan fibroblast hücrelerini uyararak bu hücrelerden kolajen ve elastin liflerini ve cildin nemini sağlayan hyalüronik asit üretimini artırır.',
                },
                {
                    description: '- Tedavi etkinliğinin olması için mezoterapiye 20’li yaşlarda başlanması, düzenli aralıklarda tedaviye devam edilmesi önemlidir. 30’lu yaşların ortalarından sonra cihaz işlemleri ile kombine edilebilir.',
                },
                {
                    description: '- Mezoterapinin “hızlı bir çözüm” olmadığı bilinmeli ve istenen etkiyi elde etmek için tedavilerin düzenli aralıklarla yapılması gerekliliği bilinmelidir. Mezoterapi uygulamaları kişinin cildine yaptığı bir yatırımdır ve bu şekilde cilt altı dokusu korunarak yaşlanma süreci yavaşlatılmaktadır. ',
                },
            ]
        },
    },
    {
        blogid: 'derin-ve-ince-kirisikliklar-nasil-tedavi-edilir',
        title: "Derin Ve İnce Kırışıklıklar Nasıl Tedavi Edilir?",
        image: "/img/blog-images/derin-ve-ince-kirisikliklar-nasil-tedavi-edilir.jpg",
        date: '23 Eylül 2022',
        content: {
            blogPageTitle: "Derin Ve İnce Kırışıklıklar Nasıl Tedavi Edilir?",
            blogPageDesc: "Derin kırışıklıklar sadece yaşlanmanın değil aynı zamanda güneşe maruz kalma, sigara ve alkol tüketimi gibi çevresel faktörlerin de bir sonucudur....",
            metaDescription: "Kırışıklıkların azalmasını ya da oluşmasını azaltmak için evde yapılabilecekler ve dermatolog eşliğinde yapılabilecek işlemleri öğrenmek için hemen tıklayın.",
            blogDetailPageInfo: [
                {
                    description: 'Derin kırışıklıklar sadece yaşlanmanın değil aynı zamanda güneşe maruz kalma, sigara ve alkol tüketimi gibi çevresel faktörlerin de bir sonucudur. Cildin doğal elastikiyeti yaşla birlikte azalır ve bu da ciltte hacim kaybına neden olur. İnce çizgilere tekrarlanan yüz ifadeleri, güneşe maruz kalma ve kuruluk neden olur. Peki kırışıklıklarla nasıl başa çıkabilirsiniz? Ya da sorunun temeline inmek için daha detaylı bilmek gerekirse kırışıklıklar nasıl oluşur? Yazının devamında kırışıklıklar ve kırışıklıkların azalmasını ya da oluşmasını azaltmak için neler yapabileceğimizden bahsettik, keyifli okumalar.',
                },
                {
                    title: 'Kırışıklıklar Nasıl Oluşur?',
                    description: 'Kırışıklıklar yaşlanma sürecinin doğal bir parçasıdır. Cildimiz yaş alma sürecinde doğal olarak elastikiyetini kaybeder, daha kuru hale gelir ve artık kırışıklıklar kaçınılmaz olur.',
                },
                {
                    description: 'Tekrarlayan kas hareketleri de kırışıklıkların oluşumu için bir nedendir. Gülümseme, kaş çatma ve gözlerini kısma gibi tekrarlanan yüz hareketleri üst yüz bölgemizde oluşan kırışıklıklarımızın en önemli nedenidir. Yıllar içerisinde alt yüz bölgemizde dudak üzerinde ince çizgilerimiz, yanaklarda paralel çizgilerimiz, boyun bölgesinde ve el üzerinde ise kırışıklıklarımız ortaya çıkmaktadır.',
                },
                {
                    description: 'Kırışıklıkların gelişimini yaşam tarzımız da etkileyebilir. Eğer sigara içiyorsanız dudak üzerinde çizgileriniz, uyku pozisyonunuza bağlı yanaklar ve göz kenarlarındaki çizgileriniz, güneş hasarınız fazla ve güneş koruyucu kullanmıyorsanız cildinizde derin kırışıklıklarınızın oluşması kaçınılmazdır. Sigara içiyorsanız, çok alkol tüketiyorsanız, yetersiz uyuyorsanız, dengesiz (trans yağ içeren ve rafine şekerler bulunduran diyetiniz varsa) besleniyorsanız, stresli yaşantınız varsa, spor yapmıyorsanız ve yaz kış demeden güneş koruyucu kullanmıyorsanız kırışıklıkların oluşmasına davetiye çıkarıyorsunuz demektir. ',
                },
                {
                    description: 'Tabii ki değişmeyen gerçek genetik faktörlerin de etkisi yadsınamaz. Eğer ailenizde kırışarak yaşlanan bireyler fazla ise ve ince kuru cilt yapısına sahipseniz, kırışarak yaş alma süreciniz olma ihtimali yüksektir. Eğer sizler de yüzünüzdeki kırışıklıklardan rahatsız iseniz ve kendinizi geç kalmış hissediyorsanız her zaman “hayatın yakaladığımız yerden başladığını” hatırlayın lütfen.',
                },
                {
                    title: 'Kırışıklıklar İçin Evde Yapılabilecekler',
                },
                {
                    title: '1. Retinoidler (A Vitamini)',
                    description: 'Yaşlanma karşıtı bileşenler söz konusu olduğunda evde kullanacağınız ürünlerde retinoidler (A vitamini) altın standarttır. Retinoidler hücre döngüsünü uyararak, kolajen ve elastin üretimini artırarak ve kırışıklıkların görünümünü azaltarak etkinlik gösterirler. Ayrıca, retinoidler yaşlılık lekelerini (senil lentigo) ve çilleri azaltır, güneş hasarını tersine çevirir ve cilt dokusunun yenilenme sürecini artırarak daha parlak, daha genç görünen bir cilt elde edilmesini sağlar. ',
                },
                {
                    title: 'Retinoidler (A Vitamini) Nasıl Kullanılır? ',
                    description: 'Retinoidlerin etkileri çok yönlüdür. Ancak burada bir uyarıda bulunmakta fayda var. Retinoidler son derece güçlüdürler (!!!). Özellikle yanlış kullanıldıklarında tahrişe, kuruluğa, kızarıklığa ve ciltte ciddi soyulmalara neden olabilirler. Retinoidler başlangıçta haftada sadece iki ila üç kez kullanılmalıdır. Hatta çok açık tenli iseniz haftada bir kez kullanımla bile başlanabilir. Kullanım sürecinde cildinizin toleransına göre kademeli olarak kullanılan gün sayısı artırılarak her gün kullanıma geçilebilir. Kullanacağınız retinoidli kreminizi bir nemlendirici ile karıştırarak uygularsanız, yan etki ihtimalini azaltmış olursunuz. Ve her zaman retinoidli kremleri gece kullanmalısınız ve gündüzleri iyi bir nemlendirici ile devam etmelisiniz. Retinoidli krem kullanıyorsanız, mutlaka güneş kremi de kullanmalısınız çünkü retinoidler cildinizi güneşe karşı daha hassas hale getirir.',
                },
                {
                    description: '<b>Kullanım süresi:</b> Kış dönemi boyunca kullanımı tavsiye edilmektedir. Etkinlik konusunda beklentiniz hemen olmasın. Yapım sürecini uyardığından dolayı etkinliği de 3-4 ay sonra ortaya çıkacaktır.',
                },
                {
                    description: '<b>Uyarı:</b> Retinoid kullanımına başlamadan önce mutlaka dermatoloğunuzdan bilgi alın.',
                },
                {
                    title: '2. C Vitamini (Askorbik Asit)',
                    description: 'Yaşlanma karşıtı durumlar söz konusu olduğunda evde kullanacağınız bir başka ürün de C vitaminidir (askorbik asit). C Vitamini, kolajen ve elastin üretimini artıran güçlü bir antioksidandır ve bu şekilde kırışıklıkların görünümünü azaltır. C vitamini cildinizi güneş hasarından korumakta harika etkinlik gösterir. Özellikle yaz döneminde güneş koruyucunuzun altına sürmeniz, güneşin yan etkilerinden korunmanızı sağlayacaktır. Ayrıca güneş lekelerinizi, genişlemiş gözeneklerinizi de azaltır ve cildinize daha parlak bir görünüm sağlar.',
                },
                {
                    title: 'C Vitamini Alırken ve Kullanırken Nelere Dikkat Etmelisiniz?',
                    description: 'C vitaminin önemli ölçüde etkinliği olması için kontrasyonun %8’den (aldığınız ürünün içeriğinde belirtilir) yüksek olması gerekir. En etkili olduğu yoğunluk (konsantrasyon) oranı %15 askorbik asit içeren ürünlerdir. Eğer cildiniz kuru ise ve hassas bir cildiniz varsa, kullanmaya yavaş yavaş başlayın (haftada 2-3 gün) ve cildinizin toleransı arttıkça kullanımınızı kademeli olarak artırın. C vitamini konsantrasyonu %20’den fazla ise ciltte rahatsızlık hissi verir ve C vitamini etkinliği %20 konsantrasyondan sonra plato çizer. Fakat bu durum ciltteki kuruluk hissini artırır. ',
                },
                {
                    description: 'C vitamini ürününün oksidasyonu önlemek için opak veya koyu renkli bir cam şişede olduğundan emin olun. Havaya ve ışığa maruz kalındığında oksitlendiği için C vitaminini ışıktan uzak bir yerde saklayın. C vitamini oksitlendiğinde sarı ya da kahverengi olur. O yüzden kullanırken serumunuzun açık sarı ya da renksiz olduğundan emin olun. ',
                },
                {
                    description: '<b>Kullanımı:</b> Sabah akşam kullanılabilir. Özellikle sabahları güneş koruyucunuzun altına uygulayabilirsiniz. ',
                },
                {
                    title: 'Dermatolog Eşliğinde Kırışıklık Tedavisi'
                },
                {
                    title: '1. Mezoterapi',
                    description: 'Mezoterapi uygulamalarının kırışıklık oluşumunun önlenmesindeki etkinliği, kırışıklık tedavisine göre çok daha fazladır. Mezoterapi uygulamaları ile hücresel aktivitenin yenilenmesi ve aktivasyonu ile fibroblastların (cilt doku mimarisini oluşturan kolajen ve elastin sentezinin yapıldığı hücre) sentez kapasitelerinin arttırılması, yeni kolajen, elastin ve hyalüronik asit üretimi ile derinin neminin, parlaklığının ve sıkılığının artırılması hedeflenir. Cildimiz 20’li yaşlarda her yıl kolajen ve elastinin %1,5’ini kaybeder. Bu kayıba bağlı gelişen kırışıklıklarımızı bizler 30’lu yaşların ortalarından itibaren fark etmeye başlarız. Mezoterapilerin içeriğinde olan vitaminler, mineraller, aminoasitler, antioksidanlar, ko-enzimler kolajen ve elastin üretiminin esas maddeleridir. 20’li yaşlarda başlanan mezoterapiler ile  kolajen ve elastinin kayıp süreci büyük ölçüde azaltılır ve bizler yıllar içinde cildimize iyi bir yatırım yaparak, kırışıklıklarımızın oluşma sürecini büyük ölçüde yavaşlatırız. Özellikle sigara içiyorsanız, yetersiz uyuyorsanız, dengesiz (trans yağ içeren ve rafine şekerler bulunduran diyetiniz varsa) besleniyorsanız, stresli yaşantınız mevcutsa mezoterapi ile bu olumsuz yaşam tarzınızın cildinize olan etkilerini de azaltmış olursunuz.',
                },
                {
                    title: '2. Dolgu Uygulamaları',
                    description: 'Dolgu ürünleri, kırışıklıklarınızı yumuşatmak için cilde enjekte edilen jel benzeri maddelerdir. Yüzdeki hacim kaybının neden olduğu kırışıklıklar için dolgu ürünleri harikadır. Daha genç bir görünüm için kaybolan hacmi geri kazandırır, cildi nemlendirir ve yüzü dolgunlaştırır. Dermal dolgu maddeleri, kolajen, elastikiyet ve yağ kaybının yanı sıra yerçekimi ve güneş hasarından kaynaklanan statik kırışıklıkları (mimik hareketleri ile oluşmayan) düzeltmek için de kullanılmaktadır. Dolgu uygulamaları özellikle alt yüz bölgesindeki kırışıklar için ideal çözümdür. ',
                },
                {
                    title: '3. Scarlet X',
                    description: 'Tüm cilt katmanlarında problemleri aynı anda tedavi edebilen bu sistem, özel tasarlanmış mikro iğneler ile cilt altını radyofrekans dalgalarıyla cilt doku mimarisini oluşturan fibroblast hücreleri uyarılır. Uygulamadan sonra cildiniz yeni kolajen ve elastin üretmeye başlayacaktır. Bu şekilde daha sıkı ve elastikiyeti artmış bir cilde sahip olacaksınız.',
                },
                {
                    description: 'Scarlet X yöntemi ince çizgiler ve kırışıklık tedavisinde kullanılan etkili bir yöntemdir. Cilt altı dokusunu uyardığından ve yeni sentez sürecini de artırdığından kırışıklık oluşumunu da engelleyecektir. Hem kırışıklıkları azaltıcı etkinliği hem de önleyici etkinliği ile kırışıklık tedavisinde çifte etkinlik sağlar. Radyofrekans teknolojisi, derinin üst tabakasına zarar vermeden yalnızca deri altı dokusunu hedeflediğinden, her mevsim uygulanabilir. Uygulama sonrası 1-2 saat sonra geçen hafif bir kızarıklık oluşabilir. Ancak kişi sosyal yaşamına hemen geri dönebilir. İşlem sonrasında cildin iyice nemlendirilmesi ve güneş koruyucu kullanılması yeterlidir. Radyofrekans teknolojisine sahip çok çeşitli cihazlar mevcuttur. Scarlet X, FDA onayı olan radyofrekans teknolojisinin en üst teknolojisi ile üretilmiş bir cihazdır. ',
                },
                {
                    title: '4. İp Uygulamaları (Örümcek ağı)',
                    description: 'Örümcek ağı estetiği uygulamasında, cilt yapısı ile biyouyumlu tamamen eriyebilen ipler, cilt altına ince uçlu iğneler ile yerleştirilir. Uygulanan bu ipler cilt alt dokusuna tıpkı örümcek ağı gibi işlenir. Örümcek ağı estetiği temelinde, azalan kolajenin ve elastinin üretimini artırarak cilt kalitesini artırmak hedeflenir. Böylelikle artan cilt kalitesi ile yeni kırışıklık oluşumu azalırken, oluşmuş olan kırışıkların azaltılması da sağlanır ve bu şekilde çifte etkinlik olur. Örümcek ağı uygulamasının özellikle 30-45 yaş arasındaki kişilerde etkinliği çok daha iyidir. 20’li yaşlarda olup daha sıkı ve daha gergin cilde sahip olmak isteyenler için de çok uygun bir yöntemdir. Örümcek ağı estetiği, kişiyi sosyal hayattan koparmayan ve kısa sürede uygulanan bir uygulamadır. Örümcek ağı estetiği uygulamasından hemen sonra canlanmaya başlayan ciltteki asıl etki 3. aydan sonra görülecektir.',
                },
                {
                    title: '5. Lazer Tedavileri',
                    description: 'Kırışıklıkların azaltılması ve ince çizgilerin tedavi edilmesinde lazer tedavileri de etkilidir. Bu tedavilerin sadece kış döneminde yapılması, tedavi sonrasında geçici kızarıklığın uzun sürmesi ve sonrasında 7-10 gün kadar yüzde soyulma ve kabuklanmanın olması, radyofrekans teknolojisine göre dezavantajlarıdır.',
                },
                {
                    title: '6. Kimyasal Peelingler',
                    description: 'Kimyasal peeling, yüzünüzdeki cilt dokusunu uyararak kolajen ve elastin üretimi artırır, cildinizi pürüzsüzleştirerek ince kırışıklıklarınızı azaltır. Kimyasal peeling uygulamasından önce kırışıklarınızın derinliği değerlendirilir ve sonrasında tedavi protokolünüz oluşturulur. Yüzeysel, orta ve derin peeling uygulamaları mevcuttur. Bu uygulamalarda kullanılan ürünler ve bu ürünlerin konsantrasyonları birbirinden farklıdır. Kimyasal peeling sonrasında uygulanan ürüne ve uygulanan ürünün konsantrasyonuna göre değişkenlik gösteren kızarıklığınız ve ciltte soyulmalarınız olacaktır. Bu durum peelingin derinliği arttıkça daha da belirgin olacaktır. Bu nedenle sadece kış döneminde uygulanması gerekir. Derin kimyasal peeling uygulamalarından sonra 7-10 gün evde kalmanız doktorunuz tarafından önerilebilir.',
                },
                {
                    description: 'Derin ve ince kırışıklıkların nasıl tedavi edildiğine dair bir çok yöntemle ilgili kapsamlı bir bilgi aktarımı yapmaya çalıştım. Konu ile ilgili kafanıza takılan bir durum olduğunda ya da randevu oluşturmak isterseniz web sitemizde yer alan telefon numaralarından bize ulaşabilirsiniz.',
                },
            ]
        },
    },
    {
        blogid: 'dolgu-uygulamalari-yuzu-sisirir-ya-da-genisletir-mi',
        title: "Dolgu Uygulamaları Yüzü Şişirir ya da Genişletir mi?",
        image: "/img/blog-images/dolgu-uygulamalari-yuzu-sisirir-ya-da-genisletir-mi.jpg",
        date: '16 Eylül 2022',
        content: {
            blogPageTitle: "Dolgu Uygulamaları Yüzü Şişirir ya da Genişletir mi?",
            blogPageDesc: "Sincap yanaklar, sosis dudaklar veya uzamış bir çene çoğu kişi için istenmeyen sonuçlardır. Tanıdığınız bir kişinin doğal olmayan bir şekilde dolgun...",
            metaDescription: "Yetkin olmayan kişiler tarafından yapılan dolgu uygulaması yan etkilere neden olmaktadır. Dolgu uygulamaları hakkında detaylı bilgi almak için tıklayın.",
            blogDetailPageInfo: [
                {
                    description: 'Sincap yanaklar, sosis dudaklar veya uzamış bir çene çoğu kişi için istenmeyen sonuçlardır. ',
                },
                {
                    description: 'Tanıdığınız bir kişinin doğal olmayan bir şekilde dolgun ve bazen de tanınmayan bir yüze dönüştüğünü görmek rahatsız edicidir. Dermal dolgu maddeleri, yaşlanma belirtileriyle mücadele etmenin ve yüzünüze hacim kazandırmanın harika bir yolu olsa da, yüzün doğal görünmesini sağlamak için kullanılacak doğru miktarını bilen profesyonel bir uzmanın uygulaması önemlidir. Yetkin olmayan kişiler tarafından yapılan dolgu uygulaması cildi germek için çok fazla dolgu maddesi uygulamanın doğru olduğunu düşünmekteler. Ne yazık ki, iyi bir şeyin çok fazlası kötü olacaktır. Sonuç, tipik sincap yanaklar, sosis dudaklar veya anormal uzamış bir çene görünümüdür. Çok fazla dolgu maddesi uygulamaları hem genç bir insanın tuhaf görünmesine hem de daha yaşlı görünmesine  neden olacaktır.',
                },
                {
                    description: 'Bu durum, yüz dolgu uygulaması düşünen kişiler için de gerçek bir endişe kaynağı oluşturur ve bu kişiler dolgu uygulamalardan kaçınırlar.',
                },
                {
                    description: 'Yapılan yanlış teknikle, uygun olmayan dolgu seçenekleriyle, uygulama hatasından dolayı; yanakların aşırı doldurulması, yüzün doğal kontur dengesini bozabilir, gözlerin şeklini etkileyebilir. Madonna, Nicole Kidman ve Janice Dickison gibi ünlülerde bile şaşırtıcı sonuçları görmekteyiz.',
                },
                {
                    description: 'Dolgu maddeleri seçilirken ve uygulanırken hastanın yüzünün 3 boyutlu yapısına uygun, estetik alanların harmonisini bozmayacak şekil ve biçim seçilerek tedavi planlanır.',
                },
                {
                    description: 'Yaş ile birlikte yüzümüz hacim kaybeder yanaklar düzleşir, şakaklar çöker, dudaklar incelir içe döner, çene geriye doğru çekilir, ağız köşesinden çene sınırına uzanan oluklanma belirginleşir, çene hattının belirginliği azalır. Bütün bu değişimlere karşı koymak için hacmi artırmak için dolgu uygulamaları yapılır. Enjeksiyonlar düzgün bir şekilde gerçekleştirildiğinde, olağanüstü güzel sonuçlar alınır. Doktorunuzun doğru teknik, bilgi ve deneyimi yanı sıra estetik anlayışı ile uygulandığında hacim kaybı olan alanların geriye kazandırılması mükemmel bir şekilde sağlanacaktır. Yüzde herhangi bir genişleme durumu oluşmayacaktır.',
                },
                {
                    description: '20’li yaşlarda kozmetik amaçla yapılan yanak dolgusu- dudak dolgusu-burun dolgusu- çene hattının belirginleştirilmesi (jawline dolgu) doğru teknik, uygulayıcının deneyimi ve uygun dolgu seçimi ile yapılırsa herhangi bir abartılı görünüm olmadan kişiyi güzel-çekici gösterecek estetik harmoniye ulaşacaktır.',
                },
                {
                    description: 'Dolgu uygulamalarında; kullanılan ürün, uygulanacak teknik, kişinin bu uygulamalardaki deneyimi önemlidir. Ve uygulama öncesinde kişinin yüzünün anatomik yapısı  değerlendirerek en uygun uygulamanın ne olacağına karar verilmelidir. ',
                },
                {
                    description: 'Sincap yanaklar, sosis dudaklar veya anormal bir çene olağan bir durum değildir, uygulama hatalarındadır. ',
                },
            ]
        },
    },
    {
        blogid: 'yaslanmaya-bagli-yuzde-meydana-gelen-degisimler',
        title: "Yaşlanmaya Bağlı Yüzde Meydana Gelen Değişimler",
        image: "/img/blog-images/yaslanmaya-bagli-yuzde-meydana-gelen-degisimler.jpg",
        date: '13 Eylül 2022',
        content: {
            blogPageTitle: "Yaşlanmaya Bağlı Yüzde Meydana Gelen Değişimler",
            blogPageDesc: "Cilt yaşlanmaya bağlı değişimleri en belirgin olarak gösteren kompleks ve dinamik bir organdır. Cildimizin yaşlanması doğum ile beraber başlar ve...",
            metaDescription: "Yaş alma sürecinde yüzünüzde meydana gelen değişimler hakkında detaylı bilgi öğrenmek ve yaşınıza göre yüzünüzde oluşacak belirtileri öğrenmek için hemen tıklayın.",
            blogDetailPageInfo: [
                {
                    description: 'Cilt yaşlanmaya bağlı değişimleri en belirgin olarak gösteren kompleks ve dinamik bir organdır. Cildimizin yaşlanması doğum ile beraber başlar ve 20 yaşından itibaren cildimizde yaşlanmaya ait belirtiler ortaya çıkar.',
                },
                {
                    description: 'İlk olarak, yüzümüzün nelerden oluştuğuna bir göz atalım. Temel olarak yüzümüz; cilt, cilt altı yüzeyi ve derin yağ pedleri, ligamentler, kas ve kemikten oluşur ve yaşlandıkça bu alanların hepsinde hacim kaybederiz ve bu da yaşlanmanın kaçınılmaz görünür belirtilerini ortaya çıkarır. Hacim kaybı yerçekimi ile birleştiğinde yaşlanmanın tipik görünümü ortaya çıkar. VE deri; altındaki tüm yaşlanmaya ait değişiklikleri bir kumaş gibi yansıtır.',
                },
                {
                    title: 'Cilt Dokusunun Kırışması ve Sarkması',
                    description: 'Cildimizde ise 20’li yaşlardan itibaren kolajen-elastin üretimi azalır ve yıkım hızı artmaya başlar ve biz her yıl kollajen ve elastinimizin %1,5’unu kaybederiz. Bu kaybı 20’li-30’lu yaşların başında pek fark etmeyebiliriz, 30’lu yaşların ikinci yarısından sonra artık fark etmeye başlarız. Kollajen sentezini artıracak uygulamalar yapılmazsa 70 yaşına kadar kollajen- elastin liflerinde % 80 oranında bir azalma olur. Kolajen kaybı yaşlanma sürecinde önemli bir faktördür. Kolajen kaybına ayrıca cildin elastikiyetini sağlayan (elastin) ve nemini sağlayan (hyalüronik asit) kaybıda eklenince cilt: daha kuru, daha az esnek, daha ince hale gelir ve cilt kırışıklığı ya da sarkma olasılığı daha da yükselir.',
                },
                {
                    title: 'Yüz Kaslarındaki Değişimler',
                    description: 'Bu faktör yüz yaşlanması sürecini büyük ölçüde etkiler. Yüz kasları tonusunu kaybettiğinde, yaşlanma belirtileri daha belirgin hale gelir. Yüzümüzdeki ve boynumuzdaki cildimiz doğrudan kaslara bağlıdır. Yıllar geçtikçe kas tonusu yavaş yavaş azalır ve tüm yüz sarkmaya başlar. Kas tonusunu zamanla kaybetmesinin yanı sıra, yüz ifadesi için yüz kaslarının sürekli kullanımı cildimizin yüzeyinde daha derin çizgilerin oluşmasına neden olur.',
                },
                {
                    title: 'Yağ Dokusunun Azalması',
                    description: 'Özellikle şakaklarda, alında ve elmacık kemiklerinin üzerinde yağ dokusu azalmaya başlar. Yüzümüzü yukarı tutan derin yağ pedlerimiz küçülür, yüzeyel yağ pedlerimiz ise yerçekimi etkisiyle hareket eder ve alt yüz bölgemizde jawline hattının orta kısmında birikerek yaşlanmanın başlangıcını daha da görünür hale getirir. Zayıfladıkça yüzümüzdeki sarkmaların nedeni, kilo verme sürecinde yüzümüzdeki yağ pedlerinde küçülmesidir.',
                },
                {
                    title: 'Kemik Dokusundaki Değişimler',
                    description: '35 yaşın üzerinde kemik yapısında değişikler oluşmaya başlar, göz altı oyuklarına, düzleşmiş yanaklara ve çene kemiğinin geriye çekilmesine yol açar. Bütün bu değişiklikler cildimize giderek azalan kemik desteğine neden olur. Dokularımız altta yatan kemikleri için çok büyük hale gelir ve kırışıklıklar ve kıvrımlar daha da belirginleşir. Kemik desteği azalan cildimiz yerçekiminin etkisiyle ve kollajen liflerin azalmasıyla alt yüz bölgesinde belirginleşmeye başlar ve zamanla yüzlerimiz bir bebeğin yüzünün oranlarına benzemeye başlar. Bebeklerin yanakları ve çeneleri sarkık olmasının nedenini hiç merak ettiniz mi? Bunun nedeni, yüz kemiklerine göre çok fazla cilt ve yağ dokusuna sahip olmalarıdır. Kemik desteği azaldıkça ilerleyen yaşlarda aynı durum ortaya çıkar. Çocuklarda, kemik yapısı giderek gelişir ve oranlar 20’li yaşlarda optimal olur. Ama maalesef 35 yaşından sonra ise kemik yapımızı kaybetmeye başlarız.',
                },
                {
                    description: 'Bazı insanlarda ise kıkırdak büyümesine bağlı olarak kulakları uzayabilir. Özellikle erkekler kulaklarında yaşlandıkça daha uzun, daha kaba ve daha belirgin hale geldiğini fark edebilirler.',
                },
                {
                    description: 'Her şey o kadar adaletsiz görünüyor ki, yüzümüzün tüm temel bileşenleri ile bizi yaşlı göstermek için bize karşı komplo kuruyor.',
                },
                {
                    description: 'Doğru zamanda doğru uygulamalarla yaşlanma sürecinin belirtileri azalatılarak yaşınızın en iyisi olabilirsiniz. Önemli olan cildin kalitesini koruyarak cildi yapılandırarak ilerlemektir.',
                },
                {
                    description: 'Yaşlanmaya bağlı yüz bölgesinde meydana gelen değişimleri geciktirmek ve daha fazla bilgi edinmek için buradan iletişime geçebilir ve randevu alabilirsiniz.',
                },
            ]
        },
    },
    {
        blogid: '10-adimda-parlak-bir-cilde-sahip-olmanin-ipuclari',
        title: "10 Adımda Parlak Bir Cilde Sahip Olmanın İpuçları",
        image: "/img/blog-images/10-adimda-parlak-bir-cilde-sahip-olmanin-ipuclari.jpg",
        date: '10 Eylül 2022',
        content: {
            blogPageTitle: "10 Adımda Parlak Bir Cilde Sahip Olmanın İpuçları",
            blogPageDesc: "Gerçek: ışıltılı cildin cazibesi asla geçmez. Ancak ışıltılı bir yüzü korumak her zaman kolay değildir ve gerçek şu ki, cildinizdeki...",
            metaDescription: "Donuk cilt nedeniyle parıltınızı kaybettiğinizi hissediyorsanız sizler için derlediğimiz 10 Adımda Parlak Bir Cilde Sahip Olmanın İpuçları adlı blog yazımızı okumak için tıklayın.",
            blogDetailPageInfo: [
                {
                    description: 'Gerçek: ışıltılı cildin cazibesi asla geçmez. Ancak ışıltılı bir yüzü korumak her zaman kolay değildir ve gerçek şu ki, cildinizdeki ışıltının azalmasının sebepleri vardır.',
                },
                {
                    description: 'Donuk cilt nedeniyle parıltınızı kaybettiğinizi hissediyorsanız, muhtemelen aşağıdaki nedenlerden biri sorumludur.',
                },
                {
                    title: '1. Gün içinde yeterli miktarda su için',
                    description: 'Hidrasyon suyu ifade eder. Deri üç katmandan oluşur; dış katman (epidermis), alttaki deri (dermis) ve deri altı doku. Epidermisin en dış tabakası yeterince su içermiyorsa hidrasyonu azalır, cilt elastikiyetini kaybeder ve pürüzlü bir his verir. Her gün minimum 1,5-2 litre su içerek hem cildinizdeki hidrasyonu sağlarsınız hem de cildinizdeki toksinlerden arınabilirsiniz.',
                },
                {
                    title: '2. Peeling Uygulaması Yapın',
                    description: 'Yüzünüzü her gün yıkıyor olabilirsiniz fakat kadınların neredeyse yarısının gözden kaçırdığı önemli bir cilt bakımı rutini vardır: peeling. Haftada 1-2 kez peeling uygulaması ile cildinizi ölü derilerden arındırabilirsiniz.',
                },
                {
                    title: '3. Düzenli Olarak Nemlendirin',
                    description: 'Cildimizdeki hücreler çok fazla su kaybederse cilt susuz kalabilir. Hücrelerin içindeki su içeriği azaldığında ise hücrelerde büzülmeler oluşur. Sonuç olarak cilt donuk görünür, parlaklığını kaybeder ve ince kırışıklıklar daha belirgin olur. Cildinizin optimal nemini sağlamak zorundasınız ve bunun için düzenli bir şekilde ihtiyacınız doğrultusunda cildinizi nemlendirmelisiniz.',
                },
                {
                    title: '4. Yeterli Uyku Düzeni Sağlayın',
                    description: 'Geceleri sizi ayakta tutan her şey sizinle parlayan cildiniz arasında durabilir. “Uyku, cilt hücrelerinin kendilerini onardığı ve yenilendiği zamandır”. Bu süre kısaltılırsa veya uyku biyoritmi değiştirilirse, cilt hücreleri optimum düzeyde performans gösteremez.',
                },
                {
                    title: '5. Sigara İçimini Sınırlandırın',
                    description: 'Sigara sağlığınıza zararlı olmakla kalmaz, aynı zamanda cildin ışıltısını da azaltır. Sigara içmek kolajen sentezini azaltarak yıkımını artırır, cildin kanlanmasının azalmasına neden olur, cildin zamanla solgun, donuk ve kırışık görünmesine yol açar.',
                },
                {
                    title: '6. Düzenli Beslenin',
                    description: 'Rafine şeker içermeyen, trans yağ bulundurmayan, antioksidan etkinliği yüksek dengeli ve düzenli beslenmek cilt sağlığı için önemlidir.',
                },
                {
                    title: '7. Hava Kirliliğine Karşı Önleminizi Alın',
                    description: 'Hava kirliliği, düşük nem, sert rüzgarlar ve güneşten gelen UV ışınlarına maruz kalma gibi çevresel faktörler, korunmasız cildi matlaştırabilir.',
                },
                {
                    description: 'İlk olarak, her gece yüzünüzü temizleme konusunda gayretli olun. UV ışınlarına ve kirliliğe karşı koruma sağlamak için günlük geniş spektrumlu SPF 30+ veya SPF 50 kullanın. Ayrıca “çevrede maruz kaldığımız serbest radikallerin cilde zarar vermesini durdurmak” için her sabah antioksidan serumları güneş koruyucusunun altına kullanabilirsiniz (en etkili antioksidanlardan bazıları C vitamini ve resveratrol’dür).',
                },
                {
                    title: 'Bonus: Mezoterapi Uygulaması',
                    description: 'Unutmayın ki hayatta her şey bir bütündür ve denge halindedir. Bu yüzden evde yapabileceğimiz kendimiz için en iyisi olabilecek yaşam tarzını benimseyerek yaşanmışlığın izlerini daha kolay azaltabilirsiniz.',
                },
            ]
        },
    },
    {
        blogid: 'kozmetik-islemlere-ne-zaman-ve-hangi-uygulama-ile-baslamaliyiz',
        title: "Kozmetik İşlemlere Ne Zaman ve Hangi Uygulama ile Başlamalıyız?",
        image: "/img/blog-images/kozmetik-islemlere-ne-zaman-ve-hangi-uygulama-ile-baslamaliyiz.jpg",
        date: '7 Eylül 2022',
        content: {
            blogPageTitle: "Kozmetik İşlemlere Ne Zaman ve Hangi Uygulama ile Başlamalıyız?",
            blogPageDesc: "Doğal yaş alma sürecimizi durduramayız ama yavaşlatabiliriz. Genlerimiz bu değişikliklerin ne zaman meydana geleceğini büyük ölçüde kontrol eder. Bu tür...",
            metaDescription: "Cildinizde yaşlanmaya ait belirtiler ortaya çıktıysa kozmetik işlemlerden ne zaman ve hangi uygulama ile başlamanız gerektiğini öğrenmek için hemen tıklayın.",
            blogDetailPageInfo: [
                {
                    description: 'Doğal yaş alma sürecimizi durduramayız ama yavaşlatabiliriz. Genlerimiz bu değişikliklerin ne zaman meydana geleceğini büyük ölçüde kontrol eder. Bu tür yaşlanma için tıbbi terim olarak “içsel yaşlanma” ifadesi kullanılır. Çevre ve yaşam tarzı seçimlerimiz cildimizin erken yaşlanmasına neden olabilir. Bu tür yaşlanma için ise tıbbi terim “dışsal yaşlanma”dır. ',
                },
                {
                    description: 'Cildimizin yaşlanmasında genetik faktörler kadar UV ışınları, sigara kullanımı, alkol tüketimi, kötü beslenme (rafine şeker ve trans yağlar içeren beslenme), yetersiz uyku (günde 7-8 saat ve gece uykusu önemlidir), psikolojik ve fiziksel stres ile çevre kirliliği gibi dışsal yaşlanma faktörleri de önemlidir. ',
                },
                {
                    description: '20’li yaşlarda cildimizdeki kolajen-elastin lifleri ve hyalüronik asit üretim hızı azalmaya ve yıkım hızı artmaya başlar. Bunun sonucunda biz her yıl kolajen ve elastin liflerimizin yüzde 1,5’ini kaybederiz. Kolajenin azalması deride gevşemeye neden olur. Elastik lif kaybı ile cildin esnekliği azalır. Cildimizdeki bu değişiklikleri 30’lu yaşların ortalarına kadar çok fark etmeyebiliriz. Cildimizdeki kolajen-elastin liflerinin ve hyalüronik asitin kaybını ne kadar erken dönemde önlemeye başlarsak cildimizin kalitesini o kadar korumuş oluruz. Hiçbir uygulama yaşlanma sürecini durdurmaz, sadece yavaşlatır. Bu yavaşlatma süreci sadece yapılacak olan uygulamalarla olmayacaktır. İlk paragrafta belirttiğimiz sizin kontrolünüz altında olan yaşam şeklinizi düzeltmek de önemlidir. Aynı zamanda spor yapmak da cildimizin kanlanmasını artırarak tüm uygulamaları destekleyecektir. ',
                },
                {
                    description: 'Yaşlanma sürecini yavaşlatmak için kendimiz için yapacağımız ilk kozmetik uygulama <b>mezoterapi</b> olmalıdır. Cildimizdeki kolajen-elastin liflerinin ve hyalüronik asitin kaybını azaltmak, azalan sentez kapasitesini artırmak için 20’li yaşların başında başlanılmasını önermekteyiz.',
                },
                {
                    description: 'Alın-kaş ortası - göz çevresindeki kırışıklıklar kullandığımız mimiklere bağlı olarak gelişir. Bu yüzden eğer çok fazla mimik kullanıyorsanız üst yüz bölgenizde oluşan çizgileri azaltıcı uygulamalara 20’li yaşların sonlarında başlayabilirsiniz.',
                },
                {
                    description: '30’lu yaşların ortalarından sonra cildinizin kalitesini artırmak için farklı tedavi yöntemleri belirlenebilir. Bu tedavi yöntemleri tamamen kişiye özeldir ve bireysel farklılıklar gösterir.',
                },
                {
                    description: 'Gelin şimdi de biraz cilt kalitemizi korumak ve artırmak için başlamamız gereken ilk uygulama olan mezoterapiden bahsedelim:',
                },
                {
                    title: 'Mezoterapi Nedir?',
                    description: 'Yaşlanma, modern toplumlarda artan bir endişedir. Yaşlanmaya bağlı değişiklikleri görebileceğimiz tek organımız ise cildimizdir. ',
                },
                {
                    description: 'Son yıllarda mezoterapi, yaşlanma karşıtı bir strateji olarak kozmetik dermatolojide oldukça yaygın olarak kullanılmaktadır. Mezoterapi ilk olarak 1950’de Fransa’da kullanılmaya başlanmış bir tedavi metodudur. Cilt gençleştirmede mezoterapinin amacı cildin sağlıklı ve genç dokusunun korunması ve/veya restorasyonudur. İstenilen nihai etki, mükemmel biyouyumlu ve tamamen emilebilir uygun ürünlerin cilt altına enjeksiyonu ile elde edilen daha sıkı, daha parlak, daha nemlendirilmiş bir cilttir. Mezoterapi ürünlerinde; hyalüronik asit, vitaminler, mineraller ve amino asitler, antioksidanlar (hem vücutta sentezlenen hem de insan vücudu tarafından sentezlenemeyen ve diyetle alınması gerekenler) ve mineraller bulunmaktadır. Mezoterapi, sorunlu bölgede bu maddelerin doğrudan optimal konsantrasyonlarla deriye iğneler aracılığıyla enjeksiyonudur. Bu ürünler fibroblastların biyosentetik kapasitesini ve optimal fizyolojik ortamın yeniden yapılandırılmasını, hücre aktivitesinin arttırılmasını ve kolajen, elastin ve hyalüronik asit sentezinin artırılmasını sağlar. ',
                },
                {
                    description: 'Mezoterapi, direkt problemli alanlara belirli miktarlarda uygulamaya olanak tanır. Uygulanan ürünler ciltte kalmaktadır ve herhangi bir vücuda emilim olmamaktadır.',
                },
                {
                    description: 'Yapılan enjeksiyon ve uygulama sayısı; hastaya, amacına ve enjeksiyonun yapılacağı bölgenin anatomisine bağlı olarak değişiklik göstermektedir.',
                }
            ]
        },
    },
];

export default blog;

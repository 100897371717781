import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const ContactArea = () => {
    const { t } = useTranslation();
    return (
        <>
            <section className="contact-area pt-50 pb-40" data-background="assets/img/bg/bg-map.png">
                <Helmet>
                    <title>{'İletişim | Doç. Dr. Ebru Karagün | Ankara Dermatoloji'}</title>
                    <meta name='description' content='Doç. Dr. Ebru Karagün eşliğinde cildinize dair yaşlanma sürecinizi geciktirecek sizlere uygun doğal, dinç ve sağlıklı uygulamalar için iletişim.' />
                </Helmet>
                <div className="container">
                    <div>
                        <div className="col-xl-7 col-lg-8 col-md-12">
                            <div className="section-title pos-rel mb-75">
                                <div className="section-icon">
                                    <img className="section-back-icon back-icon-left" src="/img/section/contact.jpg" width={480} style={{ opacity: 0.3, zIndex: '-1', marginLeft: '0px' }} alt="" />
                                </div>
                                <div className="section-text pos-rel">
                                    <h2 className='sub-custom-header'>{t('headerTwo.contact')}</h2>
                                    <h1>{t('headerTwo.contactUs')}</h1>
                                </div>
                                <div className="section-line pos-rel">
                                    <img src="/img/shape/section-title-line.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="contact text-center mb-30" style={{ minHeight: "320px" }}>
                                <i className="fas fa-envelope"></i>
                                <h3>{t('contactPage.email')}</h3>
                                <a href="mailto:info@drebrukaragun.com"> <h5>info@drebrukaragun.com</h5></a>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="contact text-center mb-30" style={{ minHeight: "320px" }}>
                                <i className="fas fa-map-marker-alt"></i>
                                <h3>{t('contactPage.address')}</h3>
                                <h5>Beştepe Mahallesi 32.Cadde No:1/22 <br /> (Moment Beştepe Plaza) <br /> Ankara</h5>
                            </div>
                        </div>
                        <div className="col-xl-4  col-lg-4 col-md-4 ">
                            <div className="contact text-center mb-30" style={{ minHeight: "320px" }}>
                                <i className="fas fa-phone"></i>
                                <h3>{t('contactPage.phone')}</h3>
                                <a href="tel:+905332691271"><h5 >+90 533 269 12 71</h5></a>
                                <a href="tel:+903125574646">
                                    <h5>+90 312 557 46 46</h5>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactArea;
const blog = [
    {
        blogid: 'side-effects-of-lipsticks',
        title: "Side Effects of Lipsticks",
        image: "/img/blog-images/rujlarin-yan-etkileri.jpg",
        date: 'October 16, 2023',
        content: {
            blogPageTitle: "Side Effects of Lipsticks",
            blogPageDesc: "Every woman dreams of having the ideal lip color. Women choose lipstick colors according to their outfits, tastes, moods, and sense of style. We have bad news for lipstick lovers...",
            metaDescription: "Lipstick contains dangerous ingredients that can harm your health and cause a range of health problems. Lipsticks can contain heavy metals like lead, nickel, aluminum, arsenic, cadmium, antimony, and chromium, which are harmful to health.",
            blogDetailPageInfo: [
                {
                    title: 'How This Makeup Product Can Harm You',
                    description: 'Every woman dreams of having the ideal lip color. Women choose lipstick colors according to their outfits, tastes, moods, and sense of style. We have bad news for lipstick lovers. Lipstick contains dangerous ingredients that can harm your health and cause a range of health problems. Lipsticks are known to contain heavy metals like lead, nickel, aluminum, arsenic, cadmium, antimony, and chromium.',
                },
                {
                    description: 'Did you know that lipsticks get into your body while eating food or drinking anything? Although we may be exposed to heavy metals in very small amounts, constant exposure becomes significant in developing chronic health risks. Chronic health risk is an irreversible outcome that gradually occurs after continuous exposure to low toxic doses.',
                },
                {
                    title: '1. They Cause Toxicity in the Body',
                    description: 'We know the number of toxic ingredients in lipsticks, so it is likely that your body absorbs these toxicities. In fact, women sometimes ingest these toxic materials without even realizing it. Always check the ingredients before buying lipstick and avoid products containing heavy metals like lead, nickel, aluminum, arsenic, cadmium, antimony, and chromium.',
                },
                {
                    description: 'Chronic lead exposure can cause neurological issues and pose risks during pregnancy.',
                },
                {
                    description: 'Long-term exposure to cadmium is closely associated with cardiovascular diseases such as atherosclerosis and hypertension. Although rarely, regular use of dark-colored lipsticks can lead to kidney failure due to cadmium accumulation in the body.',
                },
                {
                    title: '2. They Cause Various Skin Allergies',
                    description: 'Lipsticks contain a chemical called bismuth oxychloride for preservation. This ingredient causes allergies around the lips and is also carcinogenic, so be careful!',
                },
                {
                    title: '3. They Can Even Cause Cancer',
                    description: 'No, we are not joking! Since most of the ingredients are carcinogenic in nature, constant exposure to these components increases the risk of cancer. Long-term exposure to the toxic component cadmium is a reason for stomach tumors.',
                },
                {
                    title: 'What Can You Do to Minimize These Effects?',
                    description: 'Every problem has a solution. Some safer alternatives can minimize the harmful effects of lipstick.',
                },
                {
                    title: 'Things Frequent Lipstick Users Should Keep in Mind:',
                    description: 'Heavy metals are more abundant in darker shades of lipsticks. Prefer lighter shades when using lipstick.',
                },
                {
                    description: 'Pay attention to the ingredients of lipsticks to avoid the side effects of chemicals.',
                },
                {
                    description: 'Although a cheap lipstick may look appealing, we recommend checking the ingredients rather than the price.',
                },
                {
                    description: 'Always apply petroleum jelly before applying lipstick. It can provide a protective layer to your lips and minimize damage.',
                },
                {
                    description: 'Limit your use of lipstick as much as possible during pregnancy.',
                },
                {
                    description: 'Thoroughly clean your lipstick before going to bed.',
                },
                {
                    description: 'Make sure to buy organic and natural lipsticks.',
                },
                {
                    description: 'Do not apply lipstick more than 2-3 times a week.',
                },
                {
                    title: 'Sources:',
                    description: 'doi.org/10.1016/j.yrtph.2015.07.005 <br />doi.org/10.1016/j.freeradbiomed.2013.08.167 <br /> doi:10.52711/2321-5844.2023.00004',
                },
            ]
        },
    },
    {
        blogid: 'sunburn',
        title: "Sunburn",
        image: "/img/blog-images/gunes-yanigi.jpg",
        date: 'July 14, 2023',
        content: {
            blogPageTitle: "Sunburn",
            blogPageDesc: "Sunburn is painful skin that feels hot to the touch. Redness starts to become apparent 4-6 hours after sun exposure, reaching its peak severity within 24 hours...",
            metaDescription: "What causes sunburn - how to prevent it - what to do if you get sunburned. Click for detailed information.",
            blogDetailPageInfo: [
                {
                    description: 'Sunburn is painful skin that feels hot to the touch. Redness starts to become apparent 4-6 hours after sun exposure, reaching its peak severity within 24 hours. Redness, swelling, and blistering may occur. It can range from mild redness to severe second-degree burns. Sunburn can affect exposed areas of the body, including the earlobes, scalp, and lips. If clothing does not provide ultraviolet (UV) protection, sunburn can develop on covered areas as well.',
                },
                {
                    title: 'Symptoms of Sunburn:',
                    description: '- Sunburn symptoms usually appear 4-6 hours after sun exposure. <br /> - Appears pink or red on fair skin, difficult to detect on darker skin. <br /> - Skin feels hot <br /> - Pain, tenderness, and itching may be present <br /> - Blisters filled with fluid may form <br /> - Painful or gritty (irritated) eyes <br /> - If sunburn is severe, headache, fever, nausea, and vomiting can occur.',
                },
                {
                    title: 'When to See a Doctor:',
                    description: '- If there are large blisters <br /> - If blisters develop on the face, hands, or genital area <br /> - If there is increasing swelling in the sunburned areas <br /> - If blisters turn yellowish and appear infected <br /> Seek medical attention if pain increases, dizziness, confusion, nausea, or fever develops.',
                },
                {
                    title: 'Preventing Sunburn:',
                    description: '<ul><li><span style="font-weight:400">Use sunscreen to prevent sunburn even on cool, cloudy, or foggy days. Be extra careful with water, snow, concrete, and sand as they reflect sunlight.</span></li><li><span style="font-weight:400">The risk of sunburn is higher at higher altitudes.</span></li><li><span style="font-weight:400">Avoid sun exposure between 10:00 AM and 4:00 PM.</span></li><li><span style="font-weight:400">Apply sunscreen 15 to 30 minutes before going outside.</span></li><li><span style="font-weight:400">Use a water-resistant, broad-spectrum lip balm and sunscreen with an SPF of at least 30, even on cloudy days. </span></li><li><span style="font-weight:400">Broad-spectrum products provide protection against ultraviolet A (UVA) and ultraviolet B (UVB) and blue light spectrum. </span></li><li><span style="font-weight:400">Sunscreen does not provide complete protection from the sun; you should avoid the sun as much as possible. Apply it regularly and generously without leaving any gaps.</span></li><li><span style="font-weight:400">Reapply sunscreen every 2 to 3 hours if you stay in the sun.</span></li><li><span style="font-weight:400">Even products labeled as water-resistant should be reapplied immediately after washing, showering, sweating, or swimming.</span></li><li><span style="font-weight:400">One of the most common mistakes is applying sunscreen once a day and in very small amounts.</span></li></ul>',
                },
                {
                    title: 'What Helps Sunburn? How to Treat Sunburn?',
                    description: '<ul><li><span style="font-weight:400">Taking pain relievers and cooling the area is the first thing to do. However, sunburn can take days to heal.</span></li><li><span style="font-weight:400">Keep the sunburned areas as cool as possible. Apply cold water compresses or take a cool shower.</span></li><li><span style="font-weight:400">Drink plenty of water, 3-4 liters a day.</span></li><li><span style="font-weight:400">Do not pop blisters as there is a risk of infection; let them deflate naturally. If they are very large, consult a dermatologist who will drain them in a controlled manner.</span></li><li><span style="font-weight:400">You can take anti-inflammatory pain relievers for 3-5 days (people with stomach issues should use caution).</span></li><li><span style="font-weight:400">Prevent sunburned areas from being exposed to the sun again.</span></li><li><span style="font-weight:400">Applying yogurt or toothpaste, which are common but incorrect practices, will not help. They may relieve pain temporarily due to their cold temperature, but they can cause infection.</span></li><li><span style="font-weight:400">Moisturize the sunburned areas. Aloe vera-based moisturizers are particularly effective.</span></li><li><span style="font-weight:400">These are home remedies. If you do not feel relief, consult a dermatologist.</span></li></ul>',
                }
            ]
        },
    },
    {
        blogid: 'what-is-collagen',
        title: "What Is Collagen?",
        image: "/img/blog-images/kollajen-nedir.jpg",
        date: 'July 14, 2023',
        content: {
            blogPageTitle: "What Is Collagen?",
            blogPageDesc: "Collagen is an important protein component found in the structure of skin, bones, connective tissues, and muscles. It forms the structural matrix for your bones and skin....",
            metaDescription: "Collagen is one of the essential building blocks of our body. What should we pay attention to when taking supplements? Click for detailed information.",
            blogDetailPageInfo: [
                {
                    description: "Collagen is an important protein component found in the structure of skin, bones, connective tissues, and muscles. It forms the structural matrix for your bones and skin. Thanks to skin collagen, your skin feels young, firm, and elastic, your joints move smoothly, and your bones remain strong.",
                },
                {
                    description: "Collagen in the human body is composed of three amino acids that form the building blocks of collagen fibrils. These collagen fibrils then aggregate into larger groups to make collagen fibers. As a result, collagen has a cable-like structure, consisting of many small protein bundles grouped together, which gives collagen various physical properties, including high tensile strength.",
                },
                {
                    description: "Type I collagen: Found in many connective tissues, especially skin, bones, tendons.",
                },
                {
                    description: "Type II collagen: Mainly found in cartilage and vitreous fluid.",
                },
                {
                    description: "Type III collagen: Found in flexible structures like skin, lungs, and blood vessels.",
                },
                {
                    description: "In adult skin, type I collagen makes up 80% of collagen; type III collagen is around 15%; and types IV and V are about 5%.",
                },
                {
                    title: "Did You Know?",
                    description: "From our twenties, we lose about 1.5-2.5% of our natural collagen every year. Thus, signs of aging slowly begin to appear. The skin loses its elasticity, becomes dull, fine lines form, and sagging becomes noticeable. Brittle hair, painful joints, and bones develop over time. Supplements can be used to reduce collagen loss in our body.",
                },
                {
                    title: "What Causes Collagen Loss in the Skin?",
                    description: "Factors that can cause collagen loss in the skin include:<br /><br />UV exposure (also known as photoaging)<br />Age<br />Smoking<br />Genetics<br />Menopause<br />Breathing very polluted air<br />High consumption of processed foods<br />Stress",
                },
                {
                    title: "What Should Be Considered When Taking Collagen?",
                },
                {
                    title: "1. What Type of Collagen Should Be in the Content?",
                    description: "Hydrolyzed collagen is much better absorbed. This more bioactive collagen is also known as hydrolyzed collagen or collagen peptides.",
                },
                {
                    title: "2. How Much Collagen Should Be in the Content?",
                    description: "The collagen amount should be at least 2.5 grams. 2.5 to 10 grams is ideal. Pay attention to the content and amount when taking collagen.",
                },
                {
                    title: "3. Which Collagen Sources Should You Choose?",
                    description: "Marine collagen: Derived from the skin or scales of fish. Contains type 1 and type 2 collagen peptides. Marine collagen is better absorbed by the body, making it more effective, and is the most effective collagen source for the skin, providing 1.5 times more benefit compared to other collagens. However, because it is derived from marine life, marine collagen is slightly more expensive than bovine collagen.",
                },
                {
                    description: "<strong>Bovine Collagen:</strong> Made from bovine hide, contains type 1 and type 3 collagen.",
                },
                {
                    title: "4. Which Form of Collagen Should Be Preferred?",
                    description: "The absorption of sachet or liquid form is faster and more effective than capsule or tablet form.",
                },
                {
                    title: "5. How Long Should Collagen Be Used?",
                    description: "It is appropriate to use collagen for an average of 12 weeks, then it can be reused after a 12-16 week break. We should be careful to drink plenty of water while consuming collagen.",
                },
            ]
        },
    },
    {
        blogid: 'why-does-hair-turn-white',
        title: "Why Does Hair Turn White?",
        image: "/img/blog-images/drebrukaragun-cilt-catlaklari.jpg",
        date: 'March 28, 2023',
        content: {
            blogPageTitle: "Why Does Hair Turn White?",
            blogPageDesc: "Around the hair follicles are pigment (melanin) cells that provide the hair color. Over time, hair follicles lose their pigment cells and turn white....",
            metaDescription: "What are the reasons for our hair turning white? Is there a treatment for white hair? What should we do to prevent it from turning white?",
            blogDetailPageInfo: [
                {
                    description: "Around the hair follicles are pigment (melanin) cells that provide the hair color. Over time, hair follicles lose their pigment cells and turn white. It is normal for hair color to change as people age. However, gray or white hair can appear at almost any stage of life.",
                },
                {
                    description: "Genetic factors, vitamin deficiencies, dietary factors, stress, hair dyes, and other factors can be causes.",
                },
                {
                    description: "In this blog, we will discuss the common causes of prematurely white hair as well as processes for slowing or preventing it in some cases.",
                },
                {
                    title: "Causal Factors of White Hair:",
                    description: "Many reasons other than age can cause a person's hair to turn white. White and gray hair can begin to appear at any age and can be caused by various factors.",
                },
                {
                    title: "1. Vitamin Deficiencies",
                    description: "Although graying is mostly genetic, deficiencies in vitamins B-6, B-12, biotin, vitamin D, or vitamin E can cause premature graying.",
                },
                {
                    description: "Nutritional deficiencies have been found to affect pigmentation in many studies, and white hair caused by vitamin deficiency will return with vitamin supplements.",
                },
                {
                    description: "A 2016 research article reported in the International Journal of Trichology examined the factors related to early graying under the age of 25. Participants with early graying had low levels of serum ferritin, which stores iron in the body, and B-12. People experiencing early graying should check their blood vitamin levels.",
                },
                {
                    title: "2. Genetics",
                    description: "A 2013 study published in the Indian Journal of Dermatology, Venereology and Leprology found that early graying of a person's hair is largely determined by genetics. The loss of melanin in hair naturally occurs, especially after the 30s. However, the loss of hair color is largely determined by your genes. If your parents experienced early graying, your hair is likely to gray early too.",
                },
                {
                    description: "Despite claims made online and by product marketers, reversing white hair is often difficult if the cause is genetic.",
                },
                {
                    title: "3. Oxidative Stress",
                    description: "Although graying is mostly genetic, the process of hair turning white at an early age can involve oxidative stress in the body.",
                },
                {
                    description: "Free radicals are unstable molecules that damage cells, leading to aging and disease, while antioxidants counteract the harmful effects of free radicals. Oxidative stress causes imbalances when antioxidants are not enough to counter the harmful effects of free radicals. When antioxidants decrease in the body, oxidative stress increases. A stressful lifestyle, ready-to-eat and packaged foods containing trans fats and refined sugar, less than 7-8 hours of sleep a day, and living in an environment with high air pollution increase oxidative stress in the body.",
                },
                {
                    title: "4. Accompanying Diseases",
                    description: "Thyroid diseases, and alopecia areata (hair loss) tend to cause hair to turn white.",
                },
                {
                    title: "5. Stressful Life",
                    description: "It is commonly thought that stress can cause hair to turn white prematurely. A 2021 study conducted by Columbia University found that hair can turn white due to stress and, furthermore, can regain its color when stress levels decrease.",
                },
                {
                    title: "6. Smoking",
                    description: "A 2013 study conducted in Italy found that smokers were 6 times more likely to start graying before the age of 30 compared to non-smokers.",
                },
                {
                    description: "A 2015 study published in the Journal of the American Academy of Dermatology found that smoking was associated with early graying of hair in young men in their 20s.",
                },
                {
                    title: "7. Chemical Hair Dyes and Hair Products",
                    description: "Chemical hair dyes and hair products, even shampoos, can contribute to early hair graying. Most of these products contain harmful ingredients that reduce melanin.",
                },
                {
                    description: "Hydrogen peroxide, found in many hair dyes, is one such harmful chemical. Excessive use of products that lighten hair color also causes it to turn white over time.",
                },
                {
                    title: "Preventing and Reversing Early White Hair",
                    description: "If the cause is genetic or age-related, the process often cannot be prevented or reversed. However, treating prematurely graying hair if there is an underlying cause can restore color pigmentation.",
                },
                {
                    description: "When vitamin deficiencies are the cause of early white hair, correcting them can reverse the problem or prevent it from getting worse.",
                },
                {
                    description: "A diet rich in antioxidants, including more antioxidant-rich vegetables and fruits, can help prevent hair from turning white. And quitting smoking...",
                },
                {
                    title: "LAST WORD:",
                    description: "The possibility of early graying occurs due to a decrease in melanin production in your hair follicles. Sometimes stress, poor dietary habits, and other lifestyle factors that increase oxidative stress in the body can stop melanin production. When these issues are reversed, melanin is produced again. Mesotherapy treatment options can also help regain hair color.",
                },
                {
                    description: "Still, in most cases, the age at which you start to see gray hair and its extent are controlled by your genes. Genetic white hair is often irreversible. However, with the development of mesotherapy options in recent years, the process can be slowed down and color recovery can be achieved even if it is due to genetic factors.",
                },
                {
                    description: "Mesotherapy treatment options have been found to be effective at every stage of early hair graying.",
                },
                {
                    description: "Camouflaging or accepting your grays—both are your choice. You are who you are when you feel happy in front of the mirror.",
                },
                {
                    description: "<p style='text-align:right'><span style='font-weight:400'>Assoc. Prof. Dr. Ebru Karagün</span></p><p style='text-align:right'><span style='font-weight:400'>Dermatology Specialist</span></p><span style='font-weight:400'>And remember, life always starts from where we catch it.</span>",
                },
            ]
        },
    },
    {
        blogid: 'male-pattern-hair-loss',
        title: "Male Pattern Hair Loss",
        image: "/img/blog-images/erkek-tipi-sac-dokulmesi.jpg",
        date: 'March 28, 2023',
        content: {
            blogPageTitle: "Male Pattern Hair Loss",
            blogPageDesc: "Male pattern hair loss is the thinning of hair follicles due to hormonal effects in genetically predisposed individuals. The primary causes are the same in both men and women. In both...",
            metaDescription: "What causes male pattern hair loss? Are treatments effective? Is hair transplantation a solution? Click for detailed information.",
            blogDetailPageInfo: [
                {
                    description: "Male pattern hair loss is the thinning of hair follicles due to hormonal effects in genetically predisposed individuals. The primary causes are the same in both men and women.",
                },
                {
                    description: "In both sexes, it can start at any time after puberty. Clinically, it is noticeable at around 17 years old in men and 25-30 years old in women. In both sexes, hair loss primarily progresses from the top of the scalp forward. In men, the hairline often recedes. In men, hair loss progresses towards complete baldness with the hairline receding at the front and the top of the scalp thinning forward.",
                },
                {
                    description: "Genetic factors are significant; the more male relatives in a man's family with hair loss problems, the higher the chances of encountering this problem. It is a significant risk factor if the father or uncle has it.",
                },
                {
                    description: "In women, having similar hair loss in the mother is a risk factor.",
                },
                {
                    title: "What Causes Male Pattern Hair Loss?",
                    description: "Hair follicles have receptors for estrogen and testosterone hormones. Testosterone is the enemy of hair; it thins and sheds hair, and the hair that grows back is thinner and eventually becomes so thin that it cannot grow out from the hair follicle. If a hair strand does not grow out from the hair follicle for 5-6 years, we unfortunately lose that hair follicle. This condition is due to increased sensitivity to testosterone in the hair follicles, so blood testosterone levels are normal. The hair follicles sensitive to hormones are the ones on the top, front, and upper sides of the scalp. Hair in the nape and lower side areas of the scalp are not sensitive to hormones and will not shed.",
                },
                {
                    title: "How Is It Diagnosed?",
                    description: "The diagnosis is usually made through a clinical examination. A dermoscopic examination is performed to evaluate your hair follicles. In women, if there is menstrual irregularity, hormone levels should be checked. Especially in men, the progression to baldness varies. In some people, this process can be as short as 5 years, while in others, it can be as long as 15-20 years. In women, there is no baldness process, but the top of the scalp may look like a Christmas tree.",
                },
                {
                    title: "Treatment of Male Pattern Hair Loss",
                },
                {
                    title: "Home Treatments You Can Do",
                    description: "Minoxidil: Minoxidil is the most effective home treatment option. Its effectiveness continues as long as it is used, and hair starts to shed 3-4 months after stopping the treatment. In men, 5% topical minoxidil is found to be more effective. It should be applied in the morning and evening. It should stay on the scalp for at least 4 hours. In women, both 2% and 5% forms are used, but the 2% form is often preferred in treatment. After starting treatment, hair shedding may be seen on average for 8 weeks, which is a normal process. Its effectiveness begins in the 6th month, and maximum effectiveness is achieved in 1 year.",
                },
                {
                    title: "Mesotherapy Applications",
                    description: "Mesotherapy is a very effective treatment option for male pattern hair loss. After your doctor evaluates your hair loss, the protocol for mesotherapy treatment is determined. This protocol may include weekly sessions for some patients and monthly sessions for others. The initial treatment protocol is usually completed in 3-4 months. After that, it is important to continue mesotherapy sessions every 3-4 months for maintenance. The purpose of mesotherapy is to reduce sensitivity to testosterone in hair follicles and to support miniaturized hair strands to become thick hair strands again by providing growth factors, vitamins, and minerals that are important for the hair cycle.",
                },
                {
                    description: "After 2-3 months of mesotherapy, a decrease in hair shedding is expected, new hair growth begins in the 3rd-4th month, and an increase in hair density is expected in the 6th-8th months. If maintenance therapy is followed, the state of well-being continues.",
                },
                {
                    description: "The most important factor determining treatment effectiveness is the time when hair loss began. If a miniaturized hair strand does not grow out from the hair follicle for 5-6 years, we lose that hair follicle, and it is impossible to regain it. The earlier the treatment is started after hair loss begins, the more effective the treatment will be.",
                },
                {
                    title: "Platelet Rich Plasma (PRP)",
                    description: "PRP is frequently preferred in mesotherapy applications. PRP contains a high concentration of platelets. Platelets are rich in growth factors and have stimulating, repairing, and nourishing effects. PRP is often included in the mesotherapy treatment protocol.",
                },
                {
                    title: "Dermapen",
                    description: "Dermapen treatment is combined with mesotherapy treatment to stimulate hair follicles and increase new hair growth.",
                },
                {
                    title: "Hair Transplantation",
                    description: "Hair transplantation applications have been frequently performed in recent years. This method has shown successful results for male pattern hair loss. The cosmetic outcome depends on the amount and density of donor hair in the nape area and the experience of the person performing the procedure. Since hair transplantation uses hair from the nape area and these hairs are not sensitive to hormones, they will not shed in the transplanted area. However, even if hair transplantation is done, it is necessary to continue mesotherapy applications to protect the existing hair in the top-front and upper side areas.",
                },
                {
                    description: '<p style="text-align:right"><span style="font-weight:400">Assoc. Prof. Dr. Ebru Karagün</span></p><p style="text-align:right"><span style="font-weight:400">Dermatology Specialist</span></p><span style="font-weight:400">And remember, life always starts from where we catch it.</span>',
                },
            ]
        },
    },
    {
        blogid: 'top-7-antioxidants-for-your-skin',
        title: "Top 7 Antioxidants for Your Skin",
        image: "/img/blog-images/cildiniz-icin-en-iyi-7-antidoksan.jpg",
        date: 'March 3, 2023',
        content: {
            blogPageTitle: "Top 7 Antioxidants for Your Skin",
            blogPageDesc: "Antioxidants work by reversing or preventing oxidative stress, which causes cells to age. To define oxidative stress with an example: it is the browning of a sliced apple...",
            metaDescription: "Antioxidants work by reversing or preventing oxidative stress, which causes cells to age.",
            blogDetailPageInfo: [
                {
                    description: "Antioxidants work by reversing or preventing oxidative stress, which causes cells to age.",
                },
                {
                    description: "To define oxidative stress with an example: it is the browning of a sliced apple.",
                },
                {
                    description: "Unfortunately, oxidative stress damage prevents the skin's regular self-repair systems. With age, oxidative stress in our skin increases, and sun exposure, air pollution, environmental toxins, smoking, lack of sleep, poor diet, and stress also increase oxidative stress. Therefore, topical antioxidants are effective in reducing oxidative stress.",
                },
                {
                    title: "Vitamin C",
                    description: "A favorite among dermatologists, vitamin C is one of the most studied antioxidants. As a free radical scavenger, vitamin C has collagen-boosting and spot-reducing effects. It can be listed in products as L-ascorbic acid, vitamin C ester, ascorbyl palmitate, sodium ascorbyl phosphate, magnesium ascorbyl phosphate, or lipophilic vitamin C. The most effective concentration is between 8-15%. Application: apply to the entire face in the morning, followed by sunscreen.",
                },
                {
                    title: "Niacinamide",
                    description: "Also known as vitamin B3, niacinamide is a powerful antioxidant that improves the texture and tone of the skin. It reduces fine lines, wrinkles, spots, and regulates oil balance in the skin, making it effective in acne treatment. Its soothing effect also reduces redness.",
                },
                {
                    title: "Resveratrol",
                    description: "Resveratrol is one of the essential substances for the body. It is a full source of antioxidants, greatly contributing to the body's renewal and health. Mostly found in the skins of red-purple fruits, resveratrol acts as a defense shield for plants and has a similar repairing effect when used in skin care products. Resveratrol is a powerful antioxidant that calms the skin and reduces skin aging. We recommend applying it in the evening, as UV exposure reduces its effectiveness.",
                },
                {
                    title: "Vitamin E",
                    description: "Vitamin E is an essential antioxidant necessary for the proper functioning of many organs in the body, including the skin. It is commonly used for its ability to speed up the healing process of the skin. Vitamin E is often found in moisturizers, creams, lotions, and products designed to reduce stretch marks. Vitamin E also helps stabilize other antioxidants and enhance their effectiveness, so it is often used with vitamin C or resveratrol to increase the effectiveness of each product.",
                },
                {
                    title: "Retinol (Vitamin A)",
                    description: "If there is a proven ingredient to help reverse the biological clock of aging skin, it is retinol. A derivative of vitamin A, this antioxidant is especially effective due to its small molecular structure, which allows it to penetrate deeply, stimulating collagen production and accelerating cell renewal and repair. We recommend using it only during the winter.",
                },
                {
                    title: "Coenzyme Q10",
                    description: "As we age, the presence of coenzyme Q10, also known as ubiquinone, gradually decreases in our bodies, making us more susceptible to wrinkles and photo-aging. Research shows that increasing topical application of this antioxidant helps fight free radical damage and keeps our skin cells healthy. This product is easily absorbed by the skin, helps improve texture by increasing elasticity, and boosts collagen production.",
                },
                {
                    title: "Polyphenols",
                    description: "Polyphenols are compounds found naturally in plants, responsible for their colors, and exhibit antioxidant properties. They are a type of polyphenol found in fruits, vegetables, green or black tea (also known as flavonoids). Studies have shown that consuming and topically applying ingredients rich in polyphenols helps improve the skin's natural defense against oxidative stress, prevent signs of aging, and repair DNA damage.",
                },
                {
                    description: "We recommend starting to use antioxidant products for your skin from your 20s.",
                },
            ]
        },
    },
    {
        blogid: 'acne-treatment-of-acne-scars',
        title: "Acne - Treatment of Acne Scars",
        image: "/img/blog-images/akne-sivilce-izlerinin-tedavisi.jpg",
        date: 'November 25, 2022',
        content: {
            blogPageTitle: "Acne - Treatment of Acne Scars",
            blogPageDesc: "Why Do Acne Scars Occur: Early and effective treatment of acne will largely prevent the formation of acne scars, the goal is to treat before the scars develop...",
            metaDescription: "Acne scars can be bothersome, but there are many treatment options available that can make your acne scars less noticeable.",
            blogDetailPageInfo: [
                {
                    title: 'Why Do Acne Scars Occur:',
                    description: "Early and effective treatment of acne will largely prevent the formation of acne scars, the goal is to treat before the scars develop.",
                },
                {
                    description: "Remember; the best way to treat acne scars is to prevent acne in the first place.",
                },
                {
                    description: "If you tamper with your acne, you increase the likelihood of developing acne scars. You should avoid picking, popping, or squeezing your pimples.",
                },
                {
                    description: "The acne scars that have formed will not heal on their own; treatments that stimulate the skin are required for improvement.",
                },
                {
                    title: 'Types of Acne Scars:',
                    description: '<b>Erythema (Redness):</b> After acne heals, redness may remain in the areas where the acne was. It can regress over time. If it becomes permanent, vascular laser treatment can be effective.',
                },
                {
                    description: '<b>Depressed Acne Scars:</b> 80-90% of acne scars are depressed scars caused by collagen loss. They are primarily classified as icepick (pick or ice pick-like), boxcar, and rolling scars.',
                },
                {
                    description: '<b>Icepick:</b> Narrow (<2mm), deep, sharply defined tunnels that reach the deeper layers of the skin. They make up 60-70% of all acne scars.',
                },
                {
                    description: '<b>Rolling:</b> Lesions that appear to pull the skin down deeply and are wider than 4-5mm. They have a wavy appearance.',
                },
                {
                    description: '<b>Boxcar:</b> Sharp-edged, round or oval depressions, usually 1.5 to 4 mm in diameter.',
                },
                {
                    title: 'Treatment Options for Acne Scars:',
                    description: "Acne scars are stubborn, and there is no one-size-fits-all treatment option. A detailed history and examination play a key role in an appropriate and effective treatment plan for acne scars. The patient's age, type of acne scars, color of acne scars, and skin type affect the treatment options. There is no single ideal procedure for treating acne scars, so combination treatments are necessary for the best results. Combining different treatment methods according to the types of acne scars and patients' needs is more effective.",
                },
                {
                    description: "Treatment effectiveness varies from person to person, with improvements ranging from 30% to 80%.",
                },
                {
                    description: "The younger the treatment starts, the more satisfying the results will be.",
                },
                {
                    title: 'Device Treatments',
                    description: "Scarlet X and/or fractional laser treatments can be applied. The treatments are done in sessions. The number of sessions varies depending on the type and severity of acne scars, with an average of at least three sessions recommended. In some cases, the number of sessions may increase. PRP application along with these treatments enhances their effectiveness.",
                },
                {
                    description: "Scarlet X treatments can be applied in all seasons, and since there is no peeling of the skin after the procedure, it is a more comfortable application.",
                },
                {
                    description: "Fractional laser treatments are applied in the winter, and since there is peeling of the skin after the procedure, it is necessary to protect the skin very well from the sun and keep it moisturized.",
                },
                {
                    title: 'Subcision',
                    description: "Subcision is used to release the bands pulling the skin down to the deeper layers. Its application increases the effectiveness of the treatment for rolling type acne scars.",
                },
                {
                    title: 'Chemical Peeling',
                    description: "The outer layers of the skin with acne scars are peeled off and removed. Additionally, the skin is stimulated by the damage, triggering new collagen synthesis. Care should be taken when applying it to dark-skinned individuals due to the risk of post-treatment pigmentation. The CROSS-TCA method is particularly effective for ice-pick acne scars.",
                },
                {
                    title: 'Dermapen',
                    description: "It is effective in the treatment of newly developed acne scars. Dark-skinned individuals may develop pigmentation after the application, so proper patient selection is important.",
                },
                {
                    title: 'Soft Tissue Fillers',
                    description: "Injecting collagen or hyaluronic acid under the skin plumps up the indented scars. The aim is to make acne scars less noticeable. Applying these treatments, especially after device treatments, enhances their effectiveness.",
                },
                {
                    title: 'Final Word:',
                    description: "There is no single method that completely heals acne scars; combined treatments are necessary.",
                },
                {
                    description: "A treatment protocol tailored to the type of acne scar should be created for the patient from the available treatment options.",
                },
                {
                    description: "An acne scar treatment plan does not yield the same results for every patient.",
                },
                {
                    description: "Acne scars can be bothersome, but there are many treatment options available that can make your acne scars less noticeable.",
                },
            ]
        },
    },
    {
        blogid: 'why-do-skin-stretch-marks-occur',
        title: "Why Do Skin Stretch Marks Occur, How Can I Prevent Them?",
        image: "/img/blog-images/deri-catlaklari-neden-olur-nasil-onlerim.jpg",
        date: 'November 8, 2022',
        content: {
            blogPageTitle: "Why Do Skin Stretch Marks Occur, How Can I Prevent Them?",
            blogPageDesc: "Commonly known as skin stretch marks, 'striae' are scars that develop in the deeper layers of the skin due to excessive stretching and become visible on the skin's surface...",
            metaDescription: "Skin stretch marks are not significant for general health but can be a cosmetic concern, especially in women. Click for detailed information.",
            blogDetailPageInfo: [
                {
                    description: "Commonly known as skin stretch marks, 'striae' are scars that develop in the deeper layers of the skin due to excessive stretching and become visible on the skin's surface. Skin stretch marks are extremely common, twice as frequent in women. They can be observed in the 5-50 age group and the incidence rate ranges from 11-88%. They are observed in 90% of women during pregnancy.",
                },
                {
                    description: "Skin stretch marks usually develop during physiological conditions such as pregnancy, adolescence growth spurts, weightlifting, rapid weight gain, or rapid weight loss.",
                },
                {
                    description: "Stretch marks observed during adolescence appear on the upper legs, lower legs, hips, and breasts in girls, and usually on the waist, hips, and upper legs in boys.",
                },
                {
                    description: "Stretch marks observed during pregnancy appear in the last three months, often on the abdomen, breasts, and upper legs.",
                },
                {
                    title: 'Risk Factors for Stretch Marks During Pregnancy:',
                    description: "<ul><li>Genetic predisposition,</li><li>Getting pregnant at a young age,</li><li>Excessive weight gain during pregnancy,</li><li>Associated with the size of the abdomen (large baby, excess amniotic fluid, or multiple pregnancies).</li></ul>",
                },
                {
                    title: 'Prevention and Treatment of Skin Stretch Marks:',
                    description: "Skin stretch marks are not significant for general health but can be a cosmetic concern, especially in women.",
                },
                {
                    title: 'To Prevent:',
                    description: "1. It is extremely important to identify the risks well and raise awareness about these risk factors in patients.",
                },
                {
                    description: "2. Creams should be started from the 12th week of pregnancy.",
                },
                {
                    description: "3. Especially; creams containing Centella asiatica (trofolastin), Vitamin C, Vitamin E, and rosehip oil have been found to be effective in studies.",
                },
                {
                    description: "Scientific studies have not obtained sufficient positive results on the effectiveness of commonly used almond oil, cocoa butter, and olive oil.",
                },
                {
                    description: "It is known that massaging while applying creams during pregnancy reduces the formation of stretch marks.",
                },
                {
                    title: 'For Treatment:',
                    description: "Dermapen, Scarlet-X, Chemical Peeling, Laser Treatments, PRP, and Mesotherapy are applied. The effectiveness of the treatment increases if applied in the early stage, the pink-purple stage.",
                },
                {
                    description: "There are treatment options available for all age groups and during breastfeeding periods. There are also treatment options for stretch marks in both the pink-purple stage and the white stage. However, it should be especially known that treatment effectiveness increases if applied in the early stage, the pink-purple stage.",
                },
                {
                    title: 'REFERENCES:',
                    description: "Lokhande AJ, Mysore V. Striae Distensae Treatment Review and Update. Indian Dermatol Online J. 2019;10(4):380-395.",
                },
                {
                    description: "A Hague, A Bayat. Therapeutic targets in the management of striae distensae: A systematic review. J Am Acad Dermatol. 2017 Sep;77(3):559-568.e18.",
                },
                {
                    description: "Yu Y, Wu H, Yin H, Lu Q. Striae gravidarum and different modalities of therapy: a review and update. J Dermatolog Treat. 2022 May;33(3):1243-1251",
                },
                {
                    description: "Forbat E, Al-Niaimi F. Treatment of striae distensae: An evidence-based approach. J Cosmet Laser Ther. 2019;21(1):49-57.",
                },
                {
                    description: "Ud-Din S, McGeorge D, Bayat A. Topical management of striae distensae (stretch marks): prevention and therapy of striae rubrae and albae. J Eur Acad Dermatol Venereol. 2016 Feb;30(2):211-22.",
                },
            ]
        },
    },
    {
        blogid: 'how-does-scabies-spread-and-what-are-the-prevention-methods',
        title: "How Does Scabies Spread and What Are the Prevention Methods?",
        image: "/img/blog-images/uyuz-nasil-bulasir-ve-korunma-yontemleri-nelerdir.jpg",
        date: 'October 21, 2022',
        content: {
            blogPageTitle: "How Does Scabies Spread and What Are the Prevention Methods?",
            blogPageDesc: "Scabies (Scabies) is a contagious parasitic skin disease caused by mites and seen worldwide. Microscopic mites enter the skin...",
            metaDescription: "Scabies (Scabies) is a contagious parasitic skin disease caused by mites and seen worldwide. Click for information.",
            blogDetailPageInfo: [
                {
                    title: 'What is Scabies?',
                    description: 'Scabies (Scabies) is a contagious parasitic skin disease caused by mites and seen worldwide. Microscopic mites enter the skin and lay eggs, causing an intense itching and rash reaction. Scabies symptoms appear 3-6 weeks after the infection.',
                },
                {
                    title: 'What Are the Symptoms of Scabies?',
                    description: 'Although scabies does not pose a threat to a person’s general health, it significantly reduces the quality of life.',
                },
                {
                    description: '1. The most characteristic feature of scabies is intense itching at night, which increases when the person gets into bed and warms up. Itching can be severe enough to wake the person from sleep, while it is milder during the day.',
                },
                {
                    description: '2. Itching typically occurs on average 4 weeks (3 to 6 weeks) after infection.',
                },
                {
                    description: '3. In patients, itching is typically prominent in the spaces between the fingers, the inner wrists, armpits, nipples, arms and legs, ankles, and around the navel. In infants and young children, a more widespread rash may be seen, including the palms, soles, ankles, and sometimes the scalp. Particularly in adult men, red, raised, hard lesions can be observed in the genital area.',
                },
                {
                    description: '4. Even after successful treatment, itching may continue for 2 to 6 weeks.',
                },
                {
                    title: 'How Does Scabies Spread?',
                    description: '1. Scabies spreads mainly through direct and prolonged (at least 30 minutes) skin contact among family members or sexual partners. It does not spread through brief skin contact (e.g., shaking hands).',
                },
                {
                    description: '2. When clothes used by a person with scabies are worn by another person. Transmission through bed linen is rare, but it is possible.',
                },
                {
                    description: '3. Although rare, transmission through clothing, such as shawls used in cafes, can pose a risk.',
                },
                {
                    title: 'How is Scabies Diagnosed?',
                    description: 'In patients suspected of having scabies, a possible diagnosis is mostly made based on history and examination. Scabies is highly contagious and can infect anyone regardless of age, gender, or socioeconomic level, making early diagnosis and early implementation of control measures very important.',
                },
                {
                    title: 'Prevention Methods for Scabies',
                    description: '1. To prevent and control the disease, everyone sharing the same household with a person diagnosed with scabies (regardless of whether they have itching symptoms) should be treated at the same time (Family treatment is important). If treatment is not applied simultaneously, the disease will continue to spread from person to person. During the period between infection and the development of symptoms, you can be contagious even before itching develops.',
                },
                {
                    description: '2. Personal items used at home should be separated; clothes, sheets, and towels should be washed at 70-80 degrees at least, and especially the seams of the laundry should be ironed.',
                },
                {
                    description: '3. Bed linen should be changed continuously.',
                },
                {
                    description: '4. Items that cannot be washed (such as coats and jackets) should be kept in a sealed bag for 5-7 days.',
                },
            ]
        },
    },
    {
        blogid: 'is-it-possible-to-get-rid-of-facial-blemishes',
        title: "Is It Possible to Get Rid of Facial Blemishes?",
        image: "/img/blog-images/yuz-lekelerinden-kurtulmak-mumkun-mu.jpg",
        date: 'October 14, 2022',
        content: {
            blogPageTitle: "Is It Possible to Get Rid of Facial Blemishes?",
            blogPageDesc: "Facial blemishes are an unfortunate reality for many people. Melasma is a skin condition that presents as brown spots on the face due to various reasons...",
            metaDescription: "Learn about the causes of facial blemishes and solutions to help you get rid of them, and get information about treatment by clicking here.",
            blogDetailPageInfo: [
                {
                    description: 'Facial blemishes are an unfortunate reality for many people. Melasma is a skin condition that presents as brown spots on the face due to various reasons.',
                },
                {
                    description: 'In this article, we will explain the causes of these blemishes on our faces and offer solutions to help you get rid of them.',
                },
                {
                    title: 'How Common are Facial Blemishes (Melasma)?',
                    description: 'It usually starts between the ages of 20-40 and is common enough to affect 40-50% of women. Facial blemishes are a very common skin disorder, especially among pregnant women. It affects 15 to 50% of pregnant women.',
                },
                {
                    title: 'Who is at Risk of Developing Facial Blemishes?',
                    description: '1. If you have a dark skin tone, you are more likely to develop blemishes compared to those with lighter skin tones.',
                },
                {
                    description: '2. You are at risk if you are taking birth control pills or hormone therapy.',
                },
                {
                    description: '3. Pregnancy: Hormone levels are increased during pregnancy, and these hormones also cause blemishes. The increase in blemishes starts after the 3rd month. 60% of the blemishes that develop during pregnancy regress within a year after birth.',
                },
                {
                    description: '4. Genetics: Approximately 33 to 50% of people with facial blemishes have other family members with blemishes.',
                },
                {
                    description: '5. Blue Light: The blue light from your TV, laptop, mobile phone, and tablet can cause blemishes on your face.',
                },
                {
                    description: '6. Makeup (cosmetics): Some cosmetics cause reactions known as phototoxic reactions (making the skin sensitive to sunlight) and increase blemishes.',
                },
                {
                    description: '7. Hot wax applications such as waxing with sugar can cause blemishes on the skin.',
                },
                {
                    description: '8. Applications that damage the skin, such as at-home peels.',
                },
                {
                    description: '9. Phototoxic drugs (drugs that make you sensitive to sunlight): These include some antibiotics, acne medication or creams, some psychiatric medications, and anti-seizure medications.',
                },
                {
                    title: 'Are Facial Blemishes (Melasma) Permanent?',
                    description: 'Some people may have facial blemishes for years or their entire lives, and the treatments for these people need to be continuous.',
                },
                {
                    title: 'How Should You Use Sunscreen?',
                    description: 'No matter what blemish treatment you have, it cannot be done without sunscreen. The sun is a one-way ticket to blemishes.',
                },
                {
                    description: '1. Use sunscreen with at least SPF 30 (Using sunscreen with SPF 50 or higher will not provide additional benefits).',
                },
                {
                    description: '2. Choose sunscreen that contains zinc oxide and/or titanium dioxide. If your sunscreen contains iron oxide, it will also provide effective protection against the blue light spectrum.',
                },
                {
                    description: '3. Apply your sunscreen half an hour before going outside and reapply it every four hours.',
                },
                {
                    description: '4. If you are exposed to blue light sources such as computer screens or phone screens, do not neglect to use sunscreen indoors as well.',
                },
                {
                    description: '5. And remember; the evening sun increases your blemishes more, so keep using sunscreen until the sun sets. Glass does not provide a sun-protective barrier. Don’t forget to use sunscreen while driving.',
                },
                {
                    title: 'How to Treat Facial Blemishes (Melasma)?',
                    description: '<span style="font-weight:400">Blemish treatment varies from person to person. First, the depth of your blemishes needs to be determined. A treatment program is created by evaluating the prevalence of your blemishes and whether any previous treatment has been applied. For more information about blemish treatment and to schedule an appointment</span><a href="https://www.drebrukaragun.com/iletisim/"><span style="font-weight:400">click here.</span></a>',
                },
            ]
        },
    },
    {
        blogid: 'digital-screens-age-the-skin',
        title: "Digital Screens Age the Skin!",
        image: "/img/blog-images/dijital-ekranlar-cildi-yaslandiriyor.jpg",
        date: 'October 11, 2022',
        content: {
            blogPageTitle: "Digital Screens Age the Skin!",
            blogPageDesc: "The sun is our primary and natural source of blue light. Natural blue light from the sun is important for maintaining our circadian rhythm (natural sleep/wake cycle)...",
            metaDescription: "Blue lights reflecting from phone, tablet, and computer screens cause skin aging. Click for more information!",
            blogDetailPageInfo: [
                {
                    description: 'The sun is our primary and natural source of blue light. Natural blue light from the sun is important for maintaining our circadian rhythm (natural sleep/wake cycle). Healthy vision, increased attention and focus, proper memory function, and staying energetic and awake throughout the day are possible thanks to daylight, specifically light in the blue-turquoise color range.',
                },
                {
                    description: '<b>Have you ever noticed the blue light reflecting on the faces of people glued to their phones, tablets, and screens?</b>',
                },
                {
                    description: 'All new-generation technology products, from light-emitting diodes (LEDs) to Xenon lights, energy-saving bulbs to electronic devices (television, tablet, smartphone, computer), emit blue-violet light. These sources produce much lower intensity blue light than the sun, but we are exposed to these devices for much longer periods and at much closer distances.',
                },
                {
                    title: 'What Are the Effects of Blue Light on the Body?',
                    description: '1. Our exposure to blue-violet light from technological devices during the day causes digital eye strain.',
                },
                {
                    description: '2. Studies have found that people tend to blink less frequently when using digital devices that emit blue light, leading to dry eyes.',
                },
                {
                    description: '3. Excessive exposure to artificial blue light at night reduces melatonin production (the hormone that regulates sleep rhythm), disrupting the circadian rhythm. This results in difficulty falling asleep, poor sleep quality, and daytime fatigue. Disruption of the circadian system also leads to the development of diabetes, cardiovascular diseases, and cognitive dysfunctions.',
                },
                {
                    description: '4. The effect of blue-violet light on our skin: Blue light penetrates deeper into the skin compared to both UVA and UVB light, causing damage to our skin. Spending six hours in front of a computer is equivalent to being exposed to the sun at noon for 20 minutes. Blue light exposure leads to skin discoloration, triggering of diseases sensitive to sunlight by blue light, photoaging (aging caused by sunlight), and photocarcinogenesis (skin cancers caused by sunlight). The skin discoloration caused by blue light was identified in the early 1980s.',
                },
                {
                    title: 'How Can We Protect Ourselves from Blue Light?',
                    description: 'And you are also reading this blog post on a blue light-emitting device. Given the time spent in front of screens, let’s look at what you can do to reduce the impact of blue light in your life.',
                },
                {
                    description: '1. You can use blue light-blocking glasses to reduce digital eye strain.',
                },
                {
                    description: '2. Follow the 20-20-20 rule to reduce digital eye strain. Take a 20-second break every 20 minutes to look at something 20 feet away.',
                },
                {
                    description: '3. Use broad-spectrum sunscreens to protect your entire face, reapplying every four hours during blue light exposure.',
                },
                {
                    description: '4. Use blue light filters on your smartphone, tablet, and computer screens. Filters prevent most of the blue light from reaching your face and eyes without affecting screen visibility.',
                },
                {
                    description: '5. Many smart devices actually have a setting that disables blue light in favor of yellow light. You can use this setting, known as “night mode,” all day to protect your skin and eyes.',
                },
                {
                    description: '6. To maintain your circadian rhythm, if possible, end your blue light exposure two hours before sleep.',
                },
            ]
        },
    },
    {
        blogid: 'mesotherapy-applications-for-facial-and-neck-rejuvenation',
        title: "Mesotherapy Applications for Facial and Neck Rejuvenation",
        image: "/img/blog-images/yuz-ve-boyun-genclestirmede-mezoterapi-uygulamalari.jpg",
        date: 'September 30, 2022',
        content: {
            blogPageTitle: "Mesotherapy Applications for Facial and Neck Rejuvenation",
            blogPageDesc: "Skin ages due to intrinsic (chronological) and extrinsic factors. Intrinsic factors are genetic, while extrinsic factors include UV rays, smoking...",
            metaDescription: "Mesotherapy is important at every stage of anti-aging applications for all age groups. Click to learn more about mesotherapy.",
            blogDetailPageInfo: [
                {
                    description: 'Skin ages due to intrinsic (chronological) and extrinsic factors. Intrinsic factors are genetic, while extrinsic factors include UV rays, smoking, alcohol consumption, poor diet (packaged foods, trans fats, and refined sugars), insufficient sleep (7-8 hours of sleep per day is necessary), psychological and physical stress, and environmental pollution. Chronological aging is genetically programmed, so the results show individual differences.',
                },
                {
                    description: 'One of the most studied hypotheses about chronological aging is the increase in oxidative components in the skin. The increase in oxidative components in the skin increases the breakdown and decreases the production of collagen fibers that provide skin tension, elastin fibers that provide elasticity, and hyaluronic acid that provides moisture to the skin. The gradual decrease in collagen fibers, which provide skin tension, leads to sagging, while the decrease in elastin fibers, which provide elasticity, leads to wrinkles. The decrease in hyaluronic acid dulls the skin, giving it a more matte appearance.',
                },
                {
                    description: 'Age-related changes in the face and neck area are evaluated differently.',
                },
                {
                    description: 'The scale for evaluating age-related changes in the face is specified below;',
                },
                {
                    description: '<table><tbody><tr><td><span style="font-weight:400">Type I</span></td><td><span style="font-weight:400">• Minimal wrinkles</span><span style="font-weight:400">• Mild uneven skin tone</span><span style="font-weight:400">• Patients aged 20-30</span></td></tr><tr><td><span style="font-weight:400">Type II</span></td><td><span style="font-weight:400">• Wrinkles that become more prominent with expressions</span><span style="font-weight:400">• Parallel smile lines on the cheeks</span><span style="font-weight:400">• Brown spots may begin to form on the face (sun spots)</span><span style="font-weight:400">• Patients aged 30-40</span></td></tr><tr><td><span style="font-weight:400">Type III</span></td><td><span style="font-weight:400">• Static wrinkles (wrinkles present even when the face is not moving)</span><span style="font-weight:400">• Growth and raised brown spots known as age spots</span><span style="font-weight:400">• Patients aged 50-60</span></td></tr><tr><td><span style="font-weight:400">Type IV</span></td><td><span style="font-weight:400">Wrinkles throughout the face</span></td></tr></tbody></table>',
                },
                {
                    description: 'An ideal youthful neck is one with good skin tone, a well-defined jawline, and no fat tissue under the chin.',
                },
                {
                    title: 'When Should You Start Mesotherapy for Facial and Neck Rejuvenation?',
                    description: 'In facial and neck mesotherapy, the goal is to stimulate fibroblast cells that make up the skin tissue architecture, increasing the production of collagen and elastin fibers and hyaluronic acid, thereby improving the skin’s moisture, radiance, and firmness.',
                },
                {
                    description: 'It is important to start mesotherapy applications in the early 20s because we lose 1.5% of our collagen and elastin fibers every year in our 20s. In the 20s, mesotherapy alone is sufficient to restructure the subcutaneous tissue. However, after the mid-30s, mesotherapy applications should be combined with other device procedures.',
                },
                {
                    description: 'Mesotherapy is important at every stage of anti-aging applications for all age groups.',
                },
                {
                    description: '- For mild elasticity and radiance loss (Type I-II on the facial evaluation scale),',
                },
                {
                    description: '- For individuals with Type III-IV scale, combined with device procedures,',
                },
                {
                    description: '- Mesotherapy has a healing effect on the skin at every age,',
                },
                {
                    description: '- For dull, lifeless, tired-looking skin,',
                },
                {
                    description: '- For dehydrated skin,',
                },
                {
                    description: '- For smokers, those with insufficient sleep, irregular diet, exposure to pollution, and sun-damaged skin,',
                },
                {
                    description: '- For individuals with mild acne.',
                },
                {
                    title: 'What Are the Products Used in Mesotherapy?',
                    description: 'There are numerous mesotherapy products used for skin rejuvenation. Although the compositions of mesotherapy products differ, most products contain the same general types of ingredients.',
                },
                {
                    description: 'Mesotherapy products mainly include vitamins, minerals, amino acids, co-enzymes, antioxidant products, trace elements, glutathione, and hyaluronic acid. Mesotherapy products can be mixed into a cocktail product tailored to the individual’s skin issues and age.',
                },
                {
                    title: 'The Effect of Vitamins and Their Derivatives on the Skin',
                    description: 'Vitamins play important roles in the skin aging process. They have antioxidant functions. They are important in the synthesis of protein products. Therefore, they are crucial molecules for the synthesis of collagen and elastin fibers.',
                },
                {
                    title: 'Vitamin C (Ascorbic Acid)',
                    description: 'An important antioxidant necessary for collagen synthesis.',
                },
                {
                    title: 'Vitamin A (Retinol)',
                    description: 'Increases cell renewal in the skin, regulates the functions of cells that cause discoloration, and also has antioxidant effects that control sebaceous gland activity.',
                },
                {
                    title: 'Vitamin E (Tocopherol)',
                    description: 'Another important compound with high antioxidant activity.',
                },
                {
                    title: 'B Vitamin Derivatives',
                    description: 'B vitamins primarily function as coenzymes and play a role in controlling many cell functions.',
                },
                {
                    title: 'Use of Antioxidant Supplements',
                    description: 'High-dose antioxidant supplements can be harmful in some cases. For example, some studies have shown that the use of high-dose beta-carotene supplements increases the risk of lung cancer in smokers, the use of high-dose vitamin E supplements increases the risk of bleeding in people taking anticoagulant drugs (“blood thinners”), and increases the risk of hemorrhagic stroke (a type of stroke caused by bleeding in the brain) and prostate cancer.',
                },
                {
                    title: 'Vitamin K',
                    description: 'Plays an important role in regulating blood circulation.',
                },
                {
                    title: 'Amino Acids and Related Compounds',
                    description: 'These are the building blocks for the synthesis of protein-containing components in the skin, primarily collagen.',
                },
                {
                    description: '<b>Minerals:</b> Calcium, phosphorus are necessary for cell cycle maintenance. Magnesium is an important mineral required for numerous enzymatic reactions (over 180).',
                },
                {
                    description: '<b>Hyaluronic Acid (HA):</b> In the skin, it supports the intercellular space, hydrates the skin due to its water-retaining property, and forms a framework to support the normal functions of cells. HA has a capacity to bind water up to 1000 times its volume. HA products in mesotherapy solutions are important for restoring skin moisture.',
                },
                {
                    title: 'How Often Should Mesotherapy Be Applied?',
                    description: 'Mesotherapy applications for the face and décolleté area are planned as three sessions at two-week intervals. Then, follow-up sessions are organized every 2-3 months.',
                },
                {
                    title: 'Post-Mesotherapy Care Instructions:',
                    description: 'Patients should be advised not to wash their face and neck for at least 8 hours, avoid sweating and exercise, and not sunbathe or undergo peeling treatments (even home peeling) for at least 72 hours after the application.',
                },
                {
                    title: 'In Conclusion:',
                    description: '- The increasing demand for non-surgical cosmetic applications to achieve a younger appearance by preventing skin aging has increased interest in mesotherapy. Mesotherapy is widely and successfully used as a treatment for skin aging (intrinsic and extrinsic aging).',
                },
                {
                    description: '- The main aim of mesotherapy is to reduce skin oxidation, which accelerates the skin aging process, dulls the skin, leading to a more matte and lifeless appearance, and results in dry skin by decreasing moisture. It stimulates fibroblast cells that form the skin tissue architecture, increasing the production of collagen and elastin fibers and hyaluronic acid that provide skin moisture.',
                },
                {
                    description: '- For effective treatment, it is important to start mesotherapy in the 20s and continue the treatment at regular intervals. After the mid-30s, it can be combined with device procedures.',
                },
                {
                    description: '- Mesotherapy should not be seen as a “quick solution” and should be done regularly to achieve the desired effect. Mesotherapy applications are an investment in the individual’s skin, and thus, the aging process is slowed down by maintaining the subcutaneous tissue.',
                },
            ]
        },
    },
    {
        blogid: 'how-to-treat-deep-and-fine-wrinkles',
        title: "How to Treat Deep and Fine Wrinkles?",
        image: "/img/blog-images/derin-ve-ince-kirisikliklar-nasil-tedavi-edilir.jpg",
        date: 'September 23, 2022',
        content: {
            blogPageTitle: "How to Treat Deep and Fine Wrinkles?",
            blogPageDesc: "Deep wrinkles are not just a result of aging but also environmental factors like sun exposure, smoking, and alcohol consumption...",
            metaDescription: "Learn what you can do at home and what procedures are available with a dermatologist to reduce or prevent wrinkles.",
            blogDetailPageInfo: [
                {
                    description: 'Deep wrinkles are not just a result of aging but also environmental factors like sun exposure, smoking, and alcohol consumption. The skin’s natural elasticity decreases with age, leading to loss of volume. Fine lines are caused by repeated facial expressions, sun exposure, and dryness. So, how can you deal with wrinkles? Or, to get to the root of the problem, how do wrinkles form? In the rest of this article, we’ll talk about wrinkles and what we can do to reduce or prevent their formation. Enjoy reading!',
                },
                {
                    title: 'How Do Wrinkles Form?',
                    description: 'Wrinkles are a natural part of the aging process. As our skin ages, it naturally loses its elasticity, becomes drier, and wrinkles become inevitable.',
                },
                {
                    description: 'Repeated muscle movements also contribute to wrinkle formation. Smiling, frowning, and squinting are the main causes of wrinkles on the upper face. Over the years, fine lines appear on the lips, parallel lines on the cheeks, and wrinkles on the neck and hands.',
                },
                {
                    description: 'Lifestyle choices can also affect wrinkle development. If you smoke, you will likely have lines above your lips. Your sleep position can lead to lines on your cheeks and around your eyes. If you have significant sun damage and don’t use sunscreen, deep wrinkles are inevitable. Smoking, excessive alcohol consumption, insufficient sleep, an unbalanced diet (containing trans fats and refined sugars), a stressful life, lack of exercise, and not using sunscreen all invite wrinkles.',
                },
                {
                    description: 'Of course, the unchangeable truth is that genetic factors cannot be ignored. If there are many family members who age with wrinkles and you have a thin, dry skin type, your likelihood of aging with wrinkles is high. If you are bothered by the wrinkles on your face and feel like it’s too late, always remember that “life starts from where we catch it.”',
                },
                {
                    title: 'Things You Can Do at Home for Wrinkles',
                },
                {
                    title: '1. Retinoids (Vitamin A)',
                    description: 'When it comes to anti-aging components in home-use products, retinoids (Vitamin A) are the gold standard. Retinoids work by stimulating cell turnover, increasing collagen and elastin production, and reducing the appearance of wrinkles. They also reduce age spots (senile lentigo) and freckles, reverse sun damage, and increase the renewal process of skin texture, resulting in brighter, younger-looking skin.',
                },
                {
                    title: 'How to Use Retinoids (Vitamin A)?',
                    description: 'The effects of retinoids are multi-faceted. However, it is worth noting that retinoids are extremely potent (!!!). Especially if used incorrectly, they can cause irritation, dryness, redness, and significant peeling of the skin. Initially, retinoids should be used only two to three times a week. If you have very fair skin, you can even start using them once a week. Gradually increase the number of days used based on your skin’s tolerance until you can use them daily. Mixing your retinoid cream with a moisturizer can reduce the likelihood of side effects. Always use retinoid creams at night and continue with a good moisturizer during the day. If you are using a retinoid cream, you must also use sunscreen as retinoids make your skin more sensitive to the sun.',
                },
                {
                    description: '<b>Duration of Use:</b> It is recommended to use throughout the winter. Don’t expect immediate results as the effectiveness will appear after 3-4 months due to the stimulation of the production process.',
                },
                {
                    description: '<b>Warning:</b> Always consult your dermatologist before starting retinoid use.',
                },
                {
                    title: '2. Vitamin C (Ascorbic Acid)',
                    description: 'Another product to use at home for anti-aging is Vitamin C (ascorbic acid). Vitamin C is a powerful antioxidant that increases collagen and elastin production, thus reducing the appearance of wrinkles. Vitamin C also protects your skin from sun damage. Especially during the summer, applying it under your sunscreen will protect you from the sun’s side effects. It also reduces sun spots, enlarged pores, and gives your skin a brighter appearance.',
                },
                {
                    title: 'What Should You Consider When Buying and Using Vitamin C?',
                    description: 'For Vitamin C to be significantly effective, its concentration should be higher than 8% (indicated in the product content). The most effective concentration is products containing 15% ascorbic acid. If you have dry and sensitive skin, start using it gradually (2-3 times a week) and increase usage as your skin’s tolerance improves. A concentration of over 20% can cause discomfort and the effectiveness of Vitamin C plateaus after 20%, increasing the feeling of dryness.',
                },
                {
                    description: 'Ensure that the Vitamin C product is in an opaque or dark-colored glass bottle to prevent oxidation. Store it away from light as it oxidizes when exposed to air and light. When oxidized, Vitamin C turns yellow or brown. Make sure your serum is light yellow or colorless when using it.',
                },
                {
                    description: '<b>Usage:</b> Can be used morning and evening. Especially apply under your sunscreen in the morning.',
                },
                {
                    title: 'Wrinkle Treatment with a Dermatologist'
                },
                {
                    title: '1. Mesotherapy',
                    description: 'Mesotherapy applications are more effective in preventing wrinkle formation than in treating wrinkles. Mesotherapy aims to renew and activate cellular activity, increasing the synthesis capacities of fibroblasts (cells responsible for collagen and elastin synthesis in the skin tissue architecture), and boosting the production of new collagen, elastin, and hyaluronic acid to enhance the skin’s moisture, radiance, and firmness. In our 20s, our skin loses 1.5% of its collagen and elastin each year. We begin to notice wrinkles caused by this loss from the mid-30s onwards. The vitamins, minerals, amino acids, antioxidants, and co-enzymes in mesotherapy are essential for collagen and elastin production. Starting mesotherapy in your 20s can significantly reduce the process of collagen and elastin loss and, by making a good investment in your skin over the years, greatly slow down the process of wrinkle formation. If you smoke, sleep inadequately, have an unbalanced diet (containing trans fats and refined sugars), live a stressful life, mesotherapy can also reduce the effects of these negative lifestyle choices on your skin.',
                },
                {
                    title: '2. Filler Applications',
                    description: 'Filler products are gel-like substances injected into the skin to soften wrinkles. Fillers are great for wrinkles caused by volume loss in the face. They restore lost volume for a younger look, hydrate the skin, and plump the face. Dermal fillers are also used to correct static wrinkles (not caused by facial movements) resulting from collagen, elasticity, and fat loss, as well as gravity and sun damage. Filler applications are ideal for wrinkles in the lower face area.',
                },
                {
                    title: '3. Scarlet X',
                    description: 'This system, which can treat problems in all skin layers simultaneously, uses specially designed micro-needles to stimulate fibroblast cells in the skin tissue architecture with radiofrequency waves. After the application, your skin will start producing new collagen and elastin, resulting in tighter and more elastic skin.',
                },
                {
                    description: 'The Scarlet X method is an effective method used for treating fine lines and wrinkles. By stimulating the subcutaneous tissue and increasing the new synthesis process, it will also prevent wrinkle formation. It provides dual effectiveness in wrinkle treatment with both wrinkle-reducing and preventive effects. Since radiofrequency technology targets only the subcutaneous tissue without damaging the upper layer of the skin, it can be applied in all seasons. A slight redness that subsides after 1-2 hours may occur after the application, but the person can immediately return to their social life. After the procedure, it is sufficient to moisturize the skin well and use sunscreen. There are various devices with radiofrequency technology. Scarlet X is a device produced with the highest technology of radiofrequency technology, approved by the FDA.',
                },
                {
                    title: '4. Thread Applications (Spider Web)',
                    description: 'In the spider web aesthetic application, fully dissolvable threads that are biocompatible with the skin structure are placed under the skin with fine needles. These threads are woven into the subcutaneous tissue like a spider web. The main goal of spider web aesthetics is to increase the production of the reduced collagen and elastin, thereby improving skin quality. With increased skin quality, the formation of new wrinkles is reduced while the existing wrinkles are minimized, providing dual effectiveness. The effectiveness of the spider web application is much better, especially for individuals aged 30-45. It is also a suitable method for those in their 20s who want tighter and firmer skin. The spider web aesthetic is a quick application that does not take the person away from social life. The main effect on the skin, which starts to rejuvenate immediately after the spider web application, will be seen after the 3rd month.',
                },
                {
                    title: '5. Laser Treatments',
                    description: 'Laser treatments are also effective in reducing wrinkles and treating fine lines. The fact that these treatments can only be done in the winter period, the long-lasting temporary redness after the treatment, and the peeling and crusting on the face for 7-10 days afterward are disadvantages compared to radiofrequency technology.',
                },
                {
                    title: '6. Chemical Peels',
                    description: 'Chemical peeling stimulates the skin tissue on your face, increases collagen and elastin production, and smooths your skin, reducing fine wrinkles. Before the chemical peel application, the depth of your wrinkles is evaluated, and then your treatment protocol is created. There are superficial, medium, and deep peeling applications. The products used in these applications and their concentrations differ. After chemical peeling, you will have varying degrees of redness and peeling depending on the applied product and its concentration. This will be more pronounced as the depth of the peeling increases. Therefore, it should only be applied in the winter period. After deep chemical peel applications, your doctor may recommend staying at home for 7-10 days.',
                },
                {
                    description: 'I have tried to provide comprehensive information on various methods for treating deep and fine wrinkles. If you have any questions about the topic or want to make an appointment, you can reach us through the phone numbers on our website.',
                },
            ]
        },
    },
    {
        blogid: 'do-filler-applications-make-the-face-puffy-or-broader',
        title: "Do Filler Applications Make the Face Puffy or Broader?",
        image: "/img/blog-images/dolgu-uygulamalari-yuzu-sisirir-ya-da-genisletir-mi.jpg",
        date: 'September 16, 2022',
        content: {
            blogPageTitle: "Do Filler Applications Make the Face Puffy or Broader?",
            blogPageDesc: "Chipmunk cheeks, sausage lips, or an elongated chin are undesirable outcomes for most people. Seeing someone you know transform unnaturally...",
            metaDescription: "Filler applications performed by unqualified individuals can lead to side effects. Click for detailed information about filler applications.",
            blogDetailPageInfo: [
                {
                    description: 'Chipmunk cheeks, sausage lips, or an elongated chin are undesirable outcomes for most people.',
                },
                {
                    description: 'Seeing someone you know transform unnaturally into a face that sometimes becomes unrecognizable is disturbing. While dermal fillers are a great way to combat the signs of aging and add volume to your face, it’s important that they are applied by a professional who knows the right amount to use to keep the face looking natural. Unqualified individuals may think that applying a lot of filler to stretch the skin is correct. Unfortunately, too much of a good thing will be bad. The result is typically chipmunk cheeks, sausage lips, or an abnormally elongated chin. Too much filler application can make a young person look odd and even older.',
                },
                {
                    description: 'This is a real concern for people considering facial filler applications and can make them avoid fillers.',
                },
                {
                    description: 'With the wrong technique, unsuitable filler choices, and application errors, overfilling the cheeks can disrupt the natural contour balance of the face and affect the shape of the eyes. We see surprising results even in celebrities like Madonna, Nicole Kidman, and Janice Dickison.',
                },
                {
                    description: 'When selecting and applying fillers, the treatment plan should be designed in a way that does not disrupt the harmony of the aesthetic areas, considering the 3-dimensional structure of the patient’s face.',
                },
                {
                    description: 'With age, our faces lose volume: cheeks flatten, temples sink, lips thin and turn inward, the chin recedes, the grooves extending from the corners of the mouth to the chin become prominent, and the definition of the jawline decreases. Fillers are used to counteract all these changes and increase volume. When injections are done properly, the results are extraordinarily beautiful. With the right technique, knowledge, experience, and aesthetic understanding of your doctor, the lost volume areas will be perfectly restored. There will be no expansion of the face.',
                },
                {
                    description: 'In their 20s, cosmetic cheek fillers, lip fillers, nose fillers, and jawline fillers can achieve an aesthetic harmony that makes the person look beautiful and attractive without an exaggerated appearance when done with the right technique, by an experienced practitioner, and with appropriate filler choices.',
                },
                {
                    description: 'In filler applications, the product used, the technique to be applied, and the person’s experience in these applications are important. Before the application, the anatomical structure of the person’s face should be evaluated to decide the most appropriate application.',
                },
                {
                    description: 'Chipmunk cheeks, sausage lips, or an abnormal chin are not normal; they are application errors.',
                },
            ]
        },
    },
    {
        blogid: 'changes-in-the-face-due-to-aging',
        title: "Changes in the Face Due to Aging",
        image: "/img/blog-images/yaslanmaya-bagli-yuzde-meydana-gelen-degisimler.jpg",
        date: 'September 13, 2022',
        content: {
            blogPageTitle: "Changes in the Face Due to Aging",
            blogPageDesc: "The skin is a complex and dynamic organ that most visibly shows age-related changes. Our skin begins to age from birth and...",
            metaDescription: "Learn detailed information about the changes that occur in your face as you age and the signs that will appear on your face according to your age. Click for more information.",
            blogDetailPageInfo: [
                {
                    description: 'The skin is a complex and dynamic organ that most visibly shows age-related changes. Our skin begins to age from birth, and signs of aging appear on our skin starting at age 20.',
                },
                {
                    description: 'First, let’s take a look at what our face is made of. Our face essentially consists of skin, subcutaneous surface and deep fat pads, ligaments, muscles, and bone. As we age, we lose volume in all these areas, which leads to the inevitable visible signs of aging. When combined with gravity, this volume loss results in the typical appearance of aging. The skin reflects all underlying age-related changes like a fabric.',
                },
                {
                    title: 'Wrinkling and Sagging of Skin Tissue',
                    description: 'From our 20s onwards, collagen and elastin production decreases, and the rate of degradation increases. Each year, we lose 1.5% of our collagen and elastin. This loss may not be noticeable in our 20s and early 30s, but it becomes apparent in the second half of our 30s. Without interventions to increase collagen synthesis, collagen and elastin fibers decrease by 80% by age 70. Collagen loss is a significant factor in the aging process. When combined with the loss of elastin (which provides skin elasticity) and hyaluronic acid (which provides moisture), the skin becomes drier, less elastic, thinner, and more prone to wrinkling and sagging.',
                },
                {
                    title: 'Changes in Facial Muscles',
                    description: 'This factor significantly affects the facial aging process. When facial muscles lose their tone, the signs of aging become more pronounced. The skin on our face and neck is directly connected to muscles. Over the years, muscle tone gradually decreases, and the entire face starts to sag. In addition to losing muscle tone over time, the continuous use of facial muscles for expressions leads to deeper lines on the skin’s surface.',
                },
                {
                    title: 'Reduction of Fat Tissue',
                    description: 'Fat tissue especially starts to decrease in the temples, forehead, and on top of the cheekbones. Our deep fat pads that hold up the face shrink, and surface fat pads shift due to gravity, accumulating in the middle part of the jawline, making the onset of aging more visible. As we lose weight, the shrinkage of facial fat pads during weight loss also contributes to facial sagging.',
                },
                {
                    title: 'Changes in Bone Tissue',
                    description: 'Changes in bone structure begin to occur after age 35, leading to hollow eyes, flattened cheeks, and a receding jawbone. All these changes result in decreasing bone support for our skin. Our tissues become too large for the underlying bones, making wrinkles and folds more prominent. The skin with reduced bone support becomes more noticeable in the lower face due to gravity and decreased collagen fibers, and over time, our faces start to resemble the proportions of a baby’s face. Have you ever wondered why babies have sagging cheeks and chins? It’s because they have too much skin and fat tissue compared to their facial bones. The same situation occurs as we age when bone support decreases. In children, bone structure gradually develops, and proportions become optimal in their 20s. Unfortunately, after age 35, we start to lose our bone structure.',
                },
                {
                    description: 'Some people may also experience ear elongation due to cartilage growth. Especially men may notice that their ears become longer, coarser, and more pronounced as they age.',
                },
                {
                    description: 'Everything seems so unfair, as all the fundamental components of our face conspire against us to make us look older.',
                },
                {
                    description: 'With the right interventions at the right time, you can minimize the signs of aging and be the best version of your age. The key is to maintain and improve skin quality.',
                },
                {
                    description: 'To delay the changes in the face due to aging and for more information, you can contact us here and make an appointment.',
                },
            ]
        },
    },
    {
        blogid: 'tips-for-achieving-radiant-skin-in-10-steps',
        title: "Tips for Achieving Radiant Skin in 10 Steps",
        image: "/img/blog-images/10-adimda-parlak-bir-cilde-sahip-olmanin-ipuclari.jpg",
        date: 'September 10, 2022',
        content: {
            blogPageTitle: "Tips for Achieving Radiant Skin in 10 Steps",
            blogPageDesc: "Fact: The allure of glowing skin never fades. However, maintaining a glowing face is not always easy, and the truth is, there are reasons why your skin loses its radiance...",
            metaDescription: "If you feel like you've lost your glow due to dull skin, read our blog post Tips for Achieving Radiant Skin in 10 Steps.",
            blogDetailPageInfo: [
                {
                    description: 'Fact: The allure of glowing skin never fades. However, maintaining a glowing face is not always easy, and the truth is, there are reasons why your skin loses its radiance.',
                },
                {
                    description: 'If you feel like you"ve lost your glow due to dull skin, one of the following reasons is likely responsible.',
                },
                {
                    title: '1. Drink Enough Water Throughout the Day',
                    description: 'Hydration refers to water. The skin consists of three layers: the outer layer (epidermis), the underlying skin (dermis), and subcutaneous tissue. If the outermost layer of the epidermis does not contain enough water, hydration decreases, the skin loses its elasticity, and feels rough. By drinking at least 1.5-2 liters of water daily, you can ensure hydration in your skin and eliminate toxins from your body.',
                },
                {
                    title: '2. Exfoliate',
                    description: 'You may wash your face daily, but there is an essential skincare routine that almost half of women overlook: exfoliation. By exfoliating 1-2 times a week, you can rid your skin of dead cells.',
                },
                {
                    title: '3. Moisturize Regularly',
                    description: 'If the cells in our skin lose too much water, the skin can become dehydrated. When the water content inside the cells decreases, the cells shrink. As a result, the skin appears dull, loses its radiance, and fine wrinkles become more noticeable. You must ensure your skin"s optimal moisture level and regularly moisturize according to your needs.',
                },
                {
                    title: '4. Ensure Adequate Sleep',
                    description: 'Anything that keeps you up at night can stand between you and your glowing skin. “Sleep is when skin cells repair and renew themselves.” If this time is shortened or sleep biorhythm is disrupted, skin cells cannot perform optimally.',
                },
                {
                    title: '5. Limit Smoking',
                    description: 'Smoking is not only harmful to your health but also reduces the radiance of your skin. Smoking decreases collagen synthesis, increases its breakdown, reduces skin blood flow, and over time, causes the skin to appear pale, dull, and wrinkled.',
                },
                {
                    title: '6. Eat a Balanced Diet',
                    description: 'Eating a balanced diet free of refined sugars, containing no trans fats, and rich in antioxidants is essential for skin health.',
                },
                {
                    title: '7. Protect Against Air Pollution',
                    description: 'Environmental factors like air pollution, low humidity, harsh winds, and UV rays from the sun can dull unprotected skin.',
                },
                {
                    description: 'First, be diligent about cleansing your face every night. Use a daily broad-spectrum SPF 30+ or SPF 50 to protect against UV rays and pollution. Additionally, use antioxidant serums under your sunscreen every morning to prevent free radicals from damaging your skin (some of the most effective antioxidants are Vitamin C and resveratrol).',
                },
                {
                    title: 'Bonus: Mesotherapy',
                    description: 'Remember that everything in life is a whole and balanced. By adopting the best lifestyle practices at home, you can more easily reduce the traces of experience.',
                },
            ]
        },
    },
    {
        blogid: 'when-and-which-cosmetic-procedures-should-we-start',
        title: "When and Which Cosmetic Procedures Should We Start?",
        image: "/img/blog-images/kozmetik-islemlere-ne-zaman-ve-hangi-uygulama-ile-baslamaliyiz.jpg",
        date: 'September 7, 2022',
        content: {
            blogPageTitle: "When and Which Cosmetic Procedures Should We Start?",
            blogPageDesc: "We cannot stop our natural aging process, but we can slow it down. Our genes largely control when these changes occur. For such aging, the medical term used is 'intrinsic aging'...",
            metaDescription: "If signs of aging have appeared on your skin, learn when and which cosmetic procedures you should start. Click for more information.",
            blogDetailPageInfo: [
                {
                    description: 'We cannot stop our natural aging process, but we can slow it down. Our genes largely control when these changes occur. The medical term used for such aging is "intrinsic aging." Our environment and lifestyle choices can cause our skin to age prematurely. The medical term for this type of aging is "extrinsic aging."',
                },
                {
                    description: 'Genetic factors are as important as UV rays, smoking, alcohol consumption, poor nutrition (diets containing refined sugar and trans fats), lack of sleep (7-8 hours of sleep per day and night sleep are important), psychological and physical stress, and environmental pollution in the aging of our skin.',
                },
                {
                    description: 'In our 20s, the production rate of collagen, elastin fibers, and hyaluronic acid in our skin begins to decrease, and the degradation rate increases. As a result, we lose 1.5% of our collagen and elastin fibers every year. The decrease in collagen causes the skin to loosen. The loss of elastic fibers reduces skin elasticity. We may not notice these changes in our skin until our mid-30s. The earlier we start to prevent the loss of collagen, elastin fibers, and hyaluronic acid in our skin, the better we will maintain our skin quality. No application can stop the aging process, only slow it down. This slowing process will not be achieved solely with applications. It is also essential to correct your lifestyle under your control, as mentioned in the first paragraph. Exercising also supports all applications by increasing the blood flow to our skin.',
                },
                {
                    description: 'The first cosmetic procedure we should start to slow down the aging process is mesotherapy. We recommend starting mesotherapy in the early 20s to reduce the loss of collagen, elastin fibers, and hyaluronic acid in our skin and increase the decreased synthesis capacity.',
                },
                {
                    description: 'Wrinkles on the forehead, between the eyebrows, and around the eyes develop due to the facial expressions we use. Therefore, if you use a lot of facial expressions, you can start applications to reduce the lines in the upper face area in your late 20s.',
                },
                {
                    description: 'Different treatment methods can be determined to improve your skin quality after the mid-30s. These treatment methods are entirely personal and show individual differences.',
                },
                {
                    description: 'Now, let’s talk about mesotherapy, the first procedure we should start to maintain and improve our skin quality:',
                },
                {
                    title: 'What is Mesotherapy?',
                    description: 'Aging is an increasing concern in modern societies. The only organ where we can see age-related changes is our skin.',
                },
                {
                    description: 'In recent years, mesotherapy has been widely used in cosmetic dermatology as an anti-aging strategy. Mesotherapy is a treatment method that started to be used in France in the 1950s. The aim of mesotherapy in skin rejuvenation is to preserve and/or restore the healthy and youthful texture of the skin. The desired ultimate effect is a firmer, brighter, and more hydrated skin obtained by injecting suitable products that are perfectly biocompatible and completely absorbable into the skin. Mesotherapy products include hyaluronic acid, vitamins, minerals, amino acids, antioxidants (both those synthesized in the body and those that need to be obtained through diet), and minerals. Mesotherapy is the injection of these substances into the skin with needles in optimal concentrations directly in the problematic area. These products increase the biosynthetic capacity of fibroblasts, the reconstruction of the optimal physiological environment, increase cellular activity, and increase collagen, elastin, and hyaluronic acid synthesis.',
                },
                {
                    description: 'Mesotherapy allows direct application to specific amounts in problematic areas. The products applied remain in the skin and are not absorbed by the body.',
                },
                {
                    description: 'The number of injections and the application amount varies depending on the patient, the purpose, and the anatomy of the area to be injected.',
                }
            ]
        },
    },
];

export default blog;
